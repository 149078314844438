const SET = "curDevInfo/SET";

const initialState = {
    isShip: true, 
    shipNo: null, 
    devEui: null,
    fromSide: true,
};

export const setCurDevInfo = (devInfo) => ({
  type: SET,
  Info: devInfo,
});

export default function curDevInfo(state = initialState, action) {
  switch (action.type) {
    case SET:
      return { ...state, 
        isShip: action.Info.isShip, 
        shipNo: action.Info.shipNo, 
        devEui: action.Info.devEui,
        fromSide: action.Info.fromSide
       };
    default:
      return state;
  }
}