import {React, useState, useEffect} from 'react';
import './modalDevice.css';
import UseContextMenu from '../popupMenu/UseContextMenu';
import ModalDeviceEdit from './ModalDeviceEdit';
import { BiX } from 'react-icons/bi';
import {BiSearch} from 'react-icons/bi';
import {BiAlignLeft} from 'react-icons/bi'
import {ImCheckboxUnchecked} from 'react-icons/im'
import {ImCheckboxChecked} from 'react-icons/im'
import {AiFillCaretRight, AiFillCaretLeft, AiFillStepForward, AiFillStepBackward} from 'react-icons/ai'
import {RxDividerVertical} from 'react-icons/rx'
import {commRegisterGateway, commDeleteGateway, commRegisterDevice, commActivateDevice, commDeleteDevice} from '../comm/Comm';
import {commRegisterGatewayDB, commRegisterDeviceDB, commDeleteGatewayDB, commDeleteDeviceDB} from '../comm/CommUpdateDB';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import { addGateway, updateGateway } from "../../redux/modules/gateways";
import { deleteGateway } from "../../redux/modules/gateways";
import { addDevice, updateDevice } from "../../redux/modules/devices";
import { deleteDevice } from "../../redux/modules/devices";
import { addDeviceToAdd } from "../../redux/modules/devicesToAdd";
import { Confirm, MyAlert } from '../popupMenu/Confirm';
import readXlsxFile from 'read-excel-file/web-worker';
import ModalUpload from './ModalUpload';

const ModalDevice = (props) => {
  const { open, close } = props;
  const [isGateway, setIsGateway] = useState(true);
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [searchContent, setSearchContent] = useState("");
  const gateways = useSelector((state) => state.gateways);
  const devices = useSelector((state) => state.devices);
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [checkDelete, setCheckDelete] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [uploadOpen, setUploadOpen] = useState(false);
  const [reports, setReports] = useState([]);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const XLSX = require( "xlsx" );
  var uploadReports;
  const [page, setPage] = useState(1);
  const [pageStart, setPageStart] = useState(1);
  const [pageEnd, setPageEnd] = useState(1);
  const itemsInPage = 15;
  const [searchList, setSearchList] = useState([]);
  const [modalDeviceEditOpen, setModalDeviceEditOpen] = useState(false);
  const [idEdit, setIdEdit] = useState('');
  const [memoEdit, setMemoEdit] = useState('');
  
  const setAlert = (content) => {
    setAlertContent(content);
    setAlertOpen(true);
  }

  useEffect(() => {
    if (open){
      console.log(devices);
          
      document.documentElement.style.setProperty('--device_1', variables.getPropertyValue('--device_1_init'));
      document.documentElement.style.setProperty('--device_2', variables.getPropertyValue('--device_2_init'));
      document.documentElement.style.setProperty('--device_3', variables.getPropertyValue('--device_3_init'));
      document.documentElement.style.setProperty('--device_total', variables.getPropertyValue('--device_total_init'));
      
    }
  }, [open]);

  useEffect(() => {
    if (open){
      if (isSearchMode){
        if (isGateway){
          const items = (gateways.filter(gateway => 
            (gateway.gatewayID.toUpperCase().includes(searchContent.toUpperCase()) ||
            gateway.description.includes(searchContent)) ));
          const newList = items.slice((page-1)*itemsInPage, (page)*itemsInPage);
          setPageEnd(parseInt(items.length/itemsInPage) + 1);
          setList(newList);
          setSearchContent(items);
        }
        else{
          const items = (devices.filter(device => 
            (device.devEui.toUpperCase().includes(searchContent.toUpperCase()) ||
            device.description.includes(searchContent)) ));
          const newList = items.slice((page-1)*itemsInPage, (page)*itemsInPage);
          setPageEnd(parseInt(items.length/itemsInPage) + 1);
          setList(newList);
          setSearchList(items);
        }
      }
      else{
        console.log("gateway device changed");
        if (isGateway){
          const items = gateways;
          const newList = items.slice((page-1)*itemsInPage, (page)*itemsInPage);
          setPageEnd(parseInt(items.length/itemsInPage) + 1);
          setList(newList);
        }
        else{
          const items = devices;
          const newList = items.slice((page-1)*itemsInPage, (page)*itemsInPage);
          setPageEnd(parseInt(items.length/itemsInPage) + 1);
          setList(newList);
        }
      }
    }
  }, [gateways, devices])

  const drawPage = (curPage) => {
    var newList;
    if (isSearchMode){
      newList = searchList.slice((curPage-1)*itemsInPage, (curPage)*itemsInPage);
    }
    else{
      newList = (isGateway? gateways:devices).slice((curPage-1)*itemsInPage, (curPage)*itemsInPage);
    }
    setList(newList);
  }

  const setGateway = (on) => {
    setIsGateway(on);
    resetInputs();
    setPage(1);
    setIsSearchMode(false);
    const items = (on? gateways : devices);
    items.forEach(item => {
      if (on)
        dispatch(updateGateway(item.gatewayID, 'select', false));
      else
        dispatch(updateDevice(item.devEui, 'select', false));
    })
    const newList = items.slice(0, itemsInPage);
    //console.log(newList);
    setPageEnd(parseInt(items.length/itemsInPage) + 1);
    setList(newList);
  }

  const onChange1 = (e) => {
    setId(e.target.value.toUpperCase());
  };
  const onChange3 = (e) => {
    setDescription(e.target.value);
  };
  const onChange4 = (e) => {
    setSearchContent(e.target.value.toUpperCase());
  };
  const changePage = (e) => {
    setPage(e.target.value);
  }
  const pageEntered = (e) => {
    if (e.key === 'Enter') {
      if (page >= pageStart && page <= pageEnd){
        drawPage(page);
      }
    }
  }
  function resetInputs() {
    setId("");
    setDescription("");
    setSearchContent("");
  }
  function getId(array){
    var max = 0;
    for (var i=0; i<array.length; i++)
      if (array[i].id > max)
        max = array[i].id;
    return (max+1);
  }

  function UpdateDeviceFromDB(devEui, data)
  {
      dispatch(updateDevice(devEui, 'battery', data.battery));
      dispatch(updateDevice(devEui, 'lat', data.lat));
      dispatch(updateDevice(devEui, 'lng', data.lng));
      dispatch(updateDevice(devEui, 'state', data.state));
      dispatch(updateDevice(devEui, 'power', data.power));
      dispatch(updateDevice(devEui, 'workend', data.workend));
      dispatch(updateDevice(devEui, 'distanceAlarm', data.distanceAlarm));
      dispatch(updateDevice(devEui, 'distance', data.distance));
      dispatch(updateDevice(devEui, 'LedOnTime', data.LedOnTime));
      dispatch(updateDevice(devEui, 'onoffPeriod', data.onoffPeriod));
      dispatch(updateDevice(devEui, 'duration', data.duration));
      dispatch(updateDevice(devEui, 'commPeriod', data.commPeriod));
      dispatch(updateDevice(devEui, 'lastComm', data.lastComm));
  }

  function register() {
    if (id.length !== 16){
      //alert("ID의 글자수가 올바르지 않습니다.");
      setAlert("ID의 글자수가 올바르지 않습니다.");
      return;
    }
    if ((isGateway? gateways.filter(gateway => gateway.gatewayID.toUpperCase() === id.toUpperCase()):
      devices.filter(device => device.devEui.toUpperCase() === id.toUpperCase())).length > 0){
      setAlert("중복된 ID가 있습니다!");
      return;
    }
    
    if (isGateway){
      const gateway = {
        id: id,
        name: id,
        description: description,
      };
      axios(commRegisterGateway(gateway))
      .then(function (response) {
        console.log("success");
        // 배열 수정
        const idNew = getId(gateways);
        const gateway = {
          id: idNew, 
          gatewayID: id, 
          gatewayName: id, 
          description: description,
          shipNo: "",
          lat: null, 
          lng: null, 
          lastComm: "", 
          select: false
        };
        dispatch(addGateway(gateway));
        commRegisterGatewayDB(gateway)
        setAlert("게이트웨이를 등록하였습니다!");
      })
      .catch(function (error) {
        //console.log("error"+error);
        setAlert("등록 오류가 발생하였습니다!");
      });
    }
    else {
      // 등록할 device 초기값 만들기
      const idNew = getId(devices);
      const deviceNew = {
        id: idNew,
        no: 9, 
        devEui: id, 
        name: id, 
        description: description, 
        shipNo: null,
        battery: 0,
        lat: null,
        lng: null,
        state: 0x00, 
        power: 1,
        workend: 0,
        distanceAlarm: true,
        distance: 3,
        LedOnTime: null,
        onoffPeriod: 1,
        duration: 3,
        commPeriod: 2,
        lastComm: null,
        marker: null, 
        overlay: null, 
        select: false
      };
      
      // DB에 등록 먼저
      axios({
        method: "POST",
        url: 'api/devicedb/register',
        data: deviceNew
      }).then(function(response) {
        //console.log("DB 등록결과...");
        //console.log(response);

        // DB 등록결과로 받은 키값을 사용하여 네트워크 서버에 등록
        const device = {
          devEui: id,
          name: id,
          description: description,
          appskey: response.data.appskey,
          nwkskey: response.data.nwkskey,
        };
        axios(commRegisterDevice(device))
        .then(function (response) {
          console.log("success");
          
          axios(commActivateDevice(device))
          .then(function (response) {
            console.log("activation success");
  
            // 배열에 device 추가
            deviceNew.appskey = device.appskey;
            deviceNew.nwkskey = device.nwkskey;
            dispatch(addDevice(deviceNew));
            dispatch(addDeviceToAdd(deviceNew.devEui));  //map에 등록
            setAlert("어망을 등록하였습니다!");
          })
          .catch(function (error) {
            //console.log(error);
            setAlert("등록 오류가 발생하였습니다!");

            // DB에 조금전에 추가한 device 삭제해야함
            commDeleteDeviceDB(deviceNew.devEui);
          });
        })
        .catch(function (error) {
          //console.log(error);
          setAlert("등록 오류가 발생하였습니다!");
          
          // DB에 조금전에 추가한 device 삭제해야함
          commDeleteDeviceDB(deviceNew.devEui);
        });            
                
      }).catch(error=>{
        console.log(error);
        alert("어망 DB 등록 실패");
      });
    }
    resetInputs();
  };

  function deleteItem() {
    //console.log("deleteItem");    
    setCheckDelete(false);

    if (isGateway){
      const items = gateways.filter(gateway => gateway.select === true);
      items.forEach(gateway => {
        axios(commDeleteGateway(gateway.gatewayID))
        .then(function (response) {
          console.log("delete success");
          setAlert("게이트웨이를 삭제하였습니다.");
        })
        .catch(function (error) {
          //console.log("error"+error);
          setAlert("삭제 오류가 발생하였습니다.");
        });
        
        // 배열 수정
        // 관제서버에서는 무조건 삭제되도록 수정---
        dispatch(deleteGateway(gateway.gatewayID));
        commDeleteGatewayDB(gateway.gatewayID);
        // ----------------------------------------
      });
    }
    else {
      const items = devices.filter(device => device.select === true);
      items.forEach(device => {
        axios(commDeleteDevice(device.devEui))
        .then(function (response) {
          console.log("delete success");
        })
        .catch(function (error) {
          //console.log(error);
          setAlert("삭제 오류가 발생하였습니다.");
        });
        
        // 배열 수정
        // 관제서버에서는 무조건 삭제되도록 수정---
        dispatch(deleteDevice(device.devEui));
        commDeleteDeviceDB(device.devEui);
        // ----------------------------------------
      });
      setAlert("어망을 삭제하였습니다.");
    }
  };

  function search(){
    //console.log("search");
    if (isGateway){
      const items = (gateways.filter(gateway => 
        (gateway.gatewayID.toUpperCase().includes(searchContent.toUpperCase()) ||
        gateway.description.includes(searchContent)) ));
      const newList = items.slice(0, itemsInPage);
      setPageEnd(parseInt(items.length/itemsInPage) + 1);
      setList(newList);
      setSearchList(items);
    }
    else{
      const items = (devices.filter(device => 
        (device.devEui.toUpperCase().includes(searchContent.toUpperCase()) ||
        device.description.includes(searchContent)) ));
      const newList = items.slice(0, itemsInPage);
      setPageEnd(parseInt(items.length/itemsInPage) + 1);
      setList(newList);
      setSearchList(items);
    }
    setIsSearchMode(true);
    setPage(1);
    //console.log("list.length "+list.length);
  };
  
  const handleOnKeyPress1 = e => {
    if (e.key === 'Enter') {
      search();
    }
  };
  const handleOnKeyPress2 = e => {
    if (e.key === 'Enter') {
      idOverlapCheck();
    }
  };

  function clickSelect(id){
    if (isGateway){
      const gateway = gateways.find((gateway) => (gateway.gatewayID === id));
      dispatch(updateGateway(gateway.gatewayID, 'select', !gateway.select));
    }
    else{
      const device = devices.find((device) => (device.devEui === id));
      dispatch(updateDevice(device.devEui, 'select', !device.select));
    }
  }

  function selectAllClicked(){
    if (list.length < 1)
      return;    
    const newValue = !list[0].select;
    
    list.forEach((listItem) => {
      if (isGateway){
        const gateway = gateways.find((gateway) => (gateway.gatewayID === listItem.gatewayID));
        dispatch(updateGateway(gateway.gatewayID, 'select', newValue));
      }
      else{
        const device = devices.find((device) => (device.devEui === listItem.devEui));
        dispatch(updateDevice(device.devEui, 'select', newValue));
      }
    });
  }

  function idOverlapCheck(){
    if ((isGateway? gateways.filter(gateway => gateway.gatewayID.toUpperCase() === id.toUpperCase()):
      devices.filter(device => device.devEui.toUpperCase() === id.toUpperCase())).length > 0){
      setAlert("중복된 ID가 있습니다!!");
      //resetInputs();
    }
    else{
      setAlert("중복된 ID가 없습니다.");
    }
  }

  const exelOpen = async (e) => {
    console.log("exelOpen");
    readXlsxFile(e.target.files[0]).then((rows) => {
      console.log(rows);

      uploadReports = [];
      rows.filter(row => (row[0] !== "devEui") && (row[0] !== "gatewayID")).forEach(row => {
        var idReport = row[0].toUpperCase();
        var descriptionReport = row[1];
        var report = {id: idReport, description: descriptionReport, result: ""};
        console.log(report);
        uploadReports = [...uploadReports, report];

        if (idReport.length !== 16){
          uploadReports.find(item => item.id === idReport).result = "ID의 글자수가 맞지않음";
          if (uploadReports.filter(item => item.result === "").length === 0){
            setReports(uploadReports);
            setUploadOpen(true);
          }
        }
        else if ((isGateway? gateways.filter(gateway => gateway.gatewayID.toUpperCase() === idReport.toUpperCase()):
          devices.filter(device => device.devEui.toUpperCase() === idReport.toUpperCase())).length > 0){
          uploadReports.find(item => item.id === idReport).result = "중복된 ID가 있음";
          if (uploadReports.filter(item => item.result === "").length === 0){
            setReports(uploadReports);
            setUploadOpen(true);
          }
        }
        else{
          if (isGateway){
            const gateway = {
              id: idReport,
              name: idReport,
              description: descriptionReport,
            };
            axios(commRegisterGateway(gateway))
            .then(function (response) {
              // 성공
              uploadReports.find(item => item.id === idReport).result = "등록 완료";
              if (uploadReports.filter(item => item.result === "").length === 0){
                setReports(uploadReports);
                setUploadOpen(true);
              }
              // 배열 수정
              const idNew = getId(gateways);
              const gateway = {
                id: idNew, 
                gatewayID: idReport, 
                gatewayName: idReport, 
                description: descriptionReport,
                shipNo: "",
                lat: null, 
                lng: null, 
                lastComm: "", 
                select: false
              };
              dispatch(addGateway(gateway));
              commRegisterGatewayDB(gateway)
            })
            .catch(function (error) {
              // 실패
              uploadReports.find(item => item.id === idReport).result = "서버 등록 실패";
              if (uploadReports.filter(item => item.result === "").length === 0){
                setReports(uploadReports);
                setUploadOpen(true);
              }
            });
          }
          else {
            // 등록할 device 초기값 만들기
            const idNew = getId(devices);
            const deviceNew = {
              id: idNew,
              no: 9, 
              devEui: idReport, 
              name: idReport, 
              description: descriptionReport, 
              shipNo: null,
              battery: 0,
              lat: null,
              lng: null,
              state: 0x00, 
              power: 1,
              workend: 0,
              distanceAlarm: true,
              distance: 3,
              LedOnTime: null,
              onoffPeriod: 1,
              duration: 3,
              commPeriod: 2,
              lastComm: null,
              marker: null, 
              overlay: null, 
              select: false
            };
      
            // DB에 등록 먼저
            axios({
              method: "POST",
              url: 'api/devicedb/register',
              data: deviceNew
            }).then(function(response) {
              //console.log("DB 등록결과...");
              //console.log(response);
      
              // DB 등록결과로 받은 키값을 사용하여 네트워크 서버에 등록
              const device = {
                devEui: idReport,
                name: idReport,
                description: descriptionReport,
                appskey: response.data.appskey,
                nwkskey: response.data.nwkskey,
              };
              axios(commRegisterDevice(device))
              .then(function (response) {
                //console.log("success");
                
                axios(commActivateDevice(device))
                .then(function (response) {
                  // 성공
                  uploadReports.find(item => item.id === idReport).result = "등록 완료";
                  if (uploadReports.filter(item => item.result === "").length === 0){
                    setReports(uploadReports);
                    setUploadOpen(true);
                  }
        
                  // 배열에 device 추가
                  deviceNew.appskey = device.appskey;
                  deviceNew.nwkskey = device.nwkskey;
                  dispatch(addDevice(deviceNew));
                  dispatch(addDeviceToAdd(deviceNew.devEui));  //map에 등록
                  setAlert("어망을 등록하였습니다!");
                })
                .catch(function (error) {
                  // 실패
                  uploadReports.find(item => item.id === idReport).result = "서버 등록 실패 2";
                  if (uploadReports.filter(item => item.result === "").length === 0){
                    setReports(uploadReports);
                    setUploadOpen(true);
                  }

                  // DB에 조금전에 추가한 device 삭제해야함
                  commDeleteDeviceDB(deviceNew.devEui);
                });
        
              })
              .catch(function (error) {
                // 실패
                uploadReports.find(item => item.id === idReport).result = "서버 등록 실패 1";
                if (uploadReports.filter(item => item.result === "").length === 0){
                  setReports(uploadReports);
                  setUploadOpen(true);
                }
                
                // DB에 조금전에 추가한 device 삭제해야함
                commDeleteDeviceDB(deviceNew.devEui);
              });            
                      
            }).catch(error=>{
              console.log(error);
              alert("어망 DB 등록 실패");
            });

          }
        }
      });        
    })
    e.target.value = '';
  };

  const exelDownload =() => {
    //console.log("exelDownload");
    var results = [];
    (isGateway? gateways:devices).forEach(item => {
      var result;
      if (isGateway){
        result = {gatewayID: item.gatewayID, memo: item.description};
      }
      else{
        result = {devEui: item.devEui, memo: item.description};
      }
      results = [...results, result];
    });
    //console.log(results);

    const datas = XLSX.utils.json_to_sheet(results);
    datas["!cols"] = [{wpx: 150}, {wpx: 300}];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, datas, isGateway? 'gateways':'devices');
    XLSX.writeFile(workbook, isGateway? 'gateways.xlsx':'devices.xlsx');
  }

  function deleteClicked(){
    const items = (isGateway? gateways:devices).filter(item => item.select === true);
    if (items.length < 1){
      setAlert("선택된 항목이 없습니다.");
      return;
    }
    setCheckDelete(true);
  }

  function editClicked(){
    const items = (isGateway? gateways:devices).filter(item => item.select === true);
    if (items.length < 1){
      setAlert("선택된 항목이 없습니다.");
      return;
    }
    if (items.length > 1){
      setAlert("한 항목만 선택해주세요.");
      return;
    }
    const editItem = (isGateway? gateways:devices).find(item => item.select === true);
    setIdEdit(isGateway? editItem.gatewayID:editItem.devEui);
    setMemoEdit(editItem.description);
    setModalDeviceEditOpen(true);
  }

  function displayListItem(){
  //  console.log("display update");
    console.log(isGateway);
    console.log(list);
  }

  function closeClicked(){
    resetInputs();
    setIsSearchMode(false);
    setPage(1);
    const items = (isGateway? gateways : devices);
    items.forEach(item => {
      if (isGateway)
        dispatch(updateGateway(item.gatewayID, 'select', false));
      else
        dispatch(updateDevice(item.devEui, 'select', false));
    })
    const newList = items.slice(0, itemsInPage);
    setPageEnd(parseInt(items.length/itemsInPage) + 1);
    setList(newList);
    //console.log(newList);

    close();
  }

  const [startX, setStartX] = useState(0);
  const [width, setWidth] = useState(0);
  const [total, setTotal] = useState(0);

  let root = document.querySelector(':root');
  let variables = getComputedStyle(root); 
  

  return (
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <div className='modalDevice'>
            <div className='modalDevice_title'>
                {"장치등록"}
                <BiX className='modalDevice_button1' onClick={closeClicked}></BiX>
            </div>
            <div className='modalDevice_content'>
                <div className='modalDevice_left'>
                    <div className='modalDevice_items'>
                        <div className={isGateway? 'modalDevice_item_selected':'modalDevice_item_unselected'}
                             onClick={()=>{setGateway(true)}}>
                            <div>게이트웨이 등록</div>
                            <BiAlignLeft></BiAlignLeft>
                        </div>
                        <div className={isGateway? 'modalDevice_item_unselected':'modalDevice_item_selected'}
                             onClick={()=>{setGateway(false)}}>
                            <div>어망 등록</div>
                            <BiAlignLeft></BiAlignLeft>
                        </div>
                    </div>
                </div>
                <div className='modalDevice_right'>
                    <div className='modalDevice_right_title'>
                        <div>{isGateway? '게이트웨이':'어망'}</div>
                    </div>
                    <div className='modalDevice_content'>
                        <div className='modalDevice_up'>
                          <div className='modalDevice_register'>
                            <div className='modalDevice_flex'>
                              <div className='width_30'>ID</div>
                              <div style={{width:'10px'}}/>
                              <input style={{width:'200px', height:'25px'}} onChange={onChange1}
                                onKeyDown={(e) => handleOnKeyPress2(e)}  value={id}></input>
                              <div className='modalDevice_button2' onClick={idOverlapCheck}>중복검사</div>
                            </div>
                            
                            <div className='modalDevice_flex'>
                              <div className='width_30'>{"메모"}</div>
                              <div style={{width:'10px'}}/>
                              <input style={{width: '400px', height: '24px'}} onChange={onChange3} value={description}></input>
                            </div>
                            <div className='modalDevice_button2_1' onClick={register}>등록</div>
                          </div>
                          
                          <div className='modalDevice_search'>
                            <div className='width_30'>{"검색"}</div>

                            <div className='modalDevice_searchBox'>
                              <input className='searchInput' onChange={onChange4}
                                placeholder='ID, 메모를 검색합니다'
                                onKeyDown={(e) => handleOnKeyPress1(e)} value={searchContent}></input>
                              <BiSearch className='modalDevice_searchIcon' onClick={search} />
                            </div>
                          </div>

                        </div>
                    </div>
                    
                    <div className='modalDevice_result'>
                      <div className='modalDevice_result_1'>
                        <div className='modalDevice_flexLeft'>
                          <div className='modalDevice_button2_1' onClick={deleteClicked}>삭제</div>
                        </div>
                        <div>{"Total     " + (isSearchMode? searchList.length:(isGateway? gateways.length:devices.length)) + "건"}</div>
                      </div>
                      <div className='modalDevice_result_2'>
                        <div className='modalDevice_itemTitle'>
                          <div className='modalDevice_itemTitleSub1' onDragOver={(e)=>{e.preventDefault()}} onClick={selectAllClicked}>선택</div>
                          
                          <div className='modalDevice_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                            onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                              setWidth(variables.getPropertyValue('--device_1').slice(0,-2));
                              setTotal(variables.getPropertyValue('--device_total').slice(0,-2));}}
                            onDrag={(e)=>{document.documentElement.style.setProperty('--device_1', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                            onDragEnd={(e)=>{document.documentElement.style.setProperty('--device_1', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                              document.documentElement.style.setProperty('--device_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                          <div className='modalDevice_itemTitleSub2' onDragOver={(e)=>{e.preventDefault()}}>ID</div>
                          
                          <div className='modalDevice_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                            onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                              setWidth(variables.getPropertyValue('--device_2').slice(0,-2));
                              setTotal(variables.getPropertyValue('--device_total').slice(0,-2));}}
                            onDrag={(e)=>{document.documentElement.style.setProperty('--device_2', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                            onDragEnd={(e)=>{document.documentElement.style.setProperty('--device_2', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                              document.documentElement.style.setProperty('--device_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                          <div className='modalDevice_itemTitleSub3' onDragOver={(e)=>{e.preventDefault()}}>메모</div>
                        
                          <div className='modalDevice_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                            onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                              setWidth(variables.getPropertyValue('--device_3').slice(0,-2));
                              setTotal(variables.getPropertyValue('--device_total').slice(0,-2));}}
                            onDrag={(e)=>{document.documentElement.style.setProperty('--device_3', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                            onDragEnd={(e)=>{document.documentElement.style.setProperty('--device_3', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                              document.documentElement.style.setProperty('--device_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                        </div>
                        <div className='modalDevice_items'>
                          {isGateway?
                            <div>
                              {list.map((gateway)=>(
                                <div className='modalDevice_item' key={gateway.gatewayID}>
                                  <div className='modalDevice_itemSub1'>
                                    {gateway.select?
                                      <ImCheckboxChecked onClick={() => clickSelect(gateway.gatewayID)}/> :
                                      <ImCheckboxUnchecked onClick={() => clickSelect(gateway.gatewayID)}/>}
                                  </div>
                                  <div className='modalDevice_itemSub2'>{gateway.gatewayID.toUpperCase()}</div>
                                  <div className='modalDevice_itemSub3'>{gateway.description}</div>
                                </div>                  
                              ))}</div>
                            : <div>
                            {list.map((device)=>(
                              <div className='modalDevice_item' key={device.devEui}>
                                <div className='modalDevice_itemSub1'>
                                  {device.select?
                                    <ImCheckboxChecked onClick={() => clickSelect(device.devEui)}/> :
                                    <ImCheckboxUnchecked onClick={() => clickSelect(device.devEui)}/>}
                                </div>
                                <div className='modalDevice_itemSub2'>{device.devEui.toUpperCase()}</div>
                                <div className='modalDevice_itemSub3'>{device.description}</div>
                              </div>                  
                            ))}
                            </div>
                          }
                        </div>
                      </div>

                      <div style={{height:'8px'}}/>

                      <div className='modalDevice_flexSpaceBetween'>
                        <div className='modalDevice_exel'>
                          <label className="modalDevice_button4" for="input-file3">엑셀등록</label>
                          <input type="file" id="input-file3" onChange={exelOpen} style={{display:"none"}}/>
                          <div style={{width: "5px"}}/>
                          <div className="modalDevice_button4" onClick={exelDownload}>엑셀저장</div>
                          <div style={{width: "30px"}}/>
                        </div>
                        
                        <div className='modalDevice_pages'>
                          <AiFillStepBackward className={(page>pageStart)? 'pageArrow':'pageArrow_Disabled'}
                            onClick={()=>{
                              if(page>pageStart){
                                setPage(pageStart); drawPage(pageStart);
                            }}}/>
                          <AiFillCaretLeft className={(page>pageStart)? 'pageArrow':'pageArrow_Disabled'}
                            onClick={()=>{
                              if(page>pageStart){
                                setPage(page - 1); 
                                drawPage(page - 1);
                            }}}/>
                          <RxDividerVertical/>
                          <div style={{width: "5px"}}/>
                          <input className='pageInput' onChange={changePage}
                            onKeyDown={(e) => pageEntered(e)} value={page}></input>
                          <div style={{width: "5px"}}/>
                          <div className='pagePage'>{'/ '+ pageEnd}</div>
                          <div style={{width: "5px"}}/>
                          <RxDividerVertical/>
                          <AiFillCaretRight className={(page<pageEnd)? 'pageArrow':'pageArrow_Disabled'}
                            onClick={()=>{
                              if(page<pageEnd){
                                setPage(page + 1); 
                                drawPage(page + 1);
                            }}}/>
                          <AiFillStepForward className={(page<pageEnd)? 'pageArrow':'pageArrow_Disabled'}
                            onClick={()=>{
                              if(page<pageEnd){
                                setPage(pageEnd); 
                                drawPage(pageEnd);
                            }}}/>
                        </div>
                      </div>

                    </div>
                </div>          
            </div>
            <ModalDeviceEdit
              open={modalDeviceEditOpen}
              close={() => {
                setModalDeviceEditOpen(false);
                if (isGateway){
                  const gateway = gateways.find((gateway) => (gateway.gatewayID === idEdit));
                  dispatch(updateGateway(gateway.gatewayID, 'select', false));
                }
                else{
                  const device = devices.find((device) => (device.devEui === idEdit));
                  dispatch(updateDevice(device.devEui, 'select', false));
                }
              }}
              isGateway={isGateway}
              id={idEdit}
              memo={memoEdit} />
        </div>
      ) : null}      
      {checkDelete && (
        <Confirm 
          content={isGateway? "게이트웨이를 삭제하시겠습니까?":"어망을 삭제하시겠습니까?"}
          ok={deleteItem} 
          close={()=>setCheckDelete(false)}/>
      )}
      {alertOpen && (
        <MyAlert content={alertContent} ok={()=>setAlertOpen(false)}/>
      )}
      <ModalUpload
        open={uploadOpen}
        close={()=>setUploadOpen(false)}
        title={isGateway? "게이트웨이 등록":"어망 등록"}
        isGateway={isGateway}
        uploadReports={reports}  
      />
    </div>
  );
};

export default ModalDevice