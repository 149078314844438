import {React, useState, useEffect} from 'react';
import './modalDeviceEdit.css';
import UseContextMenu from '../popupMenu/UseContextMenu';
import { BiX } from 'react-icons/bi';
import { useSelector, useDispatch } from "react-redux";
import { updateDevice } from '../../redux/modules/devices';
import { Confirm, MyAlert } from '../popupMenu/Confirm';
import { updateGateway } from '../../redux/modules/gateways';
import axios from 'axios';
import {commRegisterGateway, commUpdateGateway, commDeleteGateway, commRegisterDevice, commActivateDevice, commUpdateDevice, commDeleteDevice} from '../comm/Comm';
import {commDeleteDeviceDB} from '../comm/CommUpdateDB';

const ModalDeviceEdit = (props) => {
  const devices = useSelector((state) => state.devices);
  const dispatch = useDispatch();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [id, setId] = useState('');
  const [memo, setMemo] = useState('');

  const setAlert = (content) => {
    setAlertContent(content);
    setAlertOpen(true);
  }

  useEffect(() => {
    if (props.open){
        setId(props.id);
        setMemo(props.memo);
    }
  }, [props.open]);

  function okClicked(){
    if (id.length !== 16){
      setAlert("ID의 글자수가 올바르지 않습니다.");
      return;
    }
    
    if (id !== props.id){
      // ID가 변경된 경우
      if (props.isGateway){
        // gateway 삭제
        axios(commDeleteGateway(props.id))
        .then(function (response) {
          //console.log("delete success");

          // gateway 신규등록
          const gateway = {
            id: id,
            name: id,
            description: memo,
          };
          axios(commRegisterGateway(gateway))
          .then(function (response) {
            console.log("success");
            // 배열 수정
            dispatch(updateGateway(props.id, 'gatewayID', id));
            dispatch(updateGateway(props.id, 'description', memo));

            // DB 수정 1 - props.id를 가진 gateway의 gatewayID: id, description: memo로 수정
            
            setAlert("게이트웨이 정보를 수정하였습니다!");
          })
          .catch(function (error) {
            //console.log("error"+error);
            setAlert("수정 오류가 발생하였습니다!");
          });
        })
        .catch(function (error) {
          //console.log(error);
          setAlert("수정 오류가 발생하였습니다.");
        });
      }
      else{
        const devicePrev = devices.find(device => device.devEui === props.id);
        var deviceNew = devicePrev;
        deviceNew.devEui = id;
        deviceNew.name = id;
        deviceNew.description = memo;

        // DB에 등록 먼저
        axios({
          method: "POST",
          url: 'api/devicedb/register',
          data: deviceNew
        }).then(function(response) {
          // DB 등록결과로 받은 키값을 사용하여 네트워크 서버에 등록
          const device = {
            devEui: id,
            name: id,
            description: memo,
            appskey: response.data.appskey,
            nwkskey: response.data.nwkskey,
          };
          axios(commRegisterDevice(device))
          .then(function (response) {
            console.log("success");
            
            axios(commActivateDevice(device))
            .then(function (response) {
              console.log("activation success");
    
              // 배열에 device 추가
              dispatch(updateDevice(props.id, 'description', memo));
              dispatch(updateDevice(props.id, 'appskey', device.appskey));
              dispatch(updateDevice(props.id, 'nwkskey', device.nwkskey));
              dispatch(updateDevice(props.id, 'devEui', id));

              setAlert("어망을 등록하였습니다!");
            })
            .catch(function (error) {
              //console.log(error);
              setAlert("등록 오류가 발생하였습니다!");

              // DB에 조금전에 추가한 device 삭제해야함
              commDeleteDeviceDB(deviceNew.devEui);
            });
          })
          .catch(function (error) {
            //console.log(error);
            setAlert("등록 오류가 발생하였습니다!");
            
            // DB에 조금전에 추가한 device 삭제해야함
            commDeleteDeviceDB(deviceNew.devEui);
          });            
                  
        }).catch(error=>{
          console.log(error);
          alert("어망 DB 등록 실패");
        });

        // 네트워크 서버에서 device 삭제
        axios(commDeleteDevice(props.id))
        .then(function (response) {
          //console.log("delete success");

          // DB에서 device 삭제
          commDeleteDeviceDB(props.id);
        })
        .catch(function (error) {
          //console.log(error);
          setAlert("삭제 오류가 발생하였습니다.");
        });
      }
    }
    else if (memo !== props.memo){
      // Memo만 변경된 경우
      if (props.isGateway){
        const gatewayNew = {
          id: id,
          name: id,
          description: memo,
        };
        axios(commUpdateGateway(gatewayNew))
        .then(function (response) {
          console.log("success");
          // 배열 수정
          dispatch(updateGateway(props.id, 'description', memo));
          // DB 업데이트 - gateway의 memo만 변경된 경우

          setAlert("게이트웨이 정보를 수정하였습니다!");
        })
        .catch(function (error) {
          setAlert("수정 오류가 발생하였습니다!");
        });        
      }
      else{
        const deviceNew = {
          id: id,
          name: id,
          description: memo,
        };
        axios(commUpdateDevice(deviceNew))
        .then(function (response) {
          console.log("success");
          // 배열 수정
          dispatch(updateDevice(props.id, 'description', memo));
          // DB 업데이트 - device의 memo만 변경된 경우

          setAlert("어망 정보를 수정하였습니다!");
        })
        .catch(function (error) {
          setAlert("수정 오류가 발생하였습니다!");
        }); 
      }
    }
    props.close();
  };

  function cancleClicked(){
    props.close();
  };

  const onChange1 = (e) => {
    setId(e.target.value.toUpperCase());
  };
  const onChange2 = (e) => {
    setMemo(e.target.value.toUpperCase());
  };

  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {

    }
  };

  return (
    <div className={props.open ? 'openModal modal' : 'modal'}>
      {props.open ? (
        <div className='modalDeviceEdit'>
        <div className='modalDeviceEdit_title'>
            {props.isGateway? "게이트웨이 수정":"어망 수정"}
            <BiX className='modalDeviceEdit_button1' onClick={props.close}></BiX>
        </div>
        <div className='modalDeviceEdit_content'>
          <div className='modalDeviceEdit_right'>
            
            <div className='modalDeviceEdit_item'>
                <div className='modalDeviceEdit_listitemTitleSub1'>ID</div>
                <input className='modalDeviceEdit_input' onChange={onChange1} value={id} />                    
            </div>
            <div className='modalDeviceEdit_item'>
                <div className='modalDeviceEdit_listitemTitleSub1'>Memo</div> 
                <textarea className='modalDeviceEdit_textarea' onChange={onChange2} value={memo}/>                
            </div>
          
            <div style={{height: '15px'}} />
            <div className='modalDeviceEdit_storeCancel'>
              <div className='modalDeviceEdit_storeButton' onClick={okClicked}>저장</div>
              <div className='modalDeviceEdit_Hblank_20' />
              <div className='modalDeviceEdit_storeButton' onClick={cancleClicked}>취소</div>
            </div>

          </div>
        </div>
      </div>
      ) : null}
      {alertOpen && (
        <MyAlert content={alertContent} ok={()=>setAlertOpen(false)}/>
      )}
    </div>
  );
};

export default ModalDeviceEdit