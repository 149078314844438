import React, { Component } from 'react';
import UserMenu, { UserMenuItem } from '../../components/Base/UserMenu';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as baseActions from '../../redux/modules/base';
import * as userActions from '../../redux/modules/user';
import * as modalActions from '../../redux/modules/modalState';

import storage from '../../lib/storage';

import onClickOutside from 'react-onclickoutside';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class UserMenuContainer extends Component {

    handleClickOutside = (e) => {
        const { BaseActions } = this.props;
        BaseActions.setUserMenuVisibility(false);
    }

    logoutCheck = () => {
        const { ModalActions, BaseActions } = this.props; 
        BaseActions.setUserMenuVisibility(false);          
        ModalActions.updateModalState('logout', true);
        /*const { handleLogout } = this;

        confirmAlert({
            title: '로그아웃',
            message: '정말 로그아웃 하시겠습니까?',
            buttons: [
              {
                label: '네',
                onClick: handleLogout
              },
              {
                label: '아니오',
                onClick: () => {}
              }
            ]
          });
          */
    }

    updateUserInfo = () => {        
        const { ModalActions, BaseActions } = this.props;           
        ModalActions.updateModalState('userinfo', true);
        BaseActions.setUserMenuVisibility(false);
    }

    handleLogout = async () => {
        const { UserActions } = this.props;

        try {
            await UserActions.logout();
        } catch (e) {
            console.log(e);
        }

        storage.remove('loggedInfo');
        window.location.href = '/';
    }

    render() {
        const {  visibleUserMenu, username } = this.props;
        const { updateUserInfo, logoutCheck } = this;

        if(!visibleUserMenu) {
            return null;
        }

        return (
            <UserMenu>                
                <UserMenuItem onClick={updateUserInfo}>정보수정</UserMenuItem>
                <UserMenuItem onClick={logoutCheck}>로그아웃</UserMenuItem>
            </UserMenu>
        );
    }
}

export default connect(
    (state) => ({
        visibleUserMenu: state.base.getIn(['userMenu', 'visibleUserMenu']),
        username: state.user.getIn(['loggedInfo', 'username'])
    }),
    (dispatch) => ({
        BaseActions: bindActionCreators(baseActions, dispatch),
        UserActions: bindActionCreators(userActions, dispatch),
        ModalActions: bindActionCreators(modalActions, dispatch)
    })
)(onClickOutside(UserMenuContainer));