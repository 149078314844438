import {React, useEffect, useState} from 'react';
import './infoWindow.css';
import { BiX } from 'react-icons/bi';
import { useSelector } from "react-redux";

const InfoWindow = (props) => {
  const curDevInfo = useSelector((state) => state.curDevInfo);
  const curShip = useSelector((state) => state.curShip);
  const curDevice = useSelector((state) => state.curDevice);
  const devices = useSelector((state) => state.devices);
  const users = useSelector((state) => state.users);
  const ships = useSelector((state) => state.users);
  const [owner, setOwner] = useState();
  const [captain, setCaptain] = useState();

  useEffect(()=>{
    if (users !== null){
      setOwner(users.find(user=>user.userid === curShip.ownerID));
      setCaptain(users.find(user=>user.userid === curShip.captainID));
    }
  }, [ships, curShip]);
  
/*  useEffect(()=>{
    console.log(curDevice);
    console.log(devices);
  }, [curDevice]);
*/
  function commPeriodFromDevice(device){
    switch(device.commPeriod){
      case 1: return('10분');
      case 3: return('30분');
      case 4: return('40분');
      case 5: return('50분');
      case 6: return('1시간');
      case 12: return('2시간');
      case 18: return('3시간');
      case 24: return('4시간');
      default: return('-');
    }
  }
  function distanceFromDevice(device){
    if (device.distanceAlarm === false){
      return("미사용");
    }
    switch(device.distance){
      case 3: return('30m');
      case 4: return('40m');
      case 5: return('50m');
      case 6: return('60m');
      case 7: return('70m');
      case 8: return('80m');
      case 9: return('90m');
      case 10: return('100m');
      case 11: return('110m');
      case 12: return('120m');
      case 13: return('130m');
      case 14: return('140m');
      case 15: return('150m');
      case 16: return('160m');
      case 17: return('170m');
      case 18: return('180m');
      case 19: return('190m');
      case 20: return('200m');
      default: return('-');
    }
  }

  function curState(state){
    if (state & 0x30){
      if (state & 0x10){
        return ('GPS 오류');
      }
      if (state & 0x20){
        return ('위치이탈');
      }
      return ('위치이탈, GPS 오류');
    }
    return ('정상');
  }

  function getOnOffTime(value){
    switch(value){
      case 1: return ('10초');
      case 2: return ('20초');
      case 3: return ('30초');
      case 4: return ('40초');
      case 5: return ('50초');
      case 6: return ('60초');
      default: return ('30초');
    }
  }
  function getDuration(value){
    switch(value){
      case 1: return ('10분');
      case 2: return ('20분');
      case 3: return ('30분');
      case 4: return ('40분');
      case 5: return ('50분');
      case 6: return ('60분');
      default: return ('30분');
    }
  }

  return (
    <div className='infoWindow'>
      {curDevInfo.isShip ? (
        <div>
          <div className='infoWindow_title1'>
            <div>
              <span>{curShip.shipName}</span>
              <span className='infoWindow_shipSub1_2'>{' (' + curShip.shipNo + ')'}</span>
            </div>
            <BiX className='infoWindow_button1' onClick={props.close}></BiX>
          </div>
          <div className='infoWindow_items'>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>위도</div>
              <div className='infoWindow_itemRight'>{curShip.lat}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>경도</div>
              <div className='infoWindow_itemRight'>{curShip.lng}</div>
            </div>
            <div className='infoWindow_line'></div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>게이트웨이</div>
              <div className='infoWindow_itemRight'>{curShip.gatewayID.toUpperCase()}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>설치일</div>
              <div className='infoWindow_itemRight'>{curShip.gatewayInstallDay}</div>
            </div>
            <div className='infoWindow_line'></div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>선주이름</div>
              <div className='infoWindow_itemRight'>
                {(owner !== undefined)? owner.name:'-'}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>선주번호</div>
              <div className='infoWindow_itemRight'>
                {(owner !== undefined)? owner.phone:'-'}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>이메일</div>
              <div className='infoWindow_itemRight'>
                {(owner !== undefined)? owner.email:'-'}</div>
            </div>
            <div className='infoWindow_line'></div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>선장이름</div>
              <div className='infoWindow_itemRight'>
                {(captain !== undefined)? captain.name:'-'}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>선장번호</div>
              <div className='infoWindow_itemRight'>
                {(captain !== undefined)? captain.phone:'-'}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>이메일</div>
              <div className='infoWindow_itemRight'>
                {(captain !== undefined)? captain.email:'-'}</div>
            </div>
            <div className='infoWindow_line'></div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>최근통신</div>
              <div className='infoWindow_itemRight'>{curShip.lastComm}</div>
            </div>
          </div>
        </div>
        ) : 
        <div>
          <div className='infoWindow_title1'>
          <div>
            {'부이'}
            <span style={{fontSize:'13px'}}>{' ('+curDevice.devEui.toUpperCase()+')'}</span></div>
            <BiX className='button1' onClick={props.close}></BiX>
          </div>
          <div className='infoWindow_items'>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>위도</div>
              <div className='infoWindow_itemRight'>{curDevice.lat}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>경도</div>
              <div className='infoWindow_itemRight'>{curDevice.lng}</div>
            </div>
            <div className='infoWindow_line'></div>
            <div className='line'></div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>어선번호</div>
              <div className='infoWindow_itemRight'>{curShip.shipNo}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>어선명</div>
              <div className='infoWindow_itemRight'>{curShip.shipName}</div>
            </div>
            <div className='infoWindow_line'></div>
            <div className='line'></div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>선주이름</div>
              <div className='infoWindow_itemRight'>
                {(owner !== undefined)? owner.name:'-'}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>선주번호</div>
              <div className='infoWindow_itemRight'>
                {(owner !== undefined)? owner.phone:'-'}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>이메일</div>
              <div className='infoWindow_itemRight'>
                {(owner !== undefined)? owner.email:'-'}</div>
            </div>
            <div className='infoWindow_line'></div>
            <div className='line'></div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>선장이름</div>
              <div className='infoWindow_itemRight'>
                {(captain !== undefined)? captain.name:'-'}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>선장번호</div>
              <div className='infoWindow_itemRight'>
                {(captain !== undefined)? captain.phone:'-'}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>이메일</div>
              <div className='infoWindow_itemRight'>
                {(captain !== undefined)? captain.email:'-'}</div>
            </div>
            <div className='infoWindow_line'></div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>보고간격</div>
              <div className='infoWindow_itemRight'>{commPeriodFromDevice(curDevice)}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>이탈거리</div>
              <div className='infoWindow_itemRight'>{distanceFromDevice(curDevice)}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>배터리</div>
              <div className='infoWindow_itemRight'>{curDevice.battery+'%'}</div>
            </div>

            <div className='infoWindow_line'></div>

            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>점멸주기</div>
              <div className='infoWindow_itemRight'>{getOnOffTime(curDevice.onoffPeriod)}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>유지시간</div>
              <div className='infoWindow_itemRight'>{getDuration(curDevice.duration)}</div>
            </div>
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>점등시간</div>
              <div className='infoWindow_itemRight'>
                {(curDevice.LedOnTime === '01-01 09:00')? '-':curDevice.LedOnTime}</div>
            </div>
            
            <div className='infoWindow_line'></div>

            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>이상유무</div>
              <div className={(curDevice.state & 0xF0)? 'infoWindow_itemRight_red':'infoWindow_itemRight'}>
                {curState(curDevice.state)}</div>
            </div>            
            <div className='infoWindow_item'>
              <div className='infoWindow_itemLeft'>최근통신</div>
              <div className='infoWindow_itemRight'>{curDevice.lastComm}</div>
            </div>
            
          </div>
        </div>
      }
        
    </div>
    //</InformationWindow>
  );
};

export default InfoWindow