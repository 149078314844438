import React from 'react';
import styled from 'styled-components';
import oc from 'open-color';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

// 두개가 함께 있을땐 상단 (그 사이) 에 여백을 준다
const Wrapper = styled.div`
    & + & {
        margin-top: 0.8rem;
    }
`;

const Label = styled.div`
    font-size: 1rem;
    color: ${oc.gray[6]};
    margin-top: 1rem;
    margin-bottom: 0.25rem;
`;

const Input = styled.input`
    width: 100%;
    border: 1px solid ${oc.gray[3]};
    outline: none;
    border-radius: 0px;
    line-height: 2.0rem;
    font-size: 1.0rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    ::placeholder {
        color: ${oc.gray[3]};
`;

// rest 쪽에는 onChange, type, name, value, placeholder 등의 input 에서 사용 하는 값들을 넣어줄수 있다.
const CalendarWithLabel = ({label, ...rest}) => (
    <Wrapper>
        <Label>{label}</Label>
        <Datetime value={rest.value} name={rest.name} onChange={(date) => {rest.value = date; rest.onChange(date);}} inputProps={{ placeholder: '생년월일', style: { width: '100%', border: '1px solid rgb(220,220,220)', lineHeight: '2.5rem', marginBottom: '1.0rem', fontSize: '1.0rem', paddingLeft: '0.5rem' }}} renderInput={(props, openCalendar) => (<><Input {...props} onClick={openCalendar} /></>)} dateFormat='YYYY년 MM월 DD일' timeFormat={false} closeOnSelect={true}/>
    </Wrapper>
);

export default CalendarWithLabel;