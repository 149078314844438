import React, { useEffect } from "react";
import axios from 'axios';

function SocialAuth() {
    useEffect(()=> {
        let params = new URL(document.location.toString()).searchParams;
        let code = params.get("code"); // 인가코드 받는 부분
        let grant_type = "authorization_code";
        let client_id = 'c6c06005d8f5ca1e94af6093087dbebf';
    
        axios.post(`https://kauth.kakao.com/oauth/token?
            grant_type=${grant_type}
            &client_id=${client_id}
            &redirect_uri=http://localhost:3000/auth
            &code=${code}`
            , {
        headers: {
            'Content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      }).then((res) => {
          console.log(res)
          // res에 포함된 토큰 처리
      })
      }, [])

    return (
        <div></div>
    )
}

export default SocialAuth;