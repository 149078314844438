import {React, useState, useEffect} from 'react';
import './modalShipDelete.css';
import { BiX } from 'react-icons/bi';
import { BiRectangle } from 'react-icons/bi'
import { useSelector, useDispatch } from "react-redux";

const ModalShipDelete = (props) => {
  const { open, close, ok } = props;
  const curDevInfo = useSelector((state) => state.curDevInfo);
  const curShip = useSelector((state) => state.curShip);
  const curDevice = useSelector((state) => state.curDevice);

  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <div className='modalShipDelete_modalFrame'>
          <div className='modalShipDelete_left'>
            <div className='modalShipDelete_left_up'>
              <div className='modalShipDelete_left_up1'>DELETE SHIP</div>
              <div className='modalShipDelete_left_up2'>어선삭제</div>
            </div>
            <div className='modalShipDelete_left_down'>
              {curDevInfo.isShip ? (
                <div>
                  <div><BiRectangle></BiRectangle>{' '+curShip.shipName}</div>
                  <div className='modalShipDelete_left_down_1'>{' ('+curShip.shipNo+')'}</div>
                  </div>
                ) : (
                  <div><BiRectangle></BiRectangle>
                  {' '+curDevice.devEui.toUpperCase()}</div>
                )}
            </div>
          </div>
          <div className='modalShipDelete_right'>
            <div className='modalShipDelete_right_up'>
              <div className='modalShipDelete_right_up1'>
                <BiX className='modalShipDelete_button1' onClick={close}></BiX>
              </div>
              <div className='modalShipDelete_blank_20'></div>
              <div className='modalShipDelete_right_large'>
                <div className='modalShipDelete_right_item'>
                  어선을 삭제합니다.</div>                          
              </div>
            </div>
            <div className='modalShipDelete_right_down'>
              <div className='modalShipDelete_apply' onClick={ok}>삭제</div>
              <div className='modalShipDelete_apply' onClick={close}>취소</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ModalShipDelete