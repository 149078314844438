import {React, useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import './modalShipList.css';
import UseContextMenu from '../popupMenu/UseContextMenu';
import { BiX } from 'react-icons/bi';
import {ImCheckboxChecked} from 'react-icons/im'
import {ImCheckboxUnchecked} from 'react-icons/im'
import {BiSearch} from 'react-icons/bi'
import { updateShip } from '../../redux/modules/ships';
import { Confirm, MyAlert } from '../popupMenu/Confirm';
import { commDeleteShipDB, commUpdateDeviceDB } from '../comm/CommUpdateDB';
import { deleteShip } from '../../redux/modules/ships';
import { updateModalState } from '../../redux/modules/modalState';
import { setCurDevInfo } from '../../redux/modules/curDevInfo';
import { setCurShip } from '../../redux/modules/curShip';
import { setCurDevices } from '../../redux/modules/curDevices';
import {AiFillCaretRight, AiFillCaretLeft, AiFillStepForward, AiFillStepBackward} from 'react-icons/ai';
import {RxDividerVertical} from 'react-icons/rx';
import { updateDevice } from "../../redux/modules/devices";

const ModalShipList = (props) => {
  const { open, close } = props;
  const ships = useSelector((state) => state.ships);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [searchContent, setSearchContent] = useState("");
  const [checkDelete, setCheckDelete] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const devices = useSelector((state) => state.devices);
  const XLSX = require( "xlsx" );
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [page, setPage] = useState(1);
  const [pageStart, setPageStart] = useState(1);
  const [pageEnd, setPageEnd] = useState(1);
  const [itemsInPage, setItemsInPage] = useState(15);
  
  const setAlert = (content) => {
    setAlertContent(content);
    setAlertOpen(true);
  }

  useEffect(() => {
    if (open){
      ships.forEach(ship => {
        dispatch(updateShip(ship.shipNo, 'select', false));
      });
      setSearchContent("");
      setIsSearchMode(false);
      setPage(1);
      const items = ships;
      const newList = items.slice(0, itemsInPage);
      setPageEnd(items.length? (parseInt((items.length-1)/itemsInPage) + 1):1);
      setList(newList);
      
      document.documentElement.style.setProperty('--shipList_1', variables.getPropertyValue('--shipList_1_init'));
      document.documentElement.style.setProperty('--shipList_2', variables.getPropertyValue('--shipList_2_init'));
      document.documentElement.style.setProperty('--shipList_3', variables.getPropertyValue('--shipList_3_init'));
      document.documentElement.style.setProperty('--shipList_4', variables.getPropertyValue('--shipList_4_init'));
      document.documentElement.style.setProperty('--shipList_5', variables.getPropertyValue('--shipList_5_init'));
      document.documentElement.style.setProperty('--shipList_6', variables.getPropertyValue('--shipList_6_init'));
      document.documentElement.style.setProperty('--shipList_7', variables.getPropertyValue('--shipList_7_init'));
      document.documentElement.style.setProperty('--shipList_8', variables.getPropertyValue('--shipList_8_init'));
      document.documentElement.style.setProperty('--shipList_9', variables.getPropertyValue('--shipList_9_init'));
      document.documentElement.style.setProperty('--shipList_total', variables.getPropertyValue('--shipList_total_init'));
    }
    else{
      setIsSearchMode(false);
    }
  }, [open]);

  useEffect(() => {
    if (isSearchMode){
      const items = (ships.filter(ship => 
        ship.shipNo.includes(searchContent.toUpperCase()) ||
        ship.shipName.toUpperCase().includes(searchContent.toUpperCase()) ||
        (users.find(user => user.userid === ship.ownerID)? users.find(user => user.userid === ship.ownerID).name.toUpperCase().includes(searchContent.toUpperCase()):false) ||
        (users.find(user => user.userid === ship.ownerID)? users.find(user => user.userid === ship.ownerID).phone.toUpperCase().includes(searchContent.toUpperCase()):false) ||
        (users.find(user => user.userid === ship.captainID)? users.find(user => user.userid === ship.captainID).name.toUpperCase().includes(searchContent.toUpperCase()):false) ||
        (users.find(user => user.userid === ship.captainID)? users.find(user => user.userid === ship.captainID).phone.toUpperCase().includes(searchContent.toUpperCase()):false) ));
      const newList = items.slice((page-1)*itemsInPage, (page)*itemsInPage);
      setPageEnd(items.length? (parseInt((items.length-1)/itemsInPage) + 1):1);
      setList(newList);
      setSearchList(items);
    }
    else{
      const items = ships;
      const newList = items.slice((page-1)*itemsInPage, (page)*itemsInPage);
      setPageEnd(items.length? (parseInt((items.length-1)/itemsInPage) + 1):1);
      setList(newList);
    }
  }, [ships]);

  const drawPage = (curPage) => {
    var newList;
    if (isSearchMode){
      newList = searchList.slice((curPage-1)*itemsInPage, (curPage)*itemsInPage);
    }
    else{
      newList = ships.slice((curPage-1)*itemsInPage, (curPage)*itemsInPage);
    }
    setList(newList);
  }

  function clickSelect(shipNo){
    const ship = ships.find(ship => ship.shipNo === shipNo);
    dispatch(updateShip(ship.shipNo, 'select', !ship.select));
  }

  const onChange = (e) => {
    setSearchContent(e.target.value);
  };
  const changePage = (e) => {
    setPage(e.target.value);
  }
  const pageEntered = (e) => {
    if (e.key === 'Enter') {
      if (page >= pageStart && page <= pageEnd){
        drawPage(page);
      }
    }
  }

  function search(){
    const items = (ships.filter(ship => 
      ship.shipNo.toUpperCase().includes(searchContent.toUpperCase()) ||
      ship.shipName.toUpperCase().includes(searchContent.toUpperCase()) ||
      (users.find(user => user.userid === ship.ownerID)? users.find(user => user.userid === ship.ownerID).name.toUpperCase().includes(searchContent.toUpperCase()):false) ||
      (users.find(user => user.userid === ship.ownerID)? users.find(user => user.userid === ship.ownerID).phone.toUpperCase().includes(searchContent.toUpperCase()):false) ||
      (users.find(user => user.userid === ship.captainID)? users.find(user => user.userid === ship.captainID).name.toUpperCase().includes(searchContent.toUpperCase()):false) ||
      (users.find(user => user.userid === ship.captainID)? users.find(user => user.userid === ship.captainID).phone.toUpperCase().includes(searchContent.toUpperCase()):false) ));
    const newList = items.slice(0, itemsInPage);
    setPageEnd(items.length? (parseInt((items.length-1)/itemsInPage) + 1):1);
    setList(newList);
    setSearchList(items);    
    setIsSearchMode(true);
    setPage(1);
  };
  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      search();
    }
  };
  
  function deleteItem() {
    setCheckDelete(false);
    const items = ships.filter(ship => ship.select === true);    
    items.forEach(ship => {
      const devicesToInit = devices.filter(device => device.shipNo === ship.shipNo);
      devicesToInit.forEach(device => {
        dispatch(updateDevice(device.devEui, 'shipNo', ''));
        const updateDB = {devEui: device.devEui, shipNo: ''};
        commUpdateDeviceDB(updateDB);
      })
      
      dispatch(deleteShip(ship.shipNo));
      commDeleteShipDB(ship.shipNo);
    })
    setAlert("어선을 삭제하였습니다.")
  };

  function deleteClicked() {
    const items = ships.filter(ship => ship.select === true);
    if (items.length < 1){
      setAlert("삭제할 항목이 선택되지 않았습니다.");
      return;
    }
    setCheckDelete(true);
  }

  function eidtClicked() {
    const items = list.filter(ship => ship.select === true);
    if (items.length < 1){
      setAlert("수정할 항목이 선택되지 않았습니다.");
      return;
    }
    if (items.length > 1){
      setAlert("수정할 항목을 하나만 선택해 주세요.");
      return;
    }
    console.log(items);
    const ship = items[0];
    const devInfo = {isShip: true, shipNo: ship.shipNo, devEui: null, fromSide: true};
    dispatch(setCurDevInfo(devInfo));
    dispatch(setCurShip(ship));
    dispatch(setCurDevices(devices, ship.shipNo));
    
    dispatch(updateModalState('shipEdit', true));
  }

  function getOwnerName(id) {
    //console.log(id);
    const owner = users.find(user => user.userid === id);
    //console.log(owner);
    if (owner === undefined)
      return null;
    else
      return (owner.name);
  }
  function getOwnerPhone(id) {
    const owner = users.find(user => user.userid === id);
    if (owner === undefined)
      return null;
    else
      return (owner.phone);
  }
  function getCaptainrName(id) {
    const captain = users.find(user => user.userid === id);
    if (captain === undefined)
      return null;
    else
      return (captain.name);
  }
  function getCaptainPhone(id) {
    const captain = users.find(user => user.userid === id);
    if (captain === undefined)
      return null;
    else
      return (captain.phone);
  }

  function selectAllClicked(){
    if (list.length < 1)
      return;    
    const newValue = !list[0].select;
    
    list.forEach((listItem) => {
      const ship = ships.find((ship) => (ship.shipNo === listItem.shipNo));
      dispatch(updateShip(ship.shipNo, 'select', newValue));
    });
  }

  const exelDownload =() => {
    //console.log("exelDownload");
    var results = [];
    ships.map(ship => {
      var result;
      result = {shipNo: ship.shipNo, name: ship.shipName, 
                port: ship.port, gatewayID: ship.gatewayID,
                ownerName: getOwnerName(ship.ownerID), 
                onwerPhone: getOwnerPhone(ship.ownerID),
                captainName: getCaptainrName(ship.captainID),
                getCaptainPhone: getCaptainPhone(ship.captainID)
              };
      results = [...results, result];
    });
    //console.log(results);

    const datas = XLSX.utils.json_to_sheet(results);
    datas["!cols"] = [{wpx: 150}, {wpx: 100}, {wpx: 150}, {wpx: 200}, {wpx: 150}, {wpx: 200}, {wpx: 150}, {wpx: 200}];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, datas, 'ships');
    XLSX.writeFile(workbook, 'ships.xlsx');
  }

  const [startX, setStartX] = useState(0);
  const [width, setWidth] = useState(0);
  const [total, setTotal] = useState(0);

  let root = document.querySelector(':root');
  let variables = getComputedStyle(root); 

  return (
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <div className='modalShipList'>
          <div className='modalShipList_title'>
              {"어선목록"}
              <BiX className='modalShipList_button1' onClick={props.close}></BiX>
          </div>
          <div className='modalShipList_content'>
                
            <div className='modalShipList_right'>
              <div className='modalShipList_right_title'>
                  <div>어선목록</div>
              </div>
              <div>
                <div className='modalShipList_content'>
                  <div className='modalShipList_up'>
                    <div className='modalShipList_search'>
                      <div style={{fontWeight:'bold'}}>{"검색"}</div>
                      <div className='modalShipList_Hblank_10'/>
                      <div className='modalShipList_searchBox'>
                        <input className='searchInput' onChange={onChange}
                          placeholder='어선번호, 어선명, 이름, 전화번호를 검색합니다'
                          onKeyDown={(e) => handleOnKeyPress(e)} value={searchContent}></input>
                        <BiSearch className='modalShipList_searchIcon' onClick={search} />
                      </div>                      
                    </div>
                  </div>
                </div>
                        
                <div className='modalShipList_result'>
                  <div className='modalShipList_result_1'>
                    <div className='modalShipList_flex'>
                      <div className='modalShipList_button2' onClick={()=>dispatch(updateModalState('shipRegister', true))}>등록</div>
                      <div className='modalShipList_button2' onClick={eidtClicked}>수정</div>
                      <div className='modalShipList_button2' onClick={deleteClicked}>삭제</div>
                    </div>
                    <div>{"Total     " + (list.length) + "건"}</div>
                  </div>
                  
                  <div className='modalShipList_result_2'>        
                    <div className='modalShipList_itemTitle'>
                      <div className='modalShipList_itemTitleSub1' onDragOver={(e)=>{e.preventDefault()}} onClick={selectAllClicked}>선택</div>
                      
                      <div className='modalShipList_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                        onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                          setWidth(variables.getPropertyValue('--shipList_1').slice(0,-2));
                          setTotal(variables.getPropertyValue('--shipList_total').slice(0,-2));}}
                        onDrag={(e)=>{document.documentElement.style.setProperty('--shipList_1', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                        onDragEnd={(e)=>{document.documentElement.style.setProperty('--shipList_1', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                          document.documentElement.style.setProperty('--shipList_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                      <div className='modalShipList_itemTitleSub2' onDragOver={(e)=>{e.preventDefault()}}>어선번호</div>
                      
                      <div className='modalShipList_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                        onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                          setWidth(variables.getPropertyValue('--shipList_2').slice(0,-2));
                          setTotal(variables.getPropertyValue('--shipList_total').slice(0,-2));}}
                        onDrag={(e)=>{document.documentElement.style.setProperty('--shipList_2', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                        onDragEnd={(e)=>{document.documentElement.style.setProperty('--shipList_2', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                          document.documentElement.style.setProperty('--shipList_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                      <div className='modalShipList_itemTitleSub3' onDragOver={(e)=>{e.preventDefault()}}>어선명</div>
                      
                      <div className='modalShipList_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                        onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                          setWidth(variables.getPropertyValue('--shipList_3').slice(0,-2));
                          setTotal(variables.getPropertyValue('--shipList_total').slice(0,-2));}}
                        onDrag={(e)=>{document.documentElement.style.setProperty('--shipList_3', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                        onDragEnd={(e)=>{document.documentElement.style.setProperty('--shipList_3', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                          document.documentElement.style.setProperty('--shipList_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                      <div className='modalShipList_itemTitleSub4' onDragOver={(e)=>{e.preventDefault()}}>선적항</div>
                      
                      <div className='modalShipList_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                        onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                          setWidth(variables.getPropertyValue('--shipList_4').slice(0,-2));
                          setTotal(variables.getPropertyValue('--shipList_total').slice(0,-2));}}
                        onDrag={(e)=>{document.documentElement.style.setProperty('--shipList_4', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                        onDragEnd={(e)=>{document.documentElement.style.setProperty('--shipList_4', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                          document.documentElement.style.setProperty('--shipList_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                      <div className='modalShipList_itemTitleSub5' onDragOver={(e)=>{e.preventDefault()}}>게이트웨이 ID</div>
                      
                      <div className='modalShipList_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                        onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                          setWidth(variables.getPropertyValue('--shipList_5').slice(0,-2));
                          setTotal(variables.getPropertyValue('--shipList_total').slice(0,-2));}}
                        onDrag={(e)=>{document.documentElement.style.setProperty('--shipList_5', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                        onDragEnd={(e)=>{document.documentElement.style.setProperty('--shipList_5', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                          document.documentElement.style.setProperty('--shipList_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                      <div className='modalShipList_itemTitleSub6' onDragOver={(e)=>{e.preventDefault()}}>선주이름</div>
                      
                      <div className='modalShipList_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                        onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                          setWidth(variables.getPropertyValue('--shipList_6').slice(0,-2));
                          setTotal(variables.getPropertyValue('--shipList_total').slice(0,-2));}}
                        onDrag={(e)=>{document.documentElement.style.setProperty('--shipList_6', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                        onDragEnd={(e)=>{document.documentElement.style.setProperty('--shipList_6', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                          document.documentElement.style.setProperty('--shipList_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                      <div className='modalShipList_itemTitleSub7' onDragOver={(e)=>{e.preventDefault()}}>선주전화번호</div>
                      
                      <div className='modalShipList_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                        onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                          setWidth(variables.getPropertyValue('--shipList_7').slice(0,-2));
                          setTotal(variables.getPropertyValue('--shipList_total').slice(0,-2));}}
                        onDrag={(e)=>{document.documentElement.style.setProperty('--shipList_7', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                        onDragEnd={(e)=>{document.documentElement.style.setProperty('--shipList_7', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                          document.documentElement.style.setProperty('--shipList_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                      <div className='modalShipList_itemTitleSub8' onDragOver={(e)=>{e.preventDefault()}}>선장이름</div>
                      
                      <div className='modalShipList_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                        onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                          setWidth(variables.getPropertyValue('--shipList_8').slice(0,-2));
                          setTotal(variables.getPropertyValue('--shipList_total').slice(0,-2));}}
                        onDrag={(e)=>{document.documentElement.style.setProperty('--shipList_8', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                        onDragEnd={(e)=>{document.documentElement.style.setProperty('--shipList_8', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                          document.documentElement.style.setProperty('--shipList_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                      <div className='modalShipList_itemTitleSub9' onDragOver={(e)=>{e.preventDefault()}}>선장전화번호</div>
                      
                      <div className='modalShipList_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                        onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                          setWidth(variables.getPropertyValue('--shipList_9').slice(0,-2));
                          setTotal(variables.getPropertyValue('--shipList_total').slice(0,-2));}}
                        onDrag={(e)=>{document.documentElement.style.setProperty('--shipList_9', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                        onDragEnd={(e)=>{document.documentElement.style.setProperty('--shipList_9', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                          document.documentElement.style.setProperty('--shipList_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                    </div>
                    <div className='modalShipList_items'>
                      {list.map((ship)=>(
                        <div className='modalShipList_item' key={ship.shipNo}>
                          <div className='modalShipList_itemSub1'>
                            {ship.select?
                              <ImCheckboxChecked onClick={() => clickSelect(ship.shipNo)} /> :
                              <ImCheckboxUnchecked onClick={() => clickSelect(ship.shipNo)} />}</div>
                          <div className='modalShipList_itemSub2'>{ship.shipNo}</div>
                          <div className='modalShipList_itemSub3'>{ship.shipName}</div>
                          <div className='modalShipList_itemSub4'>{ship.port}</div>
                          <div className='modalShipList_itemSub5'>{ship.gatewayID}</div>
                          <div className='modalShipList_itemSub6'>{getOwnerName(ship.ownerID)}</div>
                          <div className='modalShipList_itemSub7'>{getOwnerPhone(ship.ownerID)}</div>
                          <div className='modalShipList_itemSub8'>{getCaptainrName(ship.captainID)}</div>
                          <div className='modalShipList_itemSub9'>{getCaptainPhone(ship.captainID)}</div>
                        </div>                  
                      ))}
                    </div>
                  </div>
                </div>
              </div>              

              <div className='modalShipList_flexSpaceBetween'>
                <div className='modalShipList_exel'>
                  <div className="modalShipList_button4" onClick={exelDownload}>엑셀저장</div>
                  <div style={{width: "30px"}}/>
                </div>
                
                <div className='modalShipList_pages'>
                  <AiFillStepBackward className={(page>pageStart)? 'pageArrow':'pageArrow_Disabled'}
                    onClick={()=>{
                      if(page>pageStart){
                        setPage(pageStart); drawPage(pageStart);
                    }}}/>
                  <AiFillCaretLeft className={(page>pageStart)? 'pageArrow':'pageArrow_Disabled'}
                    onClick={()=>{
                      if(page>pageStart){
                        setPage(page - 1); drawPage(page - 1);
                    }}}/>
                  <RxDividerVertical/>
                  <div style={{width: "5px"}}/>
                  <input className='pageInput' onChange={changePage}
                    onKeyDown={(e) => pageEntered(e)} value={page}></input>
                  <div style={{width: "5px"}}/>
                  <div className='pagePage'>{'/ '+ pageEnd}</div>
                  <div style={{width: "5px"}}/>
                  <RxDividerVertical/>
                  <AiFillCaretRight className={(page<pageEnd)? 'pageArrow':'pageArrow_Disabled'}
                    onClick={()=>{
                      if(page<pageEnd){
                        setPage(page + 1); drawPage(page + 1);
                    }}}/>
                  <AiFillStepForward className={(page<pageEnd)? 'pageArrow':'pageArrow_Disabled'}
                    onClick={()=>{
                      if(page<pageEnd){
                        setPage(pageEnd); drawPage(pageEnd);
                    }}}/>
                </div>
              </div>

              <div style={{height:'5px'}} />

            </div>          
          </div>
            
        </div>
      ) : null}
      {checkDelete && (
        <Confirm 
          content="어선을 삭제하시겠습니까?" 
          ok={deleteItem} 
          close={()=>setCheckDelete(false)}/>
      )}
      {alertOpen && (
        <MyAlert content={alertContent} ok={()=>setAlertOpen(false)}/>
      )}
      
    </div>
  );
};

export default ModalShipList