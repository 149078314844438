import {React, useState} from 'react';
import './modalWorkend.css';
import { BiX } from 'react-icons/bi';
import { BiRectangle } from 'react-icons/bi'
import UseContextMenu from '../popupMenu/UseContextMenu';
import axios from 'axios';
import { getCurTime, commDeleteQueue, commSendToDevice, makeDeviceData} from '../comm/Comm';
import {commUpdateDeviceDB} from '../comm/CommUpdateDB';
import { useSelector, useDispatch } from "react-redux";
import { updateDevice } from "../../redux/modules/devices";
import { updateCurDevice } from "../../redux/modules/curDevice";
import { updateModalState } from '../../redux/modules/modalState';
import { setErrorLog } from '../../redux/modules/errorLog';
import { MyAlert } from '../popupMenu/Confirm';
import {commSaveDeviceSendDataLog} from '../comm/CommUpdateDB';

var log = [];

const ModalWorkend = (props) => {
  const { open, close } = props;
  const curDevInfo = useSelector((state) => state.curDevInfo);
  const curShip = useSelector((state) => state.curShip);
  const curDevice = useSelector((state) => state.curDevice);
  const devices = useSelector((state) => state.devices);
  const dispatch = useDispatch();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const setAlert = (content) => {
    setAlertContent(content);
    setAlertOpen(true);
  }

  function executeWorkEnd(){
    log = [];
    if (curDevInfo.isShip){
        devices.filter(device => device.shipNo === curDevInfo.shipNo).forEach((device) => {
            var curLog = {no:device.no, devEui:device.devEui, shipNo:device.shipNo, result:'', description:''};
            log = [...log, curLog];
    
            const data = makeDeviceData(1, device.distance, 0, device.onoffPeriod, device.duration, device.commPeriod);
            //console.log(device.devEui+" : "+data);
    
            // 큐 삭제
            axios(commDeleteQueue(device.devEui))
            .then(function (response) {
            // 큐 삭제 확인후 데이터 송신
                const sendData = commSendToDevice(device.devEui, data);
                axios(sendData)
                .then(function (response) {
                    commSaveDeviceSendDataLog(device.devEui, sendData, "작업완료");

                    // 성공
                    log.find(item => item.devEui === device.devEui).result = true;
                    if (log.filter(item => item.result === '').length === 0){
                      if (log.filter(item => item.result === false).length > 0){
                        dispatch(setErrorLog(log));
                        dispatch(updateModalState('errorlog', true));
                      }
                      else{
                        //dispatch(setMyAlert(true, "전송완료"));
                        setAlert("전송이 완료되었습니다.");
                      }
                    }
                    //배열 수정
                    dispatch(updateDevice(device.devEui, 'workend', 1));
                    dispatch(updateDevice(device.devEui, 'state', (device.state & 0xF0)));
                    dispatch(updateDevice(device.devEui, 'lastComm', getCurTime()));
                    const updateDB = {devEui: device.devEui, workend: 1, state: (device.state & 0xF0), lastComm: getCurTime()};
                    commUpdateDeviceDB(updateDB);
                })
                .catch(function (error) {
                    log.find(item => item.devEui === device.devEui).result = false;
                    log.find(item => item.devEui === device.devEui).description = error.message;
                    if (log.filter(item => item.result === '').length === 0){
                        dispatch(setErrorLog(log));
                        dispatch(updateModalState('errorlog', true));
                    }
                });
            })
            .catch(function (error) {
                log.find(item => item.devEui === device.devEui).result = false;
                log.find(item => item.devEui === device.devEui).description = error.message;
                if (log.filter(item => item.result === '').length === 0){
                    dispatch(setErrorLog(log));
                    dispatch(updateModalState('errorlog', true));
                }
            });
        });
    }
    else{
        const data = makeDeviceData(1, curDevice.distance, 0, curDevice.onoffPeriod, curDevice.duration, curDevice.commPeriod);
        //console.log("data "+data);
        var curLog = {no:curDevice.no, devEui:curDevice.devEui, shipNo:curDevice.shipNo, result:'', description:''};
        log = [...log, curLog];

        // 큐 삭제
        axios(commDeleteQueue(curDevice.devEui))
        .then(function (response) {
            // 큐 삭제 확인후 데이터 송신
            const sendData = commSendToDevice(curDevice.devEui, data);
            axios(sendData)
            .then(function (response) {
                commSaveDeviceSendDataLog(curDevice.devEui, sendData, "작업완료");

                // 성공
                log[0].result = true;
                //dispatch(setErrorLog(log));
                //dispatch(updateModalState('errorlog', true));
                //배열 수정
                dispatch(updateCurDevice('workend', 1));
                dispatch(updateCurDevice('state', (curDevice.state & 0xF0)));
                dispatch(updateCurDevice('lastComm', getCurTime()));
                dispatch(updateDevice(curDevice.devEui, 'workend', 1));
                dispatch(updateDevice(curDevice.devEui, 'state', (curDevice.state & 0xF0)));
                dispatch(updateDevice(curDevice.devEui, 'lastComm', getCurTime()));
                const updateDB = {devEui: curDevice.devEui, workend: 1, state: (curDevice.state & 0xF0), lastComm: getCurTime()};
                commUpdateDeviceDB(updateDB);

                //dispatch(setMyAlert(true, "전송완료"));
                setAlert("전송이 완료되었습니다.");
            })
            .catch(function (error) {
                log[0].result = false;
                log[0].description = error.message;
                dispatch(setErrorLog(log));
                dispatch(updateModalState('errorlog', true));
            });
        })
        .catch(function (error) {
            log[0].result = false;
            log[0].description = error.message;
            dispatch(setErrorLog(log));
            dispatch(updateModalState('errorlog', true));
        });
    }
  }

  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <div className='modalWorkend_modalFrame'>
          <div className='modalWorkend_left'>
            <div className='modalWorkend_left_up'>
              <div className='modalWorkend_left_up1'>WORK END</div>
              <div className='modalWorkend_left_up2'>작업완료</div>
            </div>
            <div className='modalWorkend_left_down'>
              {curDevInfo.isShip ? (
                <div>
                  <div><BiRectangle></BiRectangle>{' '+curShip.shipName}</div>
                  <div className='modalWorkend_left_down_1'>{' ('+curShip.shipNo+')'}</div>
                  </div>
                ) : (
                  <div><BiRectangle></BiRectangle>
                  {' '+curDevice.devEui.toUpperCase()}</div>
                )}
            </div>
          </div>
          <div className='modalWorkend_right'>
            <div className='modalWorkend_right_up'>
              <div className='modalWorkend_right_up1'>
                <BiX className='modalWorkend_button1' onClick={close}></BiX>
              </div>
              <div className='modalWorkend_blank_20'></div>
              <div className='modalWorkend_right_large'>
                <div className='modalWorkend_right_item'>
                  어망 작업을 완료합니다.</div>                          
              </div>
            </div>
            <div className='modalWorkend_right_down'>
              <div className='modalWorkend_apply' onClick={executeWorkEnd}>전송</div>
              <div className='modalWorkend_apply' onClick={close}>취소</div>
            </div>
          </div>
        </div>
      ) : null}
      {alertOpen && (
        <MyAlert content={alertContent} ok={()=>{setAlertOpen(false); close();}}/>
      )}
    </div>
  );
};

export default ModalWorkend