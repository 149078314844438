const SET = "devicesToAdd/SET";
const ADD = "devicesToAdd/ADD";

const initialState = [];

export const setDevicesToAdd = (devices) => ({
  type: SET,
  devices: devices,
});

export const addDeviceToAdd = (device) => ({
  type: ADD,
  device: device,
});

export default function devicesToAdd(state = initialState, action) {
  switch (action.type) {
    case SET:
      return action.devices;
    case ADD:
      return [...state, action.device];
    default:
      return state;
  }
}