const SET = "loadState/SET";
const UPDATE = "loadState/UPDATE"

const initialState = {
    users: false,
    ships: false,
    gateways: false,
    devices: false,
};

export const setLoadState = (loadState) => ({
  type: SET,
  loadState: loadState,
});

export const updateLoadState = (item, value) => ({
  type: UPDATE,
  item: item,
  value: value,
});

export default function loadState(state = initialState, action) {
  switch (action.type) {
    case SET:
      return {
        user: action.loadState.users,
        ships: action.loadState.ships,
        gateways: action.loadState.gateways,
        devices: action.loadState.devices,
      };
    case UPDATE:
      return {...state, [action.item]: action.value};
    default:
      return state;
  }
}