import axios from 'axios';

export const checkEmailExists = (email) => axios.get('/api/auth/exists/email/' + email);
export const checkUsernameExists = (username) => axios.get('/api/auth/exists/username/' + username);
export const checkUseridExists = (userid) => axios.get('/api/auth/exists/userid/' + userid);

export const localRegister = ({userid, email, username, password, userbirth, userphone, useraddress}) => axios.post('/api/auth/register/local', { userid, email, username, password, userbirth, userphone, useraddress });
export const localLogin = ({userid, password}) => axios.post('/api/auth/login/local', { userid, password });

export const checkStatus = () => axios.get('/api/auth/check');
export const logout = () => axios.post('/api/auth/logout');

export const getGatewayInfo = () => axios.get('/gateways/ac1f09fffe064c1b', {headers: {"Access-Control-Allow-Origin": "*", accept: "application/json", Authorization: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6ImUwNjM0MmQ1LTM4ZGUtNGMwMi04NTRmLTU1OTM3MDhkMDYxYSIsInR5cCI6ImtleSJ9.9lxdRskEXIqIdzMhNkTga_SnIyS6tlq99oT6_Cj9lLI"}});