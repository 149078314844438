const UPDATE = "etc/UPDATE"

const initialState = {
    noShip: false,
    distanceAlarm: false,
    gpsErrorAlarm: false,
    distanceContent: "",
    gpsContent: "",
};

export const updateEtc = (item, value) => ({
  type: UPDATE,
  item: item,
  value: value,
});

export default function etc(state = initialState, action) {
  switch (action.type) {
    case UPDATE:
      return {...state, [action.item]: action.value};
    default:
      return state;
  }
}