import {React, useState, useEffect} from 'react';
import './modal.css';
import { BiX } from 'react-icons/bi';
import { BiChevronDown } from 'react-icons/bi'
import { BiRectangle } from 'react-icons/bi'
import UseContextMenu from '../popupMenu/UseContextMenu';
import { ContextMenu1 } from '../popupMenu/styles';
import axios from 'axios';
import { getCurTime, commDeleteQueue, commSendToDevice, makeDeviceData} from '../comm/Comm';
import {commUpdateDeviceDB} from '../comm/CommUpdateDB';
import { useSelector, useDispatch } from "react-redux";
import { updateDevice } from "../../redux/modules/devices";
import { updateCurDevice } from "../../redux/modules/curDevice";
import { updateModalState } from '../../redux/modules/modalState';
import { setErrorLog } from '../../redux/modules/errorLog';
import { MyAlert } from '../popupMenu/Confirm';
import {commSaveDeviceSendDataLog} from '../comm/CommUpdateDB';

var log = [];

const ModalSetting = (props) => {
  const { open, close } = props;
  const [distanceAlarm, setDistanceAlarm] = useState(false);
  const [commPeriod, setCommPeriod] = useState('30분');
  const [distance, setDistance] = useState('30m');
  const {clicked, setClicked, points, setPoints} = UseContextMenu();
  const [pulldown, setPulldown] = useState(0);
  const [request, setRequest] = useState(0);

  const curDevInfo = useSelector((state) => state.curDevInfo);
  const curShip = useSelector((state) => state.curShip);
  const curDevice = useSelector((state) => state.curDevice);
  const devices = useSelector((state) => state.devices);
  const dispatch = useDispatch();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const setAlert = (content) => {
    setAlertContent(content);
    setAlertOpen(true);
  }

  function setCommPeriodFromDevice(device){
    switch(device.commPeriod){
      case 1: setCommPeriod('10분');  break;
      case 3: setCommPeriod('30분');  break;
      case 4: setCommPeriod('40분');  break;
      case 5: setCommPeriod('50분');  break;
      case 6: setCommPeriod('1시간');  break;
      case 12: setCommPeriod('2시간');  break;
      case 18: setCommPeriod('3시간');  break;
      case 24: setCommPeriod('4시간');  break;
      default: setCommPeriod('30분');  break;
    }
  }
  function setDistanceFromDevice(device){
    switch(device.distance){
      case 3: setDistance('30m');  break;
      case 4: setDistance('40m');  break;
      case 5: setDistance('50m');  break;
      case 6: setDistance('60m');  break;
      case 7: setDistance('70m');  break;
      case 8: setDistance('80m');  break;
      case 9: setDistance('90m');  break;
      case 10: setDistance('100m');  break;
      case 11: setDistance('110m');  break;
      case 12: setDistance('120m');  break;
      case 13: setDistance('130m');  break;
      case 14: setDistance('140m');  break;
      case 15: setDistance('150m');  break;
      case 16: setDistance('160m');  break;
      case 17: setDistance('170m');  break;
      case 18: setDistance('180m');  break;
      case 19: setDistance('190m');  break;
      case 20: setDistance('200m');  break;
      default: setDistance('30m');  break;
    }
  }

  useEffect(()=>{
    if (open){
      if (curDevInfo.shipNo != null){
        var device;
        if (curDevInfo.isShip){
          setDistanceAlarm(false);
          setCommPeriod('30분');
          setDistance('30m');
        }
        else{
          device = curDevice;
          setDistanceAlarm(device.distanceAlarm);
          setCommPeriodFromDevice(device);
          setDistanceFromDevice(device);
        }
      }
    }
  }, [open]);

/* useEffect(()=>{
    //  console.log(devices);
      if (open){
        console.log("device state "+devices.find((device)=> device.devEui === curDevice.devEui).state);
        console.log("curDevice.state "+curDevice.state);
      }
    }, [open]);*/

  function apply() {
    var commPeriodValue, distanceValue;
    switch(commPeriod){
      case '10분':  commPeriodValue = 1;  break;
      case '30분':  commPeriodValue = 3;  break;
      case '40분':  commPeriodValue = 4;  break;
      case '50분':  commPeriodValue = 5;  break;
      case '1시간':  commPeriodValue = 6;  break;
      case '2시간':  commPeriodValue = 12;  break;
      case '3시간':  commPeriodValue = 18;  break;
      case '4시간':  commPeriodValue = 24;  break;
      default:      commPeriodValue = 3;  break;
    }
    switch(distance){
      case '30m':  distanceValue = 3;  break;
      case '40m':  distanceValue = 4;  break;
      case '50m':  distanceValue = 5;  break;
      case '60m':  distanceValue = 6;  break;
      case '70m':  distanceValue = 7;  break;
      case '80m':  distanceValue = 8;  break;
      case '90m':  distanceValue = 9;  break;
      case '100m':  distanceValue = 10;  break;
      case '110m':  distanceValue = 11;  break;
      case '120m':  distanceValue = 12;  break;
      case '130m':  distanceValue = 13;  break;
      case '140m':  distanceValue = 14;  break;
      case '150m':  distanceValue = 15;  break;
      case '160m':  distanceValue = 16;  break;
      case '170m':  distanceValue = 17;  break;
      case '180m':  distanceValue = 18;  break;
      case '190m':  distanceValue = 19;  break;
      case '200m':  distanceValue = 20;  break;
      default:      distanceValue = 3;  break;
    }

    log = [];
    if (curDevInfo.isShip){
      devices.filter(device => device.shipNo === curDevInfo.shipNo).forEach((device) => {
        var curLog = {no:device.no, devEui:device.devEui, shipNo:device.shipNo, result:'', description:''};
        log = [...log, curLog];

        // 디바이스로 송신
        const data = makeDeviceData(device.workend, distanceAlarm? distanceValue:0, (device.state & 0x0F), device.onoffPeriod, device.duration, commPeriodValue);
        //console.log(device.devEui+" : "+data);

        // 큐 삭제
        axios(commDeleteQueue(device.devEui))
        .then(function (response) {
          // 큐 삭제 확인후 데이터 송신
          const sendData = commSendToDevice(device.devEui, data);
          axios(sendData)
          .then(function (response) {
            commSaveDeviceSendDataLog(device.devEui, sendData, "설정");

            // 성공
            log.find(item => item.devEui === device.devEui).result = true;
            if (log.filter(item => item.result === '').length === 0){
              if (log.filter(item => item.result === false).length > 0){
                dispatch(setErrorLog(log));
                dispatch(updateModalState('errorlog', true));
              }
              else {
                //dispatch(setMyAlert(true, "전송완료"));
                setAlert("전송이 완료되었습니다.");
              }
            }
            // 배열 수정
            dispatch(updateDevice(device.devEui, 'distanceAlarm', distanceAlarm));
            dispatch(updateDevice(device.devEui, 'distance', distanceValue));
            dispatch(updateDevice(device.devEui, 'commPeriod', commPeriodValue));
            dispatch(updateDevice(device.devEui, 'lastComm', getCurTime()));
            const updateDB = {devEui: device.devEui, distanceAlarm: distanceAlarm, distance: distanceValue, commPeriod: commPeriodValue, lastComm: getCurTime()};
            commUpdateDeviceDB(updateDB);
          })
          .catch(function (error) {
            log.find(item => item.devEui === device.devEui).result = false;
            log.find(item => item.devEui === device.devEui).description = error.message;
            if (log.filter(item => item.result === '').length === 0){
              dispatch(setErrorLog(log));
              dispatch(updateModalState('errorlog', true));
            }
          });
        })
        .catch(function (error) {
          log.find(item => item.devEui === device.devEui).result = false;
          log.find(item => item.devEui === device.devEui).description = error.message;
          if (log.filter(item => item.result === '').length === 0){
            dispatch(setErrorLog(log));
            dispatch(updateModalState('errorlog', true));
          }
        });
        if (curLog.result === false){
            log = [...log, curLog];
        }
      });
    }
    else{
      var curLog = {no:curDevice.no, devEui:curDevice.devEui, shipNo:curDevice.shipNo, result:'', description:''};
      log = [...log, curLog];
      // 디바이스로 송신
      const data = makeDeviceData(curDevice.workend, distanceAlarm? distanceValue:0, (curDevice.state & 0x0F), curDevice.onoffPeriod, curDevice.duration, commPeriodValue);
      console.log("data "+data);

      // 큐 삭제
      axios(commDeleteQueue(curDevice.devEui))
      .then(function (response) {
        // 큐 삭제 확인후 데이터 송신
        const sendData = commSendToDevice(curDevice.devEui, data);
        axios(sendData)
        .then(function (response) {
          commSaveDeviceSendDataLog(curDevice.devEui, sendData, "설정");

          // 성공
          log[0].result = true;
          //dispatch(setErrorLog(log));
          //dispatch(updateModalState('errorlog', true));
          // 배열 수정
          dispatch(updateCurDevice('distanceAlarm', distanceAlarm));
          dispatch(updateCurDevice('distance', distanceValue));
          dispatch(updateCurDevice('commPeriod', commPeriodValue));
          dispatch(updateDevice(curDevice.devEui, 'distanceAlarm', distanceAlarm));
          dispatch(updateDevice(curDevice.devEui, 'distance', distanceValue));
          dispatch(updateDevice(curDevice.devEui, 'commPeriod', commPeriodValue));
          dispatch(updateDevice(curDevice.devEui, 'lastComm', getCurTime()));
          const updateDB = {devEui: curDevice.devEui, distanceAlarm: distanceAlarm, distance: distanceValue, commPeriod: commPeriodValue, lastComm: getCurTime()};
          commUpdateDeviceDB(updateDB);

          //dispatch(setMyAlert(true, "전송완료"));
          setAlert("전송이 완료되었습니다.");
        })
        .catch(function (error) {
          log[0].result = false;
          log[0].description = error.message;
          dispatch(setErrorLog(log));
          dispatch(updateModalState('errorlog', true));
        });
      })
      .catch(function (error) {
        log[0].result = false;
        log[0].description = error.message;
        dispatch(setErrorLog(log));
        dispatch(updateModalState('errorlog', true));
      });
    }
    //close();
  };
  function changeDistanceAlarm() {
    setDistanceAlarm(!distanceAlarm);
  };

  useEffect(() => {
    //console.log("request "+request);
    if (request){
      setRequest(request-1);
    }
    else{
      setClicked(true);
      //console.log("clicked -> true");
    }
  }, [request]);

  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <div className='modalFrame'>
          <div className='left'>
            <div className='left_up'>
              <div className='left_up1'>SETTING</div>
              <div className='left_up2'>설정</div>
            </div>
            <div className='left_down'>
              {curDevInfo.isShip ? (
                <div>
                  <div><BiRectangle></BiRectangle>{' '+curShip.shipName}</div>
                  <div className='left_down_1'>{' ('+curShip.shipNo+')'}</div>
                  </div>
                ) : (
                  <div><BiRectangle></BiRectangle>
                  {' '+curDevice.devEui.toUpperCase()}</div>
                )}
            </div>
          </div>
          <div className='right'>
            <div className='right_up'>
              <div className='right_up1'>
                <BiX className='button1' onClick={close}></BiX>
              </div>
              <div className='right_title'>기본설정</div>
              <div className='settingLine' />
              <div className='right_settingItem'>
                <div>보고간격</div>
                <div onClick={(e) => {
                  //console.log("clicked");
                  e.preventDefault();
                  setRequest(1);
                  setPulldown(1);
                  setPoints({x: e.pageX, y: e.pageY});
                }}>
                  <div className='cursorPoint'>
                    {commPeriod+' '}
                    <BiChevronDown></BiChevronDown>
                  </div>
                </div>
              </div>
              <div className='blank_30'></div>
              <div className='right_title'>위치이탈경고설정</div>
              <div className='settingLine' />
              <div className='right_settingItem'>
                <div>사용</div>
                {distanceAlarm ? (
                    <div className='switch_on1' onClick={changeDistanceAlarm}>
                      사용
                      <div className='switch_on2'></div>
                    </div>
                  ) : (
                    <div className='switch_off1' onClick={changeDistanceAlarm}>
                      <div className='switch_on2'></div>
                      사용안함
                    </div> 
                  )}
                </div>
              <div className='right_settingItem'>
                <div>이탈거리</div>
                <div onClick={(e) => {
                  //console.log("clicked");
                  e.preventDefault();
                  setRequest(1);
                  setPulldown(2);
                  setPoints({x: e.pageX, y: e.pageY});
                }}>
                  <div className='cursorPoint'>
                    {distance+' '}
                    <BiChevronDown></BiChevronDown>
                  </div>
                </div>
              </div>
            </div>
            <div className='right_down'>
              <div className='apply' onClick={apply}>저장 및 전송</div>
              <div className='apply' onClick={close}>취소</div>
            </div>
          </div>
        </div>
      ) : null}
      {clicked && (
        <ContextMenu1 top={points.y-80} left={points.x-10}>
          {pulldown === 1 ?(
            <div>
              <div className='modalContextItems'>
                <div className='modalContextItem' onClick={() => setCommPeriod('10분')}>10분</div>
                <div className='modalContextItem' onClick={() => setCommPeriod('30분')}>30분</div>
                <div className='modalContextItem' onClick={() => setCommPeriod('40분')}>40분</div>
                <div className='modalContextItem' onClick={() => setCommPeriod('50분')}>50분</div>
                <div className='modalContextItem' onClick={() => setCommPeriod('1시간')}>1시간</div>
                <div className='modalContextItem' onClick={() => setCommPeriod('2시간')}>2시간</div>
                <div className='modalContextItem' onClick={() => setCommPeriod('3시간')}>3시간</div>
                <div className='modalContextItem' onClick={() => setCommPeriod('4시간')}>4시간</div>
              </div>
            </div>
          ) : (
            <div>
              <div className='modalContextItems'>
                <div className='modalContextItem' onClick={() => setDistance('30m')}>30m</div>
                <div className='modalContextItem' onClick={() => setDistance('40m')}>40m</div>
                <div className='modalContextItem' onClick={() => setDistance('50m')}>50m</div>
                <div className='modalContextItem' onClick={() => setDistance('60m')}>60m</div>
                <div className='modalContextItem' onClick={() => setDistance('70m')}>70m</div>
                <div className='modalContextItem' onClick={() => setDistance('80m')}>80m</div>
                <div className='modalContextItem' onClick={() => setDistance('90m')}>90m</div>
                <div className='modalContextItem' onClick={() => setDistance('100m')}>100m</div>
                <div className='modalContextItem' onClick={() => setDistance('110m')}>110m</div>
                <div className='modalContextItem' onClick={() => setDistance('120m')}>120m</div>
                <div className='modalContextItem' onClick={() => setDistance('130m')}>130m</div>
                <div className='modalContextItem' onClick={() => setDistance('140m')}>140m</div>
                <div className='modalContextItem' onClick={() => setDistance('150m')}>150m</div>
                <div className='modalContextItem' onClick={() => setDistance('160m')}>160m</div>
                <div className='modalContextItem' onClick={() => setDistance('170m')}>170m</div>
                <div className='modalContextItem' onClick={() => setDistance('180m')}>180m</div>
                <div className='modalContextItem' onClick={() => setDistance('190m')}>190m</div>
                <div className='modalContextItem' onClick={() => setDistance('200m')}>200m</div>
              </div>
            </div>
          )}          
        </ContextMenu1>
      )}
      {alertOpen && (
        <MyAlert content={alertContent} ok={()=>{setAlertOpen(false); close();}}/>
      )}
    </div>
  );
};

export default ModalSetting