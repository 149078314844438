
/*global kakao*/ 
import React, { useEffect, useState, useRef } from 'react'
import './home.css'
import buoyOn from '../../icons/buoyOn.svg'
import buoyOff from '../../icons/buoyOff.svg'
import InfoWindow from '../../components/modals/InfoWindow'
import { useSelector, useDispatch } from "react-redux";
import { setCurDevInfo } from '../../redux/modules/curDevInfo';
import { setCurShip } from "../../redux/modules/curShip";
import { setCurDevice, updateCurDevice } from "../../redux/modules/curDevice";
import { setCurDevices } from '../../redux/modules/curDevices';
import { updateModalState } from '../../redux/modules/modalState'
import axios from 'axios'
import { isNew } from '../../components/comm/Comm';
import { updateDevice } from "../../redux/modules/devices";

var map = null;
var curMarker = null;



const Home = (props) => {
    const [level, setLevel] = useState(2);
    const [center, setCenter] = useState({lat: props.center.lat, lng: props.center.lng});
    const [prevDevInfo, setPrevDevInfo] = useState();
    const [prevShip, setPrevShip] = useState();
    //const [curShip, setCurShip] = useState();
    //const [curDevice, setCurDevice] = useState();
    //const [infoOpen, setInfoOpen] = useState(props.infoOpen);
    const curDevInfo = useSelector((state) => state.curDevInfo);
    const curShip = useSelector((state) => state.curShip);
    const curDevice = useSelector((state) => state.curDevice);
    const curDevices = useSelector((state) => state.setCurDevices);
    const ships = useSelector((state) => state.ships);
    const gateways = useSelector((state) => state.gateways);
    const devices = useSelector((state) => state.devices);
    const modalState = useSelector((state) => state.modalState);
    const loadState = useSelector((state) => state.loadState);
    const shipsToAdd = useSelector((state) => state.shipsToAdd);
    const devicesToAdd = useSelector((state) => state.devicesToAdd);
    const etc = useSelector((state) => state.etc);
    const dispatch = useDispatch();
    const [flag, setFlag] = useState(false);

    const imageSize = new kakao.maps.Size(26, 26);
    const imageOption = { offset: new kakao.maps.Point(13, 13) };
    const markerImageOn = new kakao.maps.MarkerImage(buoyOn, imageSize, imageOption);
    const markerImageOff = new kakao.maps.MarkerImage(buoyOff, imageSize, imageOption);

    const [mapWidth, setMapWidth] = useState('calc(100vw - 380px)');
    const { visibilityLeftMenu } = useSelector((state) => state.leftMenuVisibility);
    useEffect(()=>{
        if(visibilityLeftMenu === true) 
            setMapWidth('calc(100vw - 380px)');
        else
            setMapWidth('100vw');
    }, [visibilityLeftMenu]);

    useEffect(() => {
        if(map)
            map.relayout();
    });
    
    function handleCurDevChange(device) {
        const devInfo = {isShip: false, shipNo: device.shipNo, devEui: device.devEui, fromSide: false};
        dispatch(setCurDevInfo(devInfo));
        dispatch(setCurDevice(device));

        const ship = ships.find(ship => ship.shipNo === device.shipNo);
        dispatch(setCurShip(ship));

        //setInfoOpen(true);
        dispatch(updateModalState('info', true));

        // 선택된 device 정보를 DB에서 업데이트
        axios({
            method: "GET",
            url: '/api/devicedb/dev?&devEui=' + device.devEui,
        })
        .then(function(response) {
            console.log(response);
            const memoryLastComm = device.lastComm;
            const dbLastComm = response.data[0].lastComm;
            if (isNew(dbLastComm, memoryLastComm)){
                UpdateDeviceFromDB(device.devEui, response.data[0]);
            }
        }).catch(error=>{
            console.log(error);
        });
    }
    
    function UpdateDeviceFromDB(devEui, data)
    {
        dispatch(updateDevice(devEui, 'battery', data.battery));
        dispatch(updateDevice(devEui, 'lat', data.lat));
        dispatch(updateDevice(devEui, 'lng', data.lng));
        dispatch(updateDevice(devEui, 'state', data.state));
        dispatch(updateDevice(devEui, 'power', data.power));
        dispatch(updateDevice(devEui, 'workend', data.workend));
        dispatch(updateDevice(devEui, 'distanceAlarm', data.distanceAlarm));
        dispatch(updateDevice(devEui, 'distance', data.distance));
        dispatch(updateDevice(devEui, 'LedOnTime', data.LedOnTime));
        dispatch(updateDevice(devEui, 'onoffPeriod', data.onoffPeriod));
        dispatch(updateDevice(devEui, 'duration', data.duration));
        dispatch(updateDevice(devEui, 'commPeriod', data.commPeriod));
        dispatch(updateDevice(devEui, 'lastComm', data.lastComm));
        
        dispatch(updateCurDevice('battery', data.battery));
        dispatch(updateCurDevice('lat', data.lat));
        dispatch(updateCurDevice('lng', data.lng));
        dispatch(updateCurDevice('state', data.state));
        dispatch(updateCurDevice('power', data.power));
        dispatch(updateCurDevice('workend', data.workend));
        dispatch(updateCurDevice('distanceAlarm', data.distanceAlarm));
        dispatch(updateCurDevice('distance', data.distance));
        dispatch(updateCurDevice('LedOnTime', data.LedOnTime));
        dispatch(updateCurDevice('onoffPeriod', data.onoffPeriod));
        dispatch(updateCurDevice('duration', data.duration));
        dispatch(updateCurDevice('commPeriod', data.commPeriod));
        dispatch(updateCurDevice('lastComm', data.lastComm));
    }
    function handleCurShipChange(ship) {
        const devInfo = {isShip: true, shipNo: ship.shipNo, devEui: null, fromSide: false};
        dispatch(setCurDevInfo(devInfo));
        dispatch(setCurShip(ship));
        dispatch(setCurDevices(devices, ship.shipNo));
        //setInfoOpen(true);
        dispatch(updateModalState('info', true));
            
        devices.filter(device => device.shipNo === ship.shipNo).map(device => {
            axios({
                method: "GET",
                url: '/api/devicedb/dev?&devEui=' + device.devEui,
            })
            .then(function(response) {
                console.log(response);
                const memoryLastComm = device.lastComm;
                const dbLastComm = response.data[0].lastComm;
                if (isNew(dbLastComm, memoryLastComm)){
                    UpdateDeviceFromDB(device.devEui, response.data[0]);
                }
            }).catch(error=>{
                console.log(error);
            });
        })
    }
    function handleDevRightClick(device) {
        handleCurDevChange(device)
        props.onRightClicked(true);
    }
    function handleShipRightClick(device) {
        handleCurShipChange(device)
        props.onRightClicked(true);
    }
    function handleInfoCClose() {
        //props.infoClosed();
        dispatch(updateModalState('info', false));
    }

    useEffect(()=>{
        if (loadState.ships && loadState.devices){
            if (curDevInfo.shipNo !== null && curDevInfo !== prevDevInfo){
                if (curDevInfo.shipNo !== undefined){
                    
                    // 현재 어선의 장비들을 보여줌
                    const devicesNew = devices.filter(device => device.shipNo === curDevInfo.shipNo);
                    devicesNew.forEach((device) => {
                        if (device.power){
                            if (device.marker){
                                device.marker.setMap(map);
                                device.marker.setImage((device.state & 0x0F)? markerImageOn : markerImageOff);
                                device.marker.setPosition(new kakao.maps.LatLng(device.lat, device.lng));
                            }
                            if (device.overlay){
                                device.overlay.setMap(map);
                                device.overlay.setPosition(new kakao.maps.LatLng(device.lat, device.lng));
                            }
                        }
                    });

                    // 현재 어선을 보여줌
                    const newShip = ships.find(ship => ship.shipNo === curDevInfo.shipNo);
                    //console.log(newShip);
                    if (newShip.marker)
                        newShip.marker.setMap(map);
                    if (newShip.overlay)
                        newShip.overlay.setMap(map);

                    setPrevShip(newShip);
                }
                if (prevDevInfo !== undefined && curDevInfo.shipNo !== prevDevInfo.shipNo)
                {
                    // 이전 어선의 장비들을 지움
                    const devicesNew = devices.filter(device => device.shipNo === prevDevInfo.shipNo);
                    devicesNew.forEach((device) => {
                        if (device.marker)
                            device.marker.setMap(null);
                        if (device.overlay)
                            device.overlay.setMap(null);
                    });
                    
                    // 이전 어선을 지움
                    if (prevShip.marker)
                        prevShip.marker.setMap(null);
                    if (prevShip.overlay)
                        prevShip.overlay.setMap(null);
                }

                setPrevDevInfo(curDevInfo);

                // 현재 어선 또는 장비에 마커를 위치시킴
                if (curDevInfo.isShip){
                    curMarker.setPosition(new kakao.maps.LatLng(curShip.lat, curShip.lng));

                    const ship = ships.find(ship => ship.shipNo === curShip.shipNo);
                    if (ship.marker)
                        ship.marker.setPosition(new kakao.maps.LatLng(ship.lat, ship.lng));
                    if (ship.overlay)
                        ship.overlay.setPosition(new kakao.maps.LatLng(ship.lat, ship.lng));
                }
                else{
                    curMarker.setPosition(new kakao.maps.LatLng(curDevice.lat, curDevice.lng));
                }
            }
        }
    }, [curDevInfo]);

    useEffect(()=>{
        if (curDevInfo.shipNo !== null && curDevInfo === prevDevInfo){
            const devicesToUpdate = devices.filter(device => device.shipNo === curDevInfo.shipNo);
            devicesToUpdate.forEach((device) => {
                if (device.power){
                    if (device.marker){
                        device.marker.setMap(map);
                        device.marker.setImage((device.state & 0x0F)? markerImageOn : markerImageOff);
                        device.marker.setPosition(new kakao.maps.LatLng(device.lat, device.lng));
                    }
                    if (device.overlay){
                        device.overlay.setMap(map);
                        device.overlay.setPosition(new kakao.maps.LatLng(device.lat, device.lng));
                    }
                    if (curDevInfo.isShip === false && device.devEui === curDevInfo.devEui){
                        curMarker.setPosition(new kakao.maps.LatLng(device.lat, device.lng));
                    }
                }
            });
        }
    }, [devices])

    useEffect(()=>{
        if (map && loadState.ships && loadState.devices){
            //if (props.center.lat !== center.lat || props.center.lng !== center.lng){
                setCenter({lat: props.center.lat, lng: props.center.lng});
                var moveLatLon = new kakao.maps.LatLng(props.center.lat,props.center.lng);
                map.panTo(moveLatLon);
            //}
        }
    }, [props.center]);

    const cnt = useRef(0);
    useEffect(() => {
        const loop = setInterval(()=>{
            cnt.current += 1;
            setFlag(true);
        }, 5000);
    }, []);

    useEffect(() => {
        if (flag){
            setFlag(false);

            // 5초에 한번씩 현재 어선의 위치를 업데이트함
            if (curShip.marker !== null){
                const ship = ships.find(ship => ship.shipNo === curShip.shipNo);
                ship.marker.setPosition(new kakao.maps.LatLng(ship.lat, ship.lng));
                ship.overlay.setPosition(new kakao.maps.LatLng(ship.lat, ship.lng));
            }

            // 마커의 위치도 업데이트함
            if (curDevInfo.isShip){
                curMarker.setPosition(new kakao.maps.LatLng(curShip.lat, curShip.lng));
            }
            else{
                curMarker.setPosition(new kakao.maps.LatLng(curDevice.lat, curDevice.lng));
            }
        }
    }, [flag]);
    
    // 지도그리기
    useEffect(()=>{
        //console.log("loadState");
        //console.log(loadState.ships);
        //console.log(loadState.devices);
        if (loadState.ships && loadState.devices){
            console.log("지도그리기");
            var container = document.getElementById('map');
            if (ships.length === 0)
            {
                //console.log("no ship...");
                setLevel(13);
                setCenter({lat: 36.0, lng: 127.5});
            }
            var options = {
                center: new kakao.maps.LatLng(center.lat, center.lng),
                level: level
            };
            
            map = new kakao.maps.Map(container, options);

            kakao.maps.event.addListener(map, 'zoom_changed', function() {        
                setLevel(map.getLevel());
            });

            kakao.maps.event.addListener(map, 'rightclick', function(mouseEvent) {        
                props.onRightClicked(false);
            });
            
            devices.forEach((device) => {
                device.marker = new kakao.maps.Marker({
                    map: null,
                    position: new kakao.maps.LatLng(device.lat, device.lng),
                    image: (device.state & 0x0F)? markerImageOn : markerImageOff,
                })

                kakao.maps.event.addListener(device.marker, 'click', function(){
                    handleCurDevChange(device)
                })

                kakao.maps.event.addListener(device.marker, 'rightclick', function(){
                    handleDevRightClick(device)
                })
                
                device.overlay = new kakao.maps.CustomOverlay({
                    map: null,
                    position: new kakao.maps.LatLng(device.lat, device.lng),
                    content: '<div>' + device.no + '</div>',
                    xAnchor: 0.5,
                    yAnchor: -0.7
                });
            });
            
            const MARKER_SRC1 = '/images/ship.svg';
            const imageSize1 = new kakao.maps.Size(41, 41);
            const imageOption1 = { offset: new kakao.maps.Point(21, 21) };
            const markerImage1 = new kakao.maps.MarkerImage(MARKER_SRC1, imageSize1, imageOption1);
            
            if(ships)
            {
                console.log(ships);
                ships.forEach((ship) => {
                    ship.marker = new kakao.maps.Marker({
                        map: null,
                        position: new kakao.maps.LatLng(ship.lat, ship.lng),
                        image: markerImage1,
                        title: ship.shipNo,
                    })
        
                    kakao.maps.event.addListener(ship.marker, 'click', function(){
                        handleCurShipChange(ship)
                    })
        
                    kakao.maps.event.addListener(ship.marker, 'rightclick', function(){
                        handleShipRightClick(ship)
                    })
                    
                    ship.overlay = new kakao.maps.CustomOverlay({
                        map: null,
                        position: new kakao.maps.LatLng(ship.lat, ship.lng),
                        content: '<div>' + ship.shipName + '</div>',
                        xAnchor: 0.5,
                        yAnchor: -1.0
                    });
                });
            }
            
            var imageSrcCM = 'http://t1.daumcdn.net/localimg/localimages/07/2018/pc/img/marker_spot.png';
            var imageSizeCM = new kakao.maps.Size(25,35);
            var markerImageCM = new kakao.maps.MarkerImage(imageSrcCM, imageSizeCM);
            curMarker = new kakao.maps.Marker({
                map: map,
                position: new kakao.maps.LatLng(center.lat, center.lng),
                image: markerImageCM
            })
        }
    }, [loadState.devices]);

    useEffect(()=>{
        if (etc.noShip){
            console.log("no ship");
            setCenter({lat: 36.5, lng: 127.5});
            var moveLatLon = new kakao.maps.LatLng(36.0,127.5);
            map.panTo(moveLatLon);
            map.setLevel(13);
        }
    }, [etc.noShip]);

    useEffect(()=>{
        if (shipsToAdd.length > 0){
            const shipNo = shipsToAdd.pop();
            const ship = ships.find(ship => ship.shipNo === shipNo);

            const MARKER_SRC1 = '/images/ship.svg';
            const imageSize1 = new kakao.maps.Size(41, 41);
            const imageOption1 = { offset: new kakao.maps.Point(21, 21) };
            const markerImage1 = new kakao.maps.MarkerImage(MARKER_SRC1, imageSize1, imageOption1);        

            ship.marker = new kakao.maps.Marker({
                map: null,
                position: new kakao.maps.LatLng(ship.lat, ship.lng),
                image: markerImage1,
                title: ship.shipNo,
            })
        
            kakao.maps.event.addListener(ship.marker, 'click', function(){
                handleCurShipChange(ship)
            })
        
            kakao.maps.event.addListener(ship.marker, 'rightclick', function(){
                handleShipRightClick(ship)
            })
            
            ship.overlay = new kakao.maps.CustomOverlay({
                map: null,
                position: new kakao.maps.LatLng(ship.lat, ship.lng),
                content: '<div>' + ship.shipName + '</div>',
                xAnchor: 0.5,
                yAnchor: -1.0
            });
        }
    }, [shipsToAdd]);

    useEffect(()=>{
        if (devicesToAdd.length > 0){
            const devEui = devicesToAdd.pop();
            const device = devices.find(device => device.devEui === devEui);
            
            const imageSize = new kakao.maps.Size(26, 26);
            const imageOption = { offset: new kakao.maps.Point(13, 13) };
            const markerImageOn = new kakao.maps.MarkerImage(buoyOn, imageSize, imageOption);
            const markerImageOff = new kakao.maps.MarkerImage(buoyOff, imageSize, imageOption);
            
            devices.forEach((device) => {
                device.marker = new kakao.maps.Marker({
                    map: null,
                    position: new kakao.maps.LatLng(device.lat, device.lng),
                    image: (device.state & 0x0F)? markerImageOn : markerImageOff,
                })

                kakao.maps.event.addListener(device.marker, 'click', function(){
                    handleCurDevChange(device)
                })

                kakao.maps.event.addListener(device.marker, 'rightclick', function(){
                    handleDevRightClick(device)
                })
                
                device.overlay = new kakao.maps.CustomOverlay({
                    map: null,
                    position: new kakao.maps.LatLng(device.lat, device.lng),
                    content: '<div>' + device.no + '</div>',
                    xAnchor: 0.5,
                    yAnchor: -0.7
                });
            });
        }
    }, [devicesToAdd]);

    return (
        <div className="home" id="home" style={{width: mapWidth}}>   
            <div onContextMenu={(e) => {
                e.preventDefault();
                const point = {x: e.pageX, y: e.pageY};
                props.onRightClickPosition(point);
            }}>
                <div id="map" style={{ width: mapWidth, height:  'calc(100vh - 80px)' }}></div>
            </div>  
            {modalState.info && (
                <InfoWindow 
                    close={handleInfoCClose}>
                </InfoWindow>
            )}
        </div>
    )
}

export default Home;