import React from 'react';
import styled from 'styled-components';
import oc from 'open-color';
import { shadow } from '../../../lib/styleUtil';

const BorderedButton = styled.div`
    font-weight: 600;
    color: ${oc.gray[0]};
    //border: 1px solid ${oc.cyan[6]};
    padding: 0.5rem;
    padding-bottom: 0.4rem;
    cursor: pointer;
    border-radius: 2px;
    text-decoration: none;
    transition: .2s all;
    //background: ${oc.gray[1]};

    &:hover {
        //background: ${oc.gray[0]};
        color: white;
        background: hsl(200, 90%, 35%);
        //${shadow(1)}
    }

    &:active {
        /* 마우스 클릭시 아래로 미세하게 움직임 */
        transform: translateY(3px);
    }


`;

const TopButton = ({to, onClick}) => (
    <BorderedButton onClick={onClick}>
        {to}
    </BorderedButton>
);

export default TopButton;