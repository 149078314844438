import React, { Component } from 'react';
import { AuthContent, InputWithLabel, AuthButton, RightAlignedLink, AuthError, CalendarWithLabel, AddressWithLabel } from '../../components/Auth';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as authActions from '../../redux/modules/auth';
import {isEmail, isLength, isAlphanumeric} from 'validator';
import debounce from 'lodash/debounce';
import * as userActions from '../../redux/modules/user';
import storage from '../../lib/storage';
import withRouter from '../Base/withRouter';
import { isEmpty } from 'lodash';

class Register extends Component {   
    constructor(props) {
        super(props);
        this.state = {
          isDaumPost: false,
          getAddress: ""
        };
      }

    setError = (message) => {
        const { AuthActions } = this.props;
        AuthActions.setError({
            form: 'register',
            message
        });
    }

    validate = {
        userid: (value) => {
            if(!isAlphanumeric(value) || !isLength(value, { min:4, max: 15 })) {
                this.setError('아이디는 4~15 글자의 알파벳 혹은 숫자로 이뤄져야 합니다.');
                return false;
            }
            if(isEmpty(value)) {
                this.setError('아이디를 입력해 주세요.');
                return false;
            }
            this.setError(null);
            return true;
        },
        email: (value) => {
            if(!isEmail(value)) {
                this.setError('잘못된 이메일 형식 입니다.');
                return false;
            }
            return true;
        },
        username: (value) => {
            /*if(!isAlphanumeric(value) || !isLength(value, { min:4, max: 15 })) {
                this.setError('아이디는 4~15 글자의 알파벳 혹은 숫자로 이뤄져야 합니다.');
                return false;
            }*/
            if(isEmpty(value)) {
                this.setError('이름을 입력해 주세요.');
                return false;
            }
            this.setError(null);
            return true;
        },
        password: (value) => {
            if(!isLength(value, { min: 6 })) {
                this.setError('비밀번호를 6자 이상 입력하세요.');
                return false;
            }
            this.setError(null); // 이메일과 아이디는 에러 null 처리를 중복확인 부분에서 하게 됩니다
            return true;
        },
        passwordConfirm: (value) => {
            if(this.props.form.get('password') !== value) {
                this.setError('비밀번호확인이 일치하지 않습니다.');
                return false;
            }
            this.setError(null); 
            return true;
        },
        userbirth: (value) => {
            return true;
        },
        userphone: (value) => {
            const { AuthActions } = this.props;

            value = value.replace(/[^0-9]/g, '')
                .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
            
            AuthActions.changeInput({
                name: 'userphone',
                value: value,
                form: 'register'
            });

        /*    var preValue = this.props.form.get('userphone');
            const regex = /^[0-9\b -]{0,13}$/;
            if (regex.test(value)) {
                if (value.length === 10) {
                    value = value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
                }
                else if (value.length === 13) {
                    value = value.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
                }

                AuthActions.changeInput({
                    name: 'userphone',
                    value: value,
                    form: 'register'
                });
            }
            else
            {
                AuthActions.changeInput({
                    name: 'userphone',
                    value: preValue,
                    form: 'register'
                });
            }*/
            return true;
        },
        useraddress: (value) => {
            return true;
        },
        useraddress2: (value) => {
            return true;
        }
    }

    checkEmailExists = debounce(async (email) => {
        const { AuthActions } = this.props;
        try {
            await AuthActions.checkEmailExists(email);
            if(this.props.exists.get('email')) {
                this.setError('이미 존재하는 이메일입니다.');
            } else {
                this.setError(null);
            }
        } catch (e) {
            console.log(e);
        }
    }, 100)
    
    checkUseridExists = debounce(async (userid) => {
        const { AuthActions } = this.props;
        try {
            await AuthActions.checkUseridExists(userid);
            if(this.props.exists.get('userid')) {                
                this.setError('이미 존재하는 아이디입니다.');
            } else {
                this.setError(null);
            }
        } catch (e) {
            console.log(e);
        }
    }, 100)

    handleLocalRegister = async () => {
        const { form, AuthActions, UserActions, error, history, navigate } = this.props;
        const { userid, email, username, password, passwordConfirm, userbirth, userphone, useraddress, useraddress2 } = form.toJS();
        const { validate } = this; 
        console.log("check");
        console.log(useraddress2);
        console.log(this.state.getAddress);
        
        const fullAddress = useraddress + "?" + useraddress2;

        if(error) return; // 현재 에러가 있는 상태라면 진행하지 않음
        if(!validate['userid'](userid)
            || !validate['email'](email) 
            || !validate['username'](username) 
            || !validate['password'](password) 
            || !validate['passwordConfirm'](passwordConfirm)) { 
            // 하나라도 실패하면 진행하지 않음
            return;
        }
        
        try {
            let birth = "";
            if(userbirth)
            {
                const b = new Date(userbirth);
                birth = b.getFullYear() + "년 " + (b.getMonth() + 1) + "월 " + b.getDate() + "일";
            }

            await AuthActions.localRegister({
                userid, email, username, password, userbirth:birth, userphone, useraddress:fullAddress
            });
            const loggedInfo = this.props.result.toJS();
            
            // 로그인 정보 저장 (로컬스토리지/스토어)
            storage.set('loggedInfo', loggedInfo);
            UserActions.setLoggedInfo(loggedInfo);
            UserActions.setValidated(true);

            //history.push('/'); // 회원가입 성공시 홈페이지로 이동
            navigate('/control');
        } catch(e) {
            // 에러 처리하기
            if(e.response.status === 409) {
                const { key } = e.response.data;
                const message = key === 'email' ? '이미 존재하는 이메일입니다.' : '이미 존재하는 아이디입니다.';
                return this.setError(message);
            }
            this.setError('알 수 없는 에러가 발생했습니다.')
        }
    }

    componentWillUnmount() {
        const { AuthActions } = this.props;
        AuthActions.initializeForm('register')
    }

    calendarChange = (e) => {
        const { AuthActions } = this.props;
        console.log(e);
        AuthActions.changeInput({
            name: 'userbirth',
            value: e._d,
            form: 'register'
        });
    }

    addressChange = (e) => {
        const { AuthActions } = this.props;

        AuthActions.changeInput({
            name: 'useraddress',
            value: e,
            form: 'register'
        });
    }

    handleChange = (e) => {
        const { AuthActions } = this.props;
        const { name, value } = e.target;

        AuthActions.changeInput({
            name,
            value,
            form: 'register'
        });

        // 검증작업 진행
        const validation = this.validate[name](value);
        //console.log(validation);
        if(name.indexOf('password') > -1 || !validation) return; // 비밀번호 검증이거나, 검증 실패하면 여기서 마침

        // 이메일, 아이디 중복 확인
        const check = name === 'email' ? this.checkEmailExists : this.checkUseridExists; // name 에 따라 이메일체크할지 아이디 체크 할지 결정
        //console.log(check);
        check(value);
        /*if(name === 'email')
        {
            console.log(this.checkEmailExists);
            this.checkEmailExists(value);
        }*/
    }

    render() {
        const { userid, email, username, password, passwordConfirm, userbirth, userphone, useraddress, useraddress2 } = this.props.form.toJS();
        const { handleChange, handleLocalRegister, calendarChange, addressChange } = this;
        const { error } = this.props;

        return (
            <AuthContent title="회원가입">
                <InputWithLabel 
                    label="아이디 *"
                    name="userid"
                    placeholder="아이디" 
                    value={userid} 
                    onChange={handleChange}
                /> 
                <InputWithLabel 
                    label="이메일 *"
                    name="email"
                    placeholder="이메일" 
                    value={email} 
                    onChange={handleChange}
                />                
                <InputWithLabel 
                    label="성명 *" 
                    name="username" 
                    placeholder="성명" 
                    value={username} 
                    onChange={handleChange}
                />
                <CalendarWithLabel 
                    label="생년월일"
                    name="userbirth"
                    placeholder="생년월일" 
                    value={userbirth} 
                    onChange={calendarChange}
                />
                <InputWithLabel 
                    label="전화번호"
                    name="userphone"
                    placeholder="-없이 숫자만 입력하세요" 
                    value={userphone} 
                    onChange={handleChange}
                />
                <AddressWithLabel 
                    label="주소"
                    name="useraddress"
                    name2="useraddress2"
                    placeholder="주소" 
                    value={useraddress}
                    value2={useraddress2}
                    onChange={addressChange}
                    onChange2={handleChange}
                    vr={this}
                />
                <InputWithLabel 
                    label="비밀번호 *" 
                    name="password" 
                    placeholder="6자리이상"
                    type="password" 
                    value={password} 
                    onChange={handleChange}
                />
                <InputWithLabel 
                    label="비밀번호 확인 *" 
                    name="passwordConfirm" 
                    placeholder="6자리이상" 
                    type="password" 
                    value={passwordConfirm}
                    onChange={handleChange}
                />
                {
                    error && <AuthError>{error}</AuthError>
                }
                <AuthButton onClick={ handleLocalRegister }>회원가입</AuthButton>
                <RightAlignedLink to="/login">이전화면</RightAlignedLink>
            </AuthContent>
        );
    }
}

export default connect(
    (state) => ({
        form: state.auth.getIn(['register', 'form']),
        error: state.auth.getIn(['register', 'error']),
        exists: state.auth.getIn(['register', 'exists']),
        result: state.auth.get('result')
    }),
    (dispatch) => ({
        AuthActions: bindActionCreators(authActions, dispatch),
        UserActions: bindActionCreators(userActions, dispatch)
    })
)(withRouter(Register));