const SET = "curShip/SET";
const UPDATE = "curShip/UPDATE"

const initialState = {
    id: "",
    shipNo: "",
    shipName: "",
    ownerID: "",
    captainID: "",
    gatewayID: "",
    gatewayInstallDay: "",
    port: "",
    lat: "",
    lng: "",
    state: "",
    lastComm: "",
    marker: null,
    overlay: null,
    select: false,
    license: {
      file1: null,
      file2: null
    }
};

export const setCurShip = (ship) => ({
  type: SET,
  ship: ship,
});

export const updateCurShip = (item, value) => ({
  type: UPDATE,
  item: item,
  value: value,
});

export default function curShip(state = initialState, action) {
  switch (action.type) {
    case SET:
      return {...state, 
        id: action.ship.id,
        shipNo: action.ship.shipNo,
        shipName: action.ship.shipName, 
        ownerID: action.ship.ownerID,
        captainID: action.ship.captainID,
        gatewayID: action.ship.gatewayID,
        gatewayInstallDay: action.ship.gatewayInstallDay,
        port: action.ship.port,
        lat: action.ship.lat,
        lng: action.ship.lng,
        state: action.ship.state,
        lastComm: action.ship.lastComm,
        marker: action.ship.marker,
        overlay: action.ship.overlay,
        select: action.ship.select,
        licensefile1: action.ship.licensefile1,
        licensefile2: action.ship.licensefile2};
    case UPDATE:
      return {...state, [action.item]: action.value};
    default:
      return state;
  }
}