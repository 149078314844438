const ADD = "curDevices/ADD";
const SET = "curDevices/SET";
const DELETE = "curDevices/DELETE";

const initialState = [];

export const addCurDevices = (device) => ({
  type: ADD,
  device: device,
});

export const setCurDevices = (devices, shipNo) => ({
  type: SET,
  devices: devices,
  shipNo: shipNo,
});

export const deleteCurDevices = (devEui) => ({
  type: DELETE,
  deleteID: devEui,
});

export default function curDevices(state = initialState, action) {
  //console.log(action);
  switch (action.type) {
    case ADD:
      return [...state, action.device];
    case SET:
      return action.devices.filter(device => device.shipNo === action.shipNo);
    case DELETE:
      return state.filter(device => device.devEui !== action.deleteID);
    default:
      return state;
  }
}