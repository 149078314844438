const SET = "users/SET";
const ADD = "users/ADD";
const UPDATE = "users/UPDATE";
const DELETE = "users/DELETE";

/*const initialState = [
    {isAdmin: true, name: '관리자', birthday: "740101", email: 'admin@oceangrid.kr', phone: '010-123-4567', address: "대구시 동구 동내로 70"},
    {isAdmin: false, name: '이순신', birthday: "740101", email: 'test1@oceangrid.kr', phone: '010-123-4567', address: "대구시 동구 동내로 70"},
    {isAdmin: false, name: '홍길동', birthday: "740101", email: 'test2@oceangrid.kr', phone: '010-123-4567', address: "대구시 동구 동내로 70"},
    {isAdmin: false, name: '강감찬', birthday: "740101", email: 'test3@oceangrid.kr', phone: '010-123-4567', address: "대구시 동구 동내로 70"},
    {isAdmin: false, name: '아무개', birthday: "740101", email: 'test4@oceangrid.kr', phone: '010-123-4567', address: "대구시 동구 동내로 70"},
];*/
const initialState = null;

export const setUsers = (users) => ({
  type: SET,
  users: users,
});

export const addUser = (user) => ({
  type: ADD,
  user: user,
});

export const updateUser = (userid, item, value) => ({
  type: UPDATE,
  userid: userid,
  item: item,
  value: value,
});

export const deleteUser = (userid) => ({
  type: DELETE,
  userid: userid,
});

export default function users(state = initialState, action) {
  //console.log(action);
  switch (action.type) {
    case SET:
      return action.users;
    case ADD:
      return [...state, action.user];
    case UPDATE:
      return state.map((user)=>{
        if (user.userid !== action.userid){
          return user
        }
        return {...user, [action.item]: action.value}
      });
    case DELETE:
      return state.filter(user => user.userid !== action.userid);
    default:
      return state;
  }
}