import {React, useState, useEffect} from 'react';
import './modalUpload.css';
import { BiX } from 'react-icons/bi';

const ModalUpload = (props) => {
  const { open, close } = props;
  const [list, setList] = useState([]);

  useEffect(() => {
    if (props.open){
        setList(props.uploadReports);
        console.log(props.uploadReports);
    }
  }, [props.open]);

  function cancleClicked(){
    close();
  };

  return (
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <div className='modalUpload'>
        <div className='modalUpload_title'>
            {props.title}
            <BiX className='modalUpload_button1' onClick={close}></BiX>
        </div>
        <div className='modalUpload_content'>
          <div className='modalUpload_right'>
            <div className='modalUpload_right_flex'>
              <div className='modalUpload_right2'>
                
                <div className='modalUpload_blank_5' />
                  
                <div className='modalUpload_result_2'>
                  <div className='modalUpload_listitemTitle'>
                    <div className='modalUpload_listitemTitleSub2'>{props.isGateway? "게이트웨이 ID":"어망 ID"}</div>
                    <div className='modalUpload_listitemTitleSub3'>메모</div>
                    <div className='modalUpload_listitemTitleSub4'>등록결과</div>
                  </div>
                  <div className='modalUpload_listitems'>
                    {list.map((report)=>(
                      <div className='modalUpload_listitem' key={report.id}>
                        <div className='modalUpload_listitemSub2'>{report.id.toUpperCase()}</div>
                        <div className='modalUpload_listitemSub3'>{report.description}</div>
                        <div className='modalUpload_listitemSub4'>{(report.result === "등록 완료")? report.result:
                            <div className='red'>{report.result}</div>}</div>
                      </div>                  
                    ))}
                  </div>
                </div>              
              </div>    
            </div>
          
            <div className='modalUpload_blank_10' />
            <div className='modalUpload_storeCancel'>
              <div className='modalUpload_storeButton' onClick={cancleClicked}>확인</div>
            </div>

          </div>
        </div>
      </div>
      ) : null}
    </div>
  );
};

export default ModalUpload