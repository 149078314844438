const SET = "gateways/SET";
const ADD = "gateways/ADD";
const DELETE = "gateways/DELETE";
const UPDATE = "gateways/UPDATE";

//const initialState = [
//    {id: 1, gatewayID: 'ac1f09fffe091e2a', gatewayName: '테스트1', description: '테스트용입니다.', shipNo: '090715-6482203', lat:35.041232, lng:129.124318, lastComm: '01-14 12:56', select: false},
//    {id: 2, gatewayID: 'ac1f09fffe091e2b', gatewayName: '테스트2', description: '테스트용입니다.', shipNo: '090715-6482204', lat:35.050623, lng:129.112303, lastComm: '01-14 12:56', select: false},
//    {id: 3, gatewayID: 'ac1f09fffe091e2c', gatewayName: '테스트3', description: '테스트용입니다.', shipNo: '090715-6482205', lat:35.045211, lng:129.115474, lastComm: '01-14 12:56', select: false},
//];
const initialState = null;

export const setGateways = (gateway) => ({
  type: SET,
  gateway: gateway,
});

export const addGateway = (gateway) => ({
  type: ADD,
  gateway: gateway,
});

export const deleteGateway = (gatewayID) => ({
  type: DELETE,
  deleteID: gatewayID,
});

export const updateGateway = (gatewayID, item, value) => ({
  type: UPDATE,
  gatewayID: gatewayID,
  item: item,
  value: value,
});

export default function gateways(state = initialState, action) {
  //console.log(action.gateway);
  //console.log(state);
  switch (action.type) {
    case SET:
      return action.gateway;
    case ADD:
      return [action.gateway, ...state];
    case DELETE:
      return state.filter(gateway => gateway.gatewayID !== action.deleteID);
    case UPDATE:
      return state.map((gateway)=>{
        if (gateway.gatewayID !== action.gatewayID){
          return gateway
        }
        return {...gateway, [action.item]: action.value}
      });
    default:
      return state;
  }
}