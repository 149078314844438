import {React, useState, useEffect} from 'react';
import './modalLogout.css';
import { BiX } from 'react-icons/bi';
import UseContextMenu from '../popupMenu/UseContextMenu';
import { useSelector, useDispatch } from "react-redux";
import { logout as lo } from '../../redux/modules/user';
import storage from '../../lib/storage';

const ModalLogout = (props) => {
  const { open, close } = props;
  const dispatch = useDispatch();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const setAlert = (content) => {
    setAlertContent(content);
    setAlertOpen(true);
  }

  async function logout (){
    // 로그아웃
    try {
      await dispatch(lo);
    } catch (e) {
        console.log(e);
    }

    storage.remove('loggedInfo');
    window.location.href = '/';
      
    close();
  }

  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <div className='modalLogout_modalFrame'>
          <div className='modalLogout_left'>
            <div className='modalLogout_left_up'>
              <div className='modalLogout_left_up1'>LOGOUT</div>
              <div className='modalLogout_left_up2'>로그아웃</div>
            </div>
            
          </div>
          <div className='modalLogout_right'>
            <div className='modalLogout_right_up'>
              <div className='modalLogout_right_up1'>
                <BiX className='modalLogout_button1' onClick={close}></BiX>
              </div>
              <div className='modalLogout_blank_20'></div>
              <div className='modalLogout_right_large'>
                <div className='modalLogout_right_item'>
                  정말 로그아웃하시겠습니까?</div>                          
              </div>
            </div>
            <div className='modalLogout_right_down'>
              <div className='modalLogout_apply' onClick={logout}>확인</div>
              <div className='modalLogout_apply' onClick={close}>취소</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ModalLogout