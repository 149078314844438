import { combineReducers } from 'redux';
import base from './base';
import auth from './auth';
import user from './user';
import { penderReducer } from 'redux-pender';
import ships from './ships';
import gateways from './gateways';
import devices from './devices';
import curDevInfo from './curDevInfo';
import curShip from './curShip';
import curDevice from './curDevice';
import curDevices from './curDevices';
import users from './users';
import modalState from './modalState';
import errorLog from './errorLog';
import shipsToAdd from './shipsToAdd';
import devicesToAdd from './devicesToAdd';
import myAlert from './myAlert';
import curUser from './curUser';
import loadState from './loadState';
import etc from './etc';
import { leftMenuVisibility } from './base';

export default combineReducers({
    base,
    auth,
    user,
    pender: penderReducer,
    ships,
    gateways,
    devices,
    curDevInfo,
    curShip,
    curDevice,
    leftMenuVisibility,
    curDevices,
    users,
    modalState,
    errorLog,
    shipsToAdd,
    devicesToAdd,
    myAlert,
    curUser,
    loadState,
    etc,
});