import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaClock } from 'react-icons/fa';

const ClockTextStyle = styled.div`
    color: white;
    text-align: right;
    font-size: 1.15rem;
    font-weight: 500;
    width:15%;
`;

const Clock = () => {
    const [date, setDate] = useState(()=> new Date());

    useEffect(()=>{
        const timeId = setInterval(()=>tick(),1000);
        
        return() =>{
            clearInterval(timeId);
        }
    });

    const tick = () =>{        
        setDate(new Date());
    }

    return (        
        <ClockTextStyle><FaClock style={{verticalAlign: '-10%'}}/> {date.toLocaleTimeString()}</ClockTextStyle>
    );
}

export default Clock;