import React from 'react';
import styled from 'styled-components';
import oc from 'open-color';
import { shadow, media } from '../../../lib/styleUtil';

// 상단 고정, 그림자
const Positioner = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0px;
    width: 100%;
    //${shadow(1)}
`;

// 흰 배경, 내용 중간 정렬
const TopBackground = styled.div`
    background: rgb(32,80,129);
    display: flex;
    justify-content: center;
    height: auto;
`;

// 해더의 내용
const HeaderContents = styled.div`
    width: 100%;
    height: 58px;
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-right: 0.2rem;
    padding-left: 2rem;
    ${media.wide`
        width: 992px;
    `}

    ${media.tablet`
        width: 100%;
    `}
`;

// 로고
const Logo = styled.div`
    font-size: 1.6rem;
    letter-spacing: 1px;
    color: ${oc.gray[2]};
    font-family: "Nanum Gothic";
`;

// 중간 여백
const Spacer = styled.div`
    flex-grow: 1;
`;

// 하단 그래디언트 테두리
const GradientBorder = styled.div`
    height: 3px;
    background: linear-gradient(to right, ${oc.indigo[6]}, ${oc.cyan[5]});
`;

const Header = ({children}) => {
    return (
        <Positioner>
            <TopBackground>
                <HeaderContents>
                    <Logo>스마트부이위치관리시스템</Logo>
                    <Spacer/>
                    {children}
                </HeaderContents>
            </TopBackground>
        </Positioner>
    );
};

export default Header;