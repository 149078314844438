import React from 'react';
import styled from 'styled-components';
import oc from 'open-color';
import { useDaumPostcodePopup } from 'react-daum-postcode';

const Input = styled.input`
    width: 100%;
    border: 1px solid ${oc.gray[3]};
    outline: none;
    border-radius: 0px;
    line-height: 2.0rem;
    font-size: 1.0rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-right: 0.3rem;
    margin-bottom: 0.05rem;
    ::placeholder {
        color: ${oc.gray[3]};
`;

const Postcode = (props) => {
  const open = useDaumPostcodePopup("https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js");

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    props.prp.vr.setState({getAddress: fullAddress});
  };

  const handleClick = () => {
    open({ onComplete: handleComplete, popupKey: "onlyOne" });
  };

  return (
    <Input value={props.prp.vr.state.getAddress} onClick={handleClick} onChange={props.prp.onChange(props.prp.vr.state.getAddress)}/>
  );
};

export default Postcode;