import {React, useState, useEffect} from 'react';
import './modalError.css';
import UseContextMenu from '../popupMenu/UseContextMenu';
import { BiX } from 'react-icons/bi';
import { useSelector, useDispatch } from "react-redux";

const ModalError = (props) => {
  const { open, close } = props;
  const errorLog = useSelector((state) => state.errorLog);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (props.open){
        setList(errorLog);
        console.log(errorLog);
    }
  }, [props.open]);

  function cancleClicked(){
    close();
  };

  return (
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <div className='modalError'>
        <div className='modalError_title'>
            {"통신오류"}
            <BiX className='modalError_button1' onClick={close}></BiX>
        </div>
        <div className='modalError_content'>
          <div className='modalError_right'>
            <div className='modalError_right_flex'>
              <div className='modalError_right2'>
                
                <div className='modalError_blank_5' />
                  
                <div className='modalError_result_2'>
                  <div className='modalError_listitemTitle'>
                    <div className='modalError_listitemTitleSub1'>No.</div>
                    <div className='modalError_listitemTitleSub2'>부표 ID</div>
                    <div className='modalError_listitemTitleSub3'>어선 ID</div>
                    <div className='modalError_listitemTitleSub4'>통신결과</div>
                    <div className='modalError_listitemTitleSub5'>오류내용</div>
                  </div>
                  <div className='modalError_listitems'>
                    {list.map((log)=>(
                      <div className='modalError_listitem' key={log.devEui}>
                        <div className='modalError_listitemSub1'>{log.no}</div>
                        <div className='modalError_listitemSub2'>{log.devEui}</div>
                        <div className='modalError_listitemSub3'>{log.shipNo}</div>
                        <div className='modalError_listitemSub4'>{log.result? '성공':
                            <div className='red'>실패</div>}</div>
                        <div className='modalError_listitemSub5'>{log.description}</div>
                      </div>                  
                    ))}
                  </div>
                </div>              
              </div>    
            </div>
          
            <div className='modalError_blank_10' />
            <div className='modalError_storeCancel'>
              <div className='modalError_storeButton' onClick={cancleClicked}>확인</div>
            </div>

          </div>
        </div>
      </div>
      ) : null}
    </div>
  );
};

export default ModalError