const SET = "ships/SET";
const ADD = "ships/ADD";
const UPDATE = "ships/UPDATE";
const DELETE = "ships/DELETE"

//const initialState = [
//    {id: 1, shipNo: '090715-6482203', shipName: '동해호', port:'부산항', ownerID: 'owner01', captainID: 'captain01', gatewayID: 'ac1f09fffe091e2a', lat:35.041232, lng:129.124318, state: '정상', lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
//    {id: 2, shipNo: '090715-6482204', shipName: '부산호', port:'부산항', ownerID: 'owner02', captainID: 'captain02', gatewayID: 'ac1f09fffe091e2b', lat:35.050623, lng:129.112303, state: '정상', lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
//    {id: 3, shipNo: '090715-6482205', shipName: '통영호', port:'통영항', ownerID: 'owner01', captainID: 'captain03', gatewayID: 'ac1f09fffe091e2c', lat:35.045211, lng:129.115474, state: '정상', lastComm: '01-14 12:56', marker: null, overlay: null, select: false}
//];
const initialState = null;

export const setShips = (ship) => ({
  type: SET,
  ship: ship,
});

export const addShip = (ship) => ({
  type: ADD,
  ship: ship,
});

export const updateShip = (shipNo, item, value) => ({
  type: UPDATE,
  shipNo: shipNo,
  item: item,
  value: value,
});

export const deleteShip = (shipNo) => ({
  type: DELETE,
  deleteID: shipNo,
});

export default function ships(state = initialState, action) {
  //console.log(action);
  switch (action.type) {
    case SET:
      return action.ship;
    case ADD:
      return [action.ship, ...state];
    case UPDATE:
      return state.map((ship)=>{
        if (ship.shipNo !== action.shipNo){
          return ship
        }
        return {...ship, [action.item]: action.value}
      });
    case DELETE:
      return state.filter(ship => ship.shipNo !== action.deleteID);
    default:
      return state;
  }
}