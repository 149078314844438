const SET = "errorLog/SET";

const initialState = [];

export const setErrorLog = (log) => ({
  type: SET,
  log: log,
});

export default function errorLog(state = initialState, action) {
  switch (action.type) {
    case SET:
      return action.log;
    default:
      return state;
  }
}