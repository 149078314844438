import {React, useState, useEffect} from 'react';
import './modalGatewaySelect.css';
import UseContextMenu from '../popupMenu/UseContextMenu';
import { BiX } from 'react-icons/bi';
import {BiSearch} from 'react-icons/bi'
import { useSelector, useDispatch } from "react-redux";
import { Confirm, MyAlert } from '../popupMenu/Confirm';

const ModalGatewaySelect = (props) => {
  const { open, close } = props;
  const ships = useSelector((state) => state.ships);
  const gateways = useSelector((state) => state.gateways);
  const [list, setList] = useState([]);
  const [searchContent, setSearchContent] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const setAlert = (content) => {
    setAlertContent(content);
    setAlertOpen(true);
  }

  useEffect(() => {
    if (props.open){
        setSearchContent("");
        //console.log(gateways);
        //const newList = gateways.filter(gateway => gateway.shipNo === "");
        //const newList = gateways;
        const newList = gateways.filter(gateway => 
          ships.filter(ship => ship.gatewayID === gateway.gatewayID).length === 0);
        //console.log(newList);
        setList(newList);
      
        document.documentElement.style.setProperty('--gateway_1', variables.getPropertyValue('--gateway_1_init'));
        document.documentElement.style.setProperty('--gateway_2', variables.getPropertyValue('--gateway_2_init'));
        document.documentElement.style.setProperty('--gateway_total', variables.getPropertyValue('--gateway_total_init'));
    }
  }, [props.open]);

  function okClicked(){
    const gateway = list.find(gateway => gateway.select === true);
    //const temp = ships.filter(ship => ship.gatewayID === gatewayNew.gatewayID);
    //console.log(temp);
    if (ships.filter(ship => ship.gatewayID === gateway.gatewayID).length > 0){
      setAlert("해당 게이트웨이를 사용하는 어선이 있습니다.")
      return;
    }
    if (gateway !== undefined){
        props.setGateway(gateway);
    }
    close();
  };

  function cancleClicked(){
    close();
  };
  
  function clickSelect(id){
    const newList = list.map((gateway) =>
    gateway.gatewayID === id? {...gateway, select: true} : {...gateway, select: false});
    setList(newList);
  }

  function doubleClicked(id){
    const gateway = list.find(gateway => gateway.gatewayID === id);
    if (ships.filter(ship => ship.gatewayID === gateway.gatewayID).length > 0){
      setAlert("해당 게이트웨이를 사용하는 어선이 있습니다.")
      return;
    }
    if (gateway !== undefined){
        props.setGateway(gateway);
    }
    close();
  }

  const onChange = (e) => {
    setSearchContent(e.target.value.toUpperCase());
  };

  function search(){
    //const newList = gateways.filter(gateway => gateway.shipNo === "");
    // 아래는 임시코드. 게이트웨이에 어선정보가 등록되면 위 코드로 변경해야함
    const newList = gateways.filter(gateway => 
      ships.filter(ship => ship.gatewayID === gateway.gatewayID).length === 0);
    setList(newList.filter(gateway => 
        gateway.gatewayID.toUpperCase().includes(searchContent.toUpperCase()) ));
  };
  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      search(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };
  function searchReset(){
    setSearchContent("");
    const newList = gateways.filter(gateway => gateway.shipNo === "");
    setList(newList);
  }

  const [startX, setStartX] = useState(0);
  const [width, setWidth] = useState(0);
  const [total, setTotal] = useState(0);

  let root = document.querySelector(':root');
  let variables = getComputedStyle(root); 

  return (
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <div className='modalGateway'>
        <div className='modalGateway_title'>
            {"게이트웨이 검색"}
            <BiX className='modalGateway_button1' onClick={close}></BiX>
        </div>
        <div className='modalGateway_content'>
          <div className='modalGateway_right'>
            <div className='modalGateway_right_flex'>
              <div className='modalGateway_right2'>
                <div className='modalGateway_result_1'>
                    <div style={{width:'5px'}}/>
                    <div style={{fontWeight:'bold', width:'40px', textAlign:'left'}}>검색</div>
                    <div className='modalGateway_searchBox'>
                      <input className='searchInput' onChange={onChange}
                        placeholder='게이트웨이 ID를 검색합니다'
                        onKeyDown={(e) => handleOnKeyPress(e)} value={searchContent}></input>
                      <BiSearch className='modalGateway_searchIcon' onClick={search} />
                    </div>

                </div>
                <div className='modalGateway_blank_5' />
                  
                <div className='modalGateway_result_2'>
                  <div className='modalGateway_listitemTitle'>
                    <div className='modalGateway_listitemTitleSub1' onDragOver={(e)=>{e.preventDefault()}}>게이트웨이 ID</div>
                    
                    <div className='modalGateway_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                      onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                        setWidth(variables.getPropertyValue('--gateway_1').slice(0,-2));
                        setTotal(variables.getPropertyValue('--gateway_total').slice(0,-2));}}
                      onDrag={(e)=>{document.documentElement.style.setProperty('--gateway_1', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                      onDragEnd={(e)=>{document.documentElement.style.setProperty('--gateway_1', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                        document.documentElement.style.setProperty('--gateway_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                    <div className='modalGateway_listitemTitleSub2' onDragOver={(e)=>{e.preventDefault()}}>설명</div>
                    
                    <div className='modalGateway_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                      onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                        setWidth(variables.getPropertyValue('--gateway_2').slice(0,-2));
                        setTotal(variables.getPropertyValue('--gateway_total').slice(0,-2));}}
                      onDrag={(e)=>{document.documentElement.style.setProperty('--gateway_2', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                      onDragEnd={(e)=>{document.documentElement.style.setProperty('--gateway_2', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                        document.documentElement.style.setProperty('--gateway_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                  </div>
                  <div className='modalGateway_listitems'>
                    {list.map((gateway)=>(
                      <div className={gateway.select? 'modalGateway_listitem_selected':'modalGateway_listitem'} 
                        onClick={()=>clickSelect(gateway.gatewayID)}
                        onDoubleClick={() => doubleClicked(gateway.gatewayID)} key={gateway.gatewayID}>
                        <div className='modalGateway_listitemSub1'>{gateway.gatewayID}</div>
                        <div className='modalGateway_listitemSub2'>{gateway.description}</div>
                      </div>                  
                    ))}
                  </div>
                </div>              
              </div>    
            </div>
          
            <div className='modalGateway_blank_10' />
            <div className='modalGateway_storeCancel'>
              <div className='modalGateway_storeButton' onClick={okClicked}>등록</div>
              <div className='modalGateway_Hblank_20' />
              <div className='modalGateway_storeButton' onClick={cancleClicked}>취소</div>
            </div>

          </div>
        </div>
      </div>
      ) : null}
      {alertOpen && (
        <MyAlert content={alertContent} ok={()=>setAlertOpen(false)}/>
      )}
    </div>
  );
};

export default ModalGatewaySelect