import {React, useState, useEffect} from 'react';
import './modal.css';
import { BiX } from 'react-icons/bi';
import { BiChevronDown } from 'react-icons/bi'
import { BiRectangle } from 'react-icons/bi'
import UseContextMenu from '../popupMenu/UseContextMenu';
import { ContextMenu1 } from '../popupMenu/styles';
import axios from 'axios';
import { commDeleteQueue, commSendToDevice, getCurTime, makeDeviceData} from '../comm/Comm';
import {commSaveDeviceSendDataLog} from '../comm/CommUpdateDB';
import {commUpdateDeviceDB} from '../comm/CommUpdateDB';
import { useSelector, useDispatch } from "react-redux";
import { updateDevice } from "../../redux/modules/devices";
import { updateCurDevice } from "../../redux/modules/curDevice";
import { updateModalState } from '../../redux/modules/modalState';
import { setErrorLog } from '../../redux/modules/errorLog';
import { Confirm, MyAlert } from '../popupMenu/Confirm';
import { setMyAlert } from '../../redux/modules/myAlert';

var log = [];

const ModalControl = (props) => {
  const { open, close } = props;
  const [led, setLed] = useState(false);
  const [period, setPeriod] = useState('10초');
  const [holdingTime, setHoldingTime] = useState('30분');
  const {clicked, setClicked, points, setPoints} = UseContextMenu();
  const [pulldown, setPulldown] = useState(0);
  const [request, setRequest] = useState(0);

  const curDevInfo = useSelector((state) => state.curDevInfo);
  const curShip = useSelector((state) => state.curShip);
  const curDevice = useSelector((state) => state.curDevice);
  const devices = useSelector((state) => state.devices);
  const dispatch = useDispatch();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const setAlert = (content) => {
    setAlertContent(content);
    setAlertOpen(true);
  }

  function setPeriodFromDevice(device){
    switch(device.onoffPeriod){
      case 1: setPeriod('10초');  break;
      case 2: setPeriod('20초');  break;
      case 3: setPeriod('30초');  break;
      case 4: setPeriod('40초');  break;
      case 5: setPeriod('50초');  break;
      case 6: setPeriod('60초');  break;
      default: setPeriod('10초');  break;
    }
  }
  function setHoldingTimeFromDevice(device){
    switch(device.duration){
      case 1: setHoldingTime('10분');  break;
      case 2: setHoldingTime('20분');  break;
      case 3: setHoldingTime('30분');  break;
      case 4: setHoldingTime('40분');  break;
      case 5: setHoldingTime('50분');  break;
      case 6: setHoldingTime('60분');  break;
      default: setHoldingTime('30분');  break;
    }
  }

  useEffect(()=>{
    if (open){
      if (curDevInfo.shipNo != null){
        if (curDevInfo.isShip){
          setLed(false);
          setPeriod('10초');
          setHoldingTime('30분');
        }
        else{
          const device = curDevice;
          setLed((device.state & 0x0F)? true:false);
          setPeriodFromDevice(device);
          setHoldingTimeFromDevice(device);
        }
      }
    }
  }, [open]);

  useEffect(()=>{
    if (open)
      console.log(devices);
  }, [open])

/*  useEffect(()=>{
  //  console.log(devices);
    if (open){
      console.log("device state "+devices.find((device)=> device.devEui === curDevice.devEui).state);
      console.log("curDevice.state "+curDevice.state);
      console.log(curDevice);
    }
  }, [open]);*/

  function apply() {
    var periodValue, durationValue;
    switch(period){
      case '10초':  periodValue = 1;  break;
      case '20초':  periodValue = 2;  break;
      case '30초':  periodValue = 3;  break;
      case '40초':  periodValue = 4;  break;
      case '50초':  periodValue = 5;  break;
      case '60초':  periodValue = 6;  break;
      default:      periodValue = 1;  break;
    }
    switch(holdingTime){
      case '10분':  durationValue = 1;  break;
      case '20분':  durationValue = 2;  break;
      case '30분':  durationValue = 3;  break;
      case '40분':  durationValue = 4;  break;
      case '50분':  durationValue = 5;  break;
      case '60분':  durationValue = 6;  break;
      default:      durationValue = 3;  break;
    }

    log = [];
    if (curDevInfo.isShip){
      const devicesToApply = devices.filter(device => device.shipNo === curDevInfo.shipNo);
      if (devicesToApply.length < 1){
        setAlert("등록된 부이가 없습니다.");
        return;
      }
      devicesToApply.forEach((device) => {
        var curLog = {no:device.no, devEui:device.devEui, shipNo:device.shipNo, result:'', description:''};
        log = [...log, curLog];

        // 디바이스로 송신
        const workend = led? 0:device.workend;
        const state = (device.state & 0xF0) | (led? 0x01:0x00);
        const distance = device.distanceAlarm? device.distance : 0;
        const data = makeDeviceData(workend, distance, state, periodValue, durationValue, device.commPeriod);
        //console.log(device.devEui+" : "+data);

        // 큐 삭제
        axios(commDeleteQueue(device.devEui))
        .then(function (response) {
          // 큐 삭제 확인후 데이터 송신  
          const sendData = commSendToDevice(device.devEui, data); 
          axios(sendData)
          .then(function (response) {

            commSaveDeviceSendDataLog(device.devEui, sendData, "제어");

            // 성공
            log.find(item => item.devEui === device.devEui).result = true;
            if (log.filter(item => item.result === '').length === 0){
              if (log.filter(item => item.result === false).length > 0){
                dispatch(setErrorLog(log));
                dispatch(updateModalState('errorlog', true));
              }
              else{
                //dispatch(setMyAlert(true, "전송완료"));
                setAlert("전송이 완료되었습니다.");
              }
            }
            // 배열 수정
            dispatch(updateDevice(device.devEui, 'workend', workend));
            dispatch(updateDevice(device.devEui, 'state', state));
            dispatch(updateDevice(device.devEui, 'onoffPeriod', periodValue));
            dispatch(updateDevice(device.devEui, 'duration', durationValue));
            dispatch(updateDevice(device.devEui, 'lastComm', getCurTime()));
            
            console.log(getCurTime());
            const updateDB = {devEui: device.devEui, workend:workend, state: state, onoffPeriod: periodValue, duration: durationValue, lastComm: getCurTime()};
            commUpdateDeviceDB(updateDB);
          })
          .catch(function (error) {
            log.find(item => item.devEui === device.devEui).result = false;
            log.find(item => item.devEui === device.devEui).description = error.message;
            if (log.filter(item => item.result === '').length === 0){
              dispatch(setErrorLog(log));
              dispatch(updateModalState('errorlog', true));
            }
          });
        })
        .catch(function (error) {
          log.find(item => item.devEui === device.devEui).result = false;
          log.find(item => item.devEui === device.devEui).description = error.message;
          if (log.filter(item => item.result === '').length === 0){
            dispatch(setErrorLog(log));
            dispatch(updateModalState('errorlog', true));
          }
        });
      });
    }
    else{
      //console.log(devices);
      var curLog = {no:curDevice.no, devEui:curDevice.devEui, shipNo:curDevice.shipNo, result:'', description:''};
      log = [...log, curLog];
      // 디바이스로 송신
      const workend = led? 0:curDevice.workend;
      const state = (curDevice.state & 0xF0) | (led? 0x01:0x00);
      const distance = curDevice.distanceAlarm? curDevice.distance : 0;
      const data = makeDeviceData(workend, distance, state, periodValue, durationValue, curDevice.commPeriod);
      console.log("data "+data);      

      // 큐 삭제
      axios(commDeleteQueue(curDevice.devEui))
      .then(function (response) {
        // 큐 삭제 확인후 데이터 송신        
        const sendData = commSendToDevice(curDevice.devEui, data);
        axios(sendData)
        .then(function (response) {
          
          commSaveDeviceSendDataLog(curDevice.devEui, sendData, "제어");

          // 성공
          log[0].result = true;
          //dispatch(setErrorLog(log));
          //dispatch(updateModalState('errorlog', true));
          // 배열 수정
          dispatch(updateCurDevice('state', state));
          dispatch(updateCurDevice('onoffPeriod', periodValue));
          dispatch(updateCurDevice('duration', durationValue));
          dispatch(updateCurDevice('workend', workend));
          dispatch(updateDevice(curDevice.devEui, 'state', state));
          dispatch(updateDevice(curDevice.devEui, 'onoffPeriod', periodValue));
          dispatch(updateDevice(curDevice.devEui, 'duration', durationValue));
          dispatch(updateDevice(curDevice.devEui, 'lastComm', getCurTime()));
          dispatch(updateDevice(curDevice.devEui, 'workend', workend));

          const updateDB = {devEui: curDevice.devEui, workend:workend, state: state, onoffPeriod: periodValue, duration: durationValue, lastComm: getCurTime()};
          commUpdateDeviceDB(updateDB);

          //dispatch(setMyAlert(true, "전송완료"));
          setAlert("전송이 완료되었습니다.");
        })
        .catch(function (error) {
          log[0].result = false;
          log[0].description = error.message;
          dispatch(setErrorLog(log));
          dispatch(updateModalState('errorlog', true));
        });
      })
      .catch(function (error) {
        log[0].result = false;
        log[0].description = error.message;
        dispatch(setErrorLog(log));
        dispatch(updateModalState('errorlog', true));
      });
    }
    //close();
  };
  function changeLed() {
    setLed(!led);
  };

  useEffect(() => {
    //console.log("request "+request);
    if (request){
      setRequest(request-1);
    }
    else{
      setClicked(true);
    }
  }, [request]);

  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <div className='modalFrame'>
          <div className='left'>
            <div className='left_up'>
              <div className='left_up1'>CONTROL</div>
              <div className='left_up2'>제어</div>
            </div>
            <div className='left_down'>
              {curDevInfo.isShip ? (
                <div>
                  <div><BiRectangle></BiRectangle>{' '+curShip.shipName}</div>
                  <div className='left_down_1'>{' ('+curShip.shipNo+')'}</div>
                  </div>
                ) : (
                  <div><BiRectangle></BiRectangle>
                  {' '+curDevice.devEui.toUpperCase()}</div>
                )}
            </div>
          </div>
          <div className='right'>
            <div className='right_up'>
              <div className='right_up1'>
                <BiX className='button1' onClick={close}></BiX>
              </div>
              <div className='blank_20'></div>
              <div className='right_large'>
                <div className='right_item'>
                  {curDevInfo.isShip? "전체 LED":"LED"}</div>
                {led ? (
                    <div className='switch_on1' onClick={changeLed}>
                      점등
                      <div className='switch_on2'></div>
                    </div>
                 ) : (
                    <div className='switch_off1' onClick={changeLed}>
                      <div className='switch_on2'></div>
                      소등
                    </div> 
                 )
                }           
              </div>
              <div className='blank_20'></div>
              <div className='blank_20'></div>
              <div className='bottomLine'></div>
              <div className='blank_20'></div>
              <div className='right_sub'>
                <div>ON/OFF주기</div>
                <div onClick={(e) => {
                    //console.log("clicked");
                    e.preventDefault();
                    setRequest(1);
                    setPulldown(1);
                    setPoints({x: e.pageX, y: e.pageY});
                }}>
                  <div className='cursorPoint'>
                    {period+' '}
                    <BiChevronDown></BiChevronDown>
                  </div>
                </div>
              </div>
              <div className='right_sub'>
                <div>유지시간</div>
                <div onClick={(e) => {
                    //console.log("clicked");
                    e.preventDefault();
                    setRequest(1);
                    setPulldown(2);
                    setPoints({x: e.pageX, y: e.pageY});
                }}>
                  <div className='cursorPoint'>
                    {holdingTime+' '}
                    <BiChevronDown></BiChevronDown>
                  </div>
                </div>
              </div>
            </div>
            <div className='right_down'>
              <div className='apply' onClick={apply}>제어</div>
              <div className='apply' onClick={close}>취소</div>
            </div>
          </div>
        </div>
      ) : null} 
      {clicked && (
        <ContextMenu1 top={points.y-80} left={points.x-10}>
          {pulldown === 1 ?(
            <div>
              <div className='modalContextItems'>
                <div className='modalContextItem' onClick={() => setPeriod('10초')}>10초</div>
                <div className='modalContextItem' onClick={() => setPeriod('20초')}>20초</div>
                <div className='modalContextItem' onClick={() => setPeriod('30초')}>30초</div>
                <div className='modalContextItem' onClick={() => setPeriod('40초')}>40초</div>
                <div className='modalContextItem' onClick={() => setPeriod('50초')}>50초</div>
                <div className='modalContextItem' onClick={() => setPeriod('60초')}>60초</div>
              </div>
            </div>
          ) : (
            <div>
              <div className='modalContextItems'>
                <div className='modalContextItem' onClick={() => setHoldingTime('10분')}>10분</div>
                <div className='modalContextItem' onClick={() => setHoldingTime('20분')}>20분</div>
                <div className='modalContextItem' onClick={() => setHoldingTime('30분')}>30분</div>
                <div className='modalContextItem' onClick={() => setHoldingTime('40분')}>40분</div>
                <div className='modalContextItem' onClick={() => setHoldingTime('50분')}>50분</div>
                <div className='modalContextItem' onClick={() => setHoldingTime('60분')}>60분</div>
              </div>
            </div>
          )}          
        </ContextMenu1>
      )}
      {alertOpen && (
        <MyAlert content={alertContent} ok={()=>{setAlertOpen(false); close();}}/>
      )}
    </div>
  );
};

export default ModalControl