import React, { useState, useEffect } from 'react';
import './topbar.css'
import styled, {css} from 'styled-components';
import Button from '../button/Button';
import ButtonL from '../button/ButtonL';
import ButtonM from '../button/ButtonM';
import ButtonR from '../button/ButtonR';
import LogoIcon from '../../icons/logo.svg'
import ModalWorkend from '../modals/ModalWorkend';
import ModalControl from '../modals/ModalControl';
import ModalSetting from '../modals/modalSetting';
import ModalUserInfo from '../modals/ModalUserInfo';
import ModalLogout from '../modals/ModalLogout';
import { funcWorkend } from '../modals/Modal.js';
import ModalShip from '../modals/ModalShip';
import ModalShipList from '../modals/ModalShipList';
import ModalDevice from '../modals/ModalDevice';
import ModalSystem from '../modals/ModalSystem';
import ModalComm from '../modals/ModalComm';
import ModalError from '../modals/ModalError';
import { ContextMenu2 } from "../../components/popupMenu/styles";
import {commGetGateway, commDeleteQueue, commGetDevices, getCommLog, commSendToDevice, makeDeviceData} from '../comm/Comm';
import {commUpdateDeviceDB} from '../comm/CommUpdateDB';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import { setCurDevInfo } from '../../redux/modules/curDevInfo';
import { setCurShip } from '../../redux/modules/curShip';
import { setCurDevice } from '../../redux/modules/curDevice';
import { updateDevice } from "../../redux/modules/devices";
import { updateCurDevice } from "../../redux/modules/curDevice";
import { BiChevronDown } from 'react-icons/bi'
import { IoIosArrowDown } from 'react-icons/io'
import { toggleLeftMenu } from '../../redux/modules/base';
import { Squash as Hamburger } from 'hamburger-react';
import { updateModalState } from '../../redux/modules/modalState';
import UseContextMenu from '../../components/popupMenu/UseContextMenu';
import SearchResult from '../popupMenu/SearchResult';
import { setErrorLog } from '../../redux/modules/errorLog';
import { BiX } from 'react-icons/bi';
import { BiSearch } from 'react-icons/bi';
import { Confirm, MyAlert } from '../popupMenu/Confirm';
import { setMyAlert } from '../../redux/modules/myAlert';

var log = [];

const Topbar = ({changeInfo, changeInfoClose}) => {
    //const [modalWorkendOpen, setModalWorkendOpen] = useState(false);
    //const [modalControlOpen, setModalControlOpen] = useState(false);
    //const [modalSettingOpen, setModalSettingOpen] = useState(false);
    const [modalShipOpen, setModalShipOpen] = useState(false);
    const [modalDeviceOpen, setModalDeviceOpen] = useState(false);
    const [modalSystemOpen, setModalSystemOpen] = useState(false);
    const [modalCommOpen, setModalCommOpen] = useState(false);
    const [modalChangeInfo, setModalChangeInfo] = useState(false);
    const dispatch = useDispatch();
    const ships = useSelector((state) => state.ships);
    const devices = useSelector((state) => state.devices);
    const curDevInfo = useSelector((state) => state.curDevInfo);
    const modalState = useSelector((state) => state.modalState);
    const myAlert = useSelector((state) => state.myAlert);
    const curUser = useSelector((state) => state.curUser);    
    const {clicked, setClicked, points, setPoints} = UseContextMenu();
    const [request, setRequest] = useState(-1);
    const [searchItem, setSearchItem] = useState(2);
    const [searchContent, setSearchContent] = useState("");
    const [searchResultOpen, setSearchResultOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertContent, setAlertContent] = useState("");
    const { visibilityLeftMenu } = useSelector((state) => state.leftMenuVisibility);
    const [isOpen, setOpen] = useState(true);

    const clickToggleLeftMenu = () => {
        if(visibilityLeftMenu)
            dispatch(toggleLeftMenu(false));
        else
            dispatch(toggleLeftMenu(true));
    };

/*    useEffect(() => {
        //console.log("Topbar changeInfo "+changeInfo);
        if (changeInfo)
            setModalShipOpen(true);
        else
            setModalShipOpen(false);
    }, [changeInfo]);*/

    const setAlert = (content) => {
        setAlertContent(content);
        setAlertOpen(true);
    }

    useEffect(() => {
        if (myAlert.on){
            setAlert(myAlert.content);
            dispatch(setMyAlert(false, ""));
        }
    }, [myAlert]);
    
    function modalShipClose(){
        setModalShipOpen(false);
        if (changeInfo)
            changeInfoClose();
    }

    function WorkendOpen(){
        if (ships.length === 0){
            setAlert("등록된 어선이 없습니다.");
            return;
        }        
        const devicesToApply = devices.filter(device => device.shipNo === curDevInfo.shipNo);
        if (devicesToApply.length < 1){
            setAlert("등록된 부이가 없습니다.");
            return;
        }
                  
        dispatch(updateModalState('workend', true));
    }
    function controlOpen(){
        if (ships.length === 0){
            setAlert("등록된 어선이 없습니다.");
            return;
        }        
        const devicesToApply = devices.filter(device => device.shipNo === curDevInfo.shipNo);
        if (devicesToApply.length < 1){
            setAlert("등록된 부이가 없습니다.");
            return;
        }
        dispatch(updateModalState('control', true));
    }
    function settingOpen(){
        if (ships.length === 0){
            setAlert("등록된 어선이 없습니다.");
            return;
        }        
        const devicesToApply = devices.filter(device => device.shipNo === curDevInfo.shipNo);
        if (devicesToApply.length < 1){
            setAlert("등록된 부이가 없습니다.");
            return;
        }
        dispatch(updateModalState('setting', true));
    }
    function commLogOpen(){
        //commGetGateway();
	    setModalCommOpen(true);	
        getCommLog();
    }
    function commGetDataInfo(){
        commGetDevices();
    }

    useEffect(() => {
        //console.log("request "+request);
        if (request !== -1){
            if (request){
            setRequest(request-1);
            }
            else{
            setClicked(true);
            }
        }
    }, [request]);

    function selectSearchItemClicked(){
        if (clicked)
            setClicked(false);
        else
            setRequest(1);

        closeSearchWindow();
    }
    function closeSearchWindow(){
        setSearchResultOpen(false);
        setSearchContent("");
        //setSearchItem(0);
    }

    function textSearchItem(){
        switch(searchItem){
            case 0: return "::검색선택::";
            case 1: return "어망ID";
            case 2: return "어선명";
            case 3: return "어선번호";
            case 4: return "게이트웨이";
        }
    }
    
    const onChange = (e) => {
        setSearchContent(e.target.value.toUpperCase());
    };

    function searchClicked(){
        if (searchItem !== 0){
            setClicked(false);
            setSearchResultOpen(true);
        }
    }
    const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
            searchClicked(); // Enter 입력이 되면 클릭 이벤트 실행
        }
    };

    return (
        <div className="topbar">
            {/*
            <div>
                <div className="topbarWrapper">
                    <div className="topLeft">
                        <img src={LogoIcon} height={40} />                        
                    </div>
                    <div className="myPage">
                        MyPage
                    </div>
                </div>
            </div>
            */}            
            <div className="logo1">
                <div className='logo1_left'>
                    <div>
                        <Hamburger rounded toggled={isOpen} toggle={setOpen} size={24} onToggle={toggled => {
                            clickToggleLeftMenu();
                        }} />
                    </div>
                    <div className="buttons" >
                        <div className='topbar_buttonL' onClick={WorkendOpen}>작업완료</div>
                        <div className='topbar_buttonM' onClick={controlOpen}>제어</div>
                        <div className='topbar_buttonR' onClick={settingOpen}>설정</div>
  
                        <div className='hblank_10' />
                        <div className='topbar_search1' onClick={selectSearchItemClicked}>
                            <div style={{display:"flex"}}>
                                {(searchItem === 1 || searchItem === 2 || searchItem === 3)? <div className='hblank_10'/>:<div className='hblank_5'/>}
                                <div>{textSearchItem()}</div>
                            </div>
                            <IoIosArrowDown/>
                        </div>

                        <div className="topbar_search">
                            <input className='input1' onChange={onChange}
                                placeholder='검색어를 입력해주세요.' 
                                onKeyDown={(e) => handleOnKeyPress(e)} value={searchContent}>
                            </input>
                            <BiSearch className='topbar_button3' onClick={searchClicked}/>
                        </div>

                        <ModalControl 
                            open={modalState.control} 
                            close={() => dispatch(updateModalState('control', false))} 
                            ></ModalControl>
                        <ModalSetting
                            open={modalState.setting} 
                            close={() => dispatch(updateModalState('setting', false))} 
                            ></ModalSetting>
                        <ModalWorkend
                            open={modalState.workend}
                            close={() => dispatch(updateModalState('workend', false))}
                            ></ModalWorkend>
                    </div>
                </div>
                <div className='logo1_right'>
                    {curUser.isAdmin ?
                        <div className="buttons" >
                        <div className='topbar_buttonL' onClick={() => dispatch(updateModalState('shipList', true))}>어선목록</div>
                        <div className='topbar_buttonM' onClick={() => setModalDeviceOpen(true)}>장치등록</div>
                        <div className='topbar_buttonM' onClick={() => setModalSystemOpen(true)}>시스템</div>
                        <div className='topbar_buttonR' onClick={commLogOpen}>통신로그</div>
                        <ModalShipList
                            open={modalState.shipList} 
                            close={() => dispatch(updateModalState('shipList', false))}
                            isRegister={!changeInfo} />
                        <ModalDevice 
                            open={modalDeviceOpen} 
                            close={() => setModalDeviceOpen(false)} />
                        <ModalSystem
                            open={modalSystemOpen} 
                            close={() => setModalSystemOpen(false)} />
                        <ModalComm
                            open={modalCommOpen} 
                            close={() => setModalCommOpen(false)} />
                        <ModalShip 
                            open={modalState.shipEdit} 
                            close={() => dispatch(updateModalState('shipEdit', false))}
                            isRegister={false} />
                        <ModalShip 
                            open={modalState.shipRegister} 
                            close={() => dispatch(updateModalState('shipRegister', false))}
                            isRegister={true} />
                    </div>:null
                    }                    
                </div>
            </div>
            <ModalError
                open={modalState.errorlog} 
                close={() => dispatch(updateModalState('errorlog', false))} />
            <ModalUserInfo
                open={modalState.userinfo} 
                close={() => dispatch(updateModalState('userinfo', false))} />
            <ModalLogout
                open={modalState.logout}
                close={() => dispatch(updateModalState('logout', false))} />
            {clicked && (
                <ContextMenu2 top={37} left={288}>
                  <div>
                    <div className='topbar_ContextItems'>
                      <div className='topbar_ContextItem' onClick={()=>setSearchItem(1)}>어망ID</div>
                      <div className='topbar_ContextItem' onClick={()=>setSearchItem(2)}>어선명</div>
                      <div className='topbar_ContextItem' onClick={()=>setSearchItem(3)}>어선번호</div>
                      <div className='topbar_ContextItem' onClick={()=>setSearchItem(4)}>게이트웨이</div>
                    </div>
                  </div>    
              </ContextMenu2>
            )}
            {searchResultOpen && (
                <SearchResult
                    searchItem={searchItem}
                    searchContent={searchContent}
                    close={closeSearchWindow}>
                </SearchResult>
            )}
            {alertOpen && (
              <MyAlert content={alertContent} ok={()=>setAlertOpen(false)}/>
            )}
        </div>
    )
}

export default Topbar