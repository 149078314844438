import {React, useState, useEffect} from 'react'
import './sidebar.css'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AdjustIcon from '@mui/icons-material/Adjust';
import LedOnIcon from '../../icons/buoyOn.svg'
import LedOffIcon from '../../icons/buoyOff.svg'
import Battery_0 from '../../icons/battery_0.svg'
import Battery_10 from '../../icons/battery_10.svg'
import Battery_20 from '../../icons/battery_20.svg'
import Battery_30 from '../../icons/battery_30.svg'
import Battery_40 from '../../icons/battery_40.svg'
import Battery_50 from '../../icons/battery_50.svg'
import Battery_60 from '../../icons/battery_60.svg'
import Battery_70 from '../../icons/battery_70.svg'
import Battery_80 from '../../icons/battery_80.svg'
import Battery_90 from '../../icons/battery_90.svg'
import Battery_100 from '../../icons/battery_100.svg'
import distAlarmIcon from '../../icons/distAlarm.svg'
import gpsErrorIcon from '../../icons/gpsError.svg'
import { useSelector, useDispatch } from "react-redux";
import { setCurDevInfo } from '../../redux/modules/curDevInfo';
import { setCurShip } from '../../redux/modules/curShip';
import { setCurDevice, updateCurDevice } from '../../redux/modules/curDevice';
import { setCurDevices } from '../../redux/modules/curDevices';
import axios from 'axios';
import { isNew } from '../comm/Comm';
import { updateDevice } from '../../redux/modules/devices';
import { commUpdateDeviceDB } from '../../components/comm/CommUpdateDB';

const batteryImg = [
    Battery_0,
    Battery_10,
    Battery_20,
    Battery_30,
    Battery_40,
    Battery_50,
    Battery_60,
    Battery_70,
    Battery_80,
    Battery_90,
    Battery_100,
];

function battery(value){
    //console.log("batter "+value);
    const width = 10;
    let val = parseInt((value+5)/10);
    
    return(<img src={batteryImg[val]} width={width} />)
}

function ledImage(state){
    if (state & 0x0F){
        return ( <div> <img src={LedOnIcon} width={15} /> </div> )
    }
    else{
        return ( <div> <img src={LedOffIcon} width={15} /> </div> )
    }
}

function alarmImage(state){
    //console.log(state & 0xF0);
    if (state & 0x10){
        return ( <img src={gpsErrorIcon} width={15} /> )
    }
    else if (state & 0x20){
        return ( <img src={distAlarmIcon} width={16} /> )
    }
    else{
        return (<div></div>)
    }
}

const SubItem = ({device/*, curDevInfo, onCurDevChange*/}) => {
    const dispatch = useDispatch();
    const curDevInfo = useSelector((state) => state.curDevInfo);
    const ships = useSelector((state) => state.ships);

    function UpdateDeviceFromDB(devEui, data)
    {
        dispatch(updateDevice(devEui, 'battery', data.battery));
        dispatch(updateDevice(devEui, 'lat', data.lat));
        dispatch(updateDevice(devEui, 'lng', data.lng));
        dispatch(updateDevice(devEui, 'state', data.state));
        dispatch(updateDevice(devEui, 'power', data.power));
        dispatch(updateDevice(devEui, 'workend', data.workend));
        dispatch(updateDevice(devEui, 'distanceAlarm', data.distanceAlarm));
        dispatch(updateDevice(devEui, 'distance', data.distance));
        dispatch(updateDevice(devEui, 'LedOnTime', data.LedOnTime));
        dispatch(updateDevice(devEui, 'onoffPeriod', data.onoffPeriod));
        dispatch(updateDevice(devEui, 'duration', data.duration));
        dispatch(updateDevice(devEui, 'commPeriod', data.commPeriod));
        dispatch(updateDevice(devEui, 'lastComm', data.lastComm));
        
        dispatch(updateCurDevice('battery', data.battery));
        dispatch(updateCurDevice('lat', data.lat));
        dispatch(updateCurDevice('lng', data.lng));
        dispatch(updateCurDevice('state', data.state));
        dispatch(updateCurDevice('power', data.power));
        dispatch(updateCurDevice('workend', data.workend));
        dispatch(updateCurDevice('distanceAlarm', data.distanceAlarm));
        dispatch(updateCurDevice('distance', data.distance));
        dispatch(updateCurDevice('LedOnTime', data.LedOnTime));
        dispatch(updateCurDevice('onoffPeriod', data.onoffPeriod));
        dispatch(updateCurDevice('duration', data.duration));
        dispatch(updateCurDevice('commPeriod', data.commPeriod));
        dispatch(updateCurDevice('lastComm', data.lastComm));
    }

    function handleCurDevChange() {
        const devInfo = {isShip: false, shipNo: device.shipNo, devEui: device.devEui, fromSide: true};
        dispatch(setCurDevInfo(devInfo));
        dispatch(setCurDevice(device));
        
        const ship = ships.find(ship => ship.shipNo === device.shipNo);
        dispatch(setCurShip(ship));
        
        // 선택된 device 정보를 DB에서 업데이트
        axios({
            method: "GET",
            url: '/api/devicedb/dev?&devEui=' + device.devEui,
        })
        .then(function(response) {
            //console.log(response);
            const memoryLastComm = device.lastComm;
            const dbLastComm = response.data[0].lastComm;
            if (isNew(dbLastComm, memoryLastComm)){
                UpdateDeviceFromDB(device.devEui, response.data[0]);
            }
        }).catch(error=>{
            console.log(error);
        });
    }

    return (
        <div>
            <div onContextMenu = {(e) => {
                    e.preventDefault();
                    handleCurDevChange();
                }}>
                <li className= {((curDevInfo.isShip === false) && (device.devEui === curDevInfo.devEui))? "sidebarSubCurItem":"sidebarSubListItem"} onClick={handleCurDevChange}>
                    <div className='sub0'>{((device.no == -1)? '':(device.no +'.'))}</div>
                    <div className='sub1'>{device.devEui.toUpperCase()}</div>
                    <div className='time'>
                        {'[' + ((device.lastComm === "" || device.lastComm === null)? '미접속':device.lastComm) + ']'}</div>
                    <div className='sub4'>{alarmImage(device.state)}</div>
                    <div className='sub4'>{ledImage(device.state)}</div>
                    <div className='sub2'>{battery(device.battery)}</div>
                </li>
            </div>
        </div>
    )
}

const SbItem = ({ship}) => {
    const [collapsed, setCollapsed] = useState(false);
    const icon = collapsed ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />;
    const curDevInfo = useSelector((state) => state.curDevInfo);
    const devices = useSelector((state) => state.devices);
    const ships = useSelector((state) => state.ships);
    const curShip = useSelector((state) => state.curShip);
    const dispatch = useDispatch();

    function toggleCollapse() {
        setCollapsed(!collapsed);
        if (selectedDevices.length === 0){
            setCollapsed(false);
        }
    }
    
    function UpdateDeviceFromDB(devEui, data)
    {
        dispatch(updateDevice(devEui, 'battery', data.battery));
        dispatch(updateDevice(devEui, 'lat', data.lat));
        dispatch(updateDevice(devEui, 'lng', data.lng));
        dispatch(updateDevice(devEui, 'state', data.state));
        dispatch(updateDevice(devEui, 'power', data.power));
        dispatch(updateDevice(devEui, 'workend', data.workend));
        dispatch(updateDevice(devEui, 'distanceAlarm', data.distanceAlarm));
        dispatch(updateDevice(devEui, 'distance', data.distance));
        dispatch(updateDevice(devEui, 'LedOnTime', data.LedOnTime));
        dispatch(updateDevice(devEui, 'onoffPeriod', data.onoffPeriod));
        dispatch(updateDevice(devEui, 'duration', data.duration));
        dispatch(updateDevice(devEui, 'commPeriod', data.commPeriod));
        dispatch(updateDevice(devEui, 'lastComm', data.lastComm));
        
    /*    dispatch(updateCurDevice('battery', data.battery));
        dispatch(updateCurDevice('lat', data.lat));
        dispatch(updateCurDevice('lng', data.lng));
        dispatch(updateCurDevice('state', data.state));
        dispatch(updateCurDevice('power', data.power));
        dispatch(updateCurDevice('workend', data.workend));
        dispatch(updateCurDevice('distanceAlarm', data.distanceAlarm));
        dispatch(updateCurDevice('distance', data.distance));
        dispatch(updateCurDevice('LedOnTime', data.LedOnTime));
        dispatch(updateCurDevice('onoffPeriod', data.onoffPeriod));
        dispatch(updateCurDevice('duration', data.duration));
        dispatch(updateCurDevice('commPeriod', data.commPeriod));
        dispatch(updateCurDevice('lastComm', data.lastComm));*/
    }

    function isTimeOver(last, period){
      var today = new Date();
      var month = ('0' + (today.getMonth() + 1)).slice(-2);
      var day = ('0' + today.getDate()).slice(-2);
      var hour = ('0' + today.getHours()).slice(-2);
      var min = ('0' + today.getMinutes()).slice(-2);
      var todayString = month + '-' + day + ' ' + hour + ':' + min;
  
      var last_month = parseInt(last.substring(0,2), 10);
      var last_day = parseInt(last.substring(3,5), 10);
      var last_hour = parseInt(last.substring(6,8), 10);
      var last_min = parseInt(last.substring(9,11), 10);
  
      last_min = last_min + period;
      if (last_min >= 60){
        last_min = last_min - 60;
        last_hour = last_hour + 1;
      }
  
      if (last_hour >= 24){
        last_hour = last_hour - 24;
        last_day = last_day + 1;
      }
  
      last_day = ('0' + last_day).slice(-2);
      last_hour = ('0' + last_hour).slice(-2);
      last_min = ('0' + last_min).slice(-2);
      var lastString = ('0' + String(last_month)).slice(-2) + '-' + ('0' + String(last_day)).slice(-2) + 
                        ' ' + ('0' + String(last_hour)).slice(-2) + ':' + ('0' + String(last_min)).slice(-2);
  
      //console.log('todayString '+ todayString);
      //console.log('lastString '+ lastString);
  
      if (isNew(todayString, lastString)){
        //console.log("true");
        return true;
      }
      else{
        //console.log("false");
        return false;
      }
    }

    function handleCurShipChange() {
        //console.log("handleCurShipChange");
        const devInfo = {isShip: true, shipNo: ship.shipNo, devEui: null, fromSide: true};
        dispatch(setCurDevInfo(devInfo));
        dispatch(setCurShip(ship));
        dispatch(setCurDevices(devices, ship.shipNo));
            
        devices.filter(device => device.shipNo === ship.shipNo).map(device => {
            axios({
                method: "GET",
                url: '/api/devicedb/dev?&devEui=' + device.devEui,
            })
            .then(function(response) {
                //console.log("response");
                //console.log(response);
                const memoryLastComm = device.lastComm;
                const dbLastComm = response.data[0].lastComm;
                //console.log("devEui: " + device.devEui);
                //console.log("memoryLastComm: " + memoryLastComm);
                //console.log("dbLastComm: " + dbLastComm);
                if (isNew(dbLastComm, memoryLastComm)){
                    UpdateDeviceFromDB(device.devEui, response.data[0]);
                }

                var workendOff = false;
                var ledTurnOff = false;

                if (response.data[0].state & 0x0F){ //LED On
                    if (isTimeOver(response.data[0].LedOnTime, response.data[0].duration * 10)){
                        workendOff = true;
                    }
                    if (response.data[0].LedOnTime === '01-01 09:00'){
                        //Led On 시간이 없으면 최종통신시간 + Led 유지시간을 넘으면 Led Off
                        if (isTimeOver(response.data[0].lastComm, response.data[0].duration * 10)){
                            ledTurnOff = true;
                        }
                    }
                    else{
                        //Led On 시간이 있으면 Led On 시간 + Led 유지시간을 넘으면 Led Off
                        if (isTimeOver(response.data[0].LedOnTime, response.data[0].duration * 10)){
                            ledTurnOff = true;
                        }
                    }
                }
                else{ //LED Off
                    if (isTimeOver(response.data[0].lastComm, response.data[0].commPeriod * 10)){
                        workendOff = true;
                    }
                    if (response.data[0].LedOnTime !== '01-01 09:00'){
                        //Led Off인데 Led On 시간이 '01-01 09:00'이 아니면 그렇게 만듦
                        ledTurnOff = true;
                    }
                }

                if (workendOff){
                    dispatch(updateDevice(device.devEui, 'workend', 0));
                    if (curDevInfo.isShip === false && device.devEui === curDevInfo.devEui){
                        dispatch(updateCurDevice('workend', 0));
                    }
                    const updateDB = {devEui: device.devEui, workend: 0};
                    commUpdateDeviceDB(updateDB);
                }

                if (ledTurnOff){
                    dispatch(updateDevice(device.devEui, 'state', (response.data[0].state & 0xF0)));
                    dispatch(updateDevice(device.devEui, 'LedOnTime', '01-01 09:00'));
                    if (curDevInfo.isShip === false && device.devEui === curDevInfo.devEui){
                    dispatch(updateCurDevice('state', (response.data[0].state & 0xF0)));
                    dispatch(updateCurDevice('LedOnTime', '01-01 09:00'));
                    }
                    const updateDB = {devEui: device.devEui, state: (response.data[0].state & 0xF0), LedOnTime: '01-01 09:00'};
                    commUpdateDeviceDB(updateDB);
                }
            }).catch(error=>{
                console.log("error !!!");
                console.log(error);
            });
        })
    }

    function shipGpsError(shipNo){
        if (devices.filter(device => (device.shipNo === shipNo && (device.state & 0xF0) === 16)).length > 0){
            return ( <img src={gpsErrorIcon} width={15} /> )
        }
        else{
            return (<div></div>)
        }
    }    
    function shipDistAlarm(shipNo){
        if (devices.filter(device => (device.shipNo === shipNo && (device.state & 0xF0) === 32)).length > 0){
            return ( <img src={distAlarmIcon} width={16} /> )
        }
        else{
            return (<div></div>)
        }
    }

    useEffect(() => {
        if (curDevInfo.isShip === false && curDevInfo.shipNo === ship.shipNo && collapsed === false){
            setCollapsed(true);
        }
    }, [curDevInfo]);
    
    let selectedDevices = devices.filter(device => device.shipNo === ship.shipNo).sort((a,b) => (b.no > a.no)? -1:1);

    function getIcon(){
        if (selectedDevices.length === 0){
            return <AdjustIcon />;
        }
        else{
            if (collapsed){
                return <RemoveCircleOutlineIcon />;
            }
            else{
                return <AddCircleOutlineIcon />;
            }
        }
    }

    return (
        <div>
            <div onContextMenu = {(e) => {
                    e.preventDefault();
                    handleCurShipChange();
                }}>
                <li className= {((curDevInfo.isShip === true) && (ship.shipNo === curDevInfo.shipNo))? "sidebarCurListItem":"sidebarListItem"}>
                    <div className='shipSub0' onClick={toggleCollapse}>
                        {getIcon()}</div>
                    <div className='shipSubL' onClick={handleCurShipChange}>
                        <div className='shipSub1'>
                            <div>
                                <span>{ship.shipName}</span>
                                <span className='shipSub1_2'>{' (' + ship.shipNo + ')'}</span>
                            </div>
                        </div>
                        <div className='shipSub2'>
                            <div>{shipGpsError(ship.shipNo)}</div>
                            <div className='shipHblank_5'/>
                            <div>{shipDistAlarm(ship.shipNo)}</div>
                            <div className='time1'> {'[' + 
                            ((ship.lastComm === "" || ship.lastComm === null)? '미접속':ship.lastComm) + ']'} </div>
                        </div>
                    </div>
                </li>
            </div>
            {collapsed ? (
                <div className='sidebar_ship'>
                    {selectedDevices.map((device) => (
                        <SubItem device={device} key={device.devEui} />
                    ))}
                </div>
            ) : null}
        </div>
    )
}

const Sidebar = ({onCurDevChange}) => {
    const ships = useSelector((state) => state.ships);
    const etc = useSelector((state) => state.etc);
    /*const handleCurDevChange = () => {
        onCurDevChange()
    }*/
    
    return (
        <div className="sidebar">
            <div className="sidebarWrapper">
                { ships && (
                <div>
                    {ships.map((ship) => (
                        <SbItem ship={ship} key={ship.shipNo}/>
                    ))}
                </div>
                )}
                { etc.noShip && (
                    <div className='noShip'>
                        등록된 어선이 없습니다.
                    </div>
                )}
            </div>
        </div>            
    )
}

export default Sidebar
