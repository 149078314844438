import React, { useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import storage from '../../lib/storage';

const AuthLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const loggedInfo = storage.get('loggedInfo');
    if (!loggedInfo) {
      //let code = new URL(window.location.href).searchParams.get("code");
      //let code = new URL(window.location.href).pathname.includes("/uploads");
      //alert(code);
      //alert(new URL(window.location.href))
      //if(!code)
        navigate("/login", { state: pathname });
    }
    else{
        navigate("/control", { state: pathname });
    }
  }, []);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default AuthLayout;