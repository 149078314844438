import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as userActions from '../../redux/modules/user';
import storage from '../../lib/storage';

const logout = async (props) => {
    const { UserActions } = props;

    try {
        await UserActions.logout();
    } catch (e) {
        console.log(e);
    }

    storage.remove('loggedInfo');
    window.location.href = '/';
}

const LogoutAfterTime = (props) => {
  const [timeLeft, setTimeLeft] = useState(props.time);

  useEffect(() => {
    const logoutTimer = setTimeout(() => {
      // 로그아웃 로직      
      logout(props);      
    }, timeLeft);

    return () => {
      clearTimeout(logoutTimer);
    };
  }, [timeLeft]);  

  return null;
};

export default connect(
    (state) => ({
        username: state.user.getIn(['loggedInfo', 'username'])
    }),
    (dispatch) => ({
        UserActions: bindActionCreators(userActions, dispatch)
    })
)(LogoutAfterTime);