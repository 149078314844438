import React, { Component } from 'react';
import Header, { TopButton } from '../../components/Base/Header';
import { connect } from 'react-redux';
import * as userActions from '../../redux/modules/user';
import * as baseActions from '../../redux/modules/base';
import { bindActionCreators } from 'redux';
import { Weather } from '../../components/Base/UserMenu';
import { Clock } from '../../components/Base/UserMenu';
import UserMenuContainer from '../../containers/Base/UserMenuContainer';
import { AiFillSetting } from 'react-icons/ai';
import { UserName } from '../../components/Base/UserMenu';
import storage from '../../lib/storage';

class HeaderContainer extends Component {
    handleMyMenuClick = () => {
        const { BaseActions } = this.props;
        BaseActions.setUserMenuVisibility(true);
    }

    render() {
        const { visible, user } = this.props;
        const { handleMyMenuClick } = this;

        if(!visible) return null;
        
        return (
            <Header>
                <Weather/>
                <Clock/>
                {/* <UserName username={user.getIn(['loggedInfo', 'username'])}/> */}
                <UserName username={storage.get('loggedInfo')?.username}/>
                { user.get('logged') 
                    ? <TopButton to={<AiFillSetting size='26'/>} onClick={handleMyMenuClick}/>
                    : <TopButton to={"로그인/회원가입"}/> 
                }
                {/* <UserMenuContainer /> */}
            </Header>
        );
    }
}

export default connect(
    (state) => ({
        visible: state.base.getIn(['header', 'visible']),
        visibleUserMenu: state.base.getIn(['userMenu', 'visibleUserMenu']),
        user: state.user
    }),
    (dispatch) => ({
        BaseActions: bindActionCreators(baseActions, dispatch),
        UserActions: bindActionCreators(userActions, dispatch)
    })
)(HeaderContainer);