import {React, useState, useEffect} from 'react';
import './modalUserSelect.css';
import { BiX } from 'react-icons/bi';
import {BiSearch} from 'react-icons/bi'
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from '../../redux/modules/users';

const ModalUserSelect = (props) => {
  const { open, close } = props;
  const users = useSelector((state) => state.users);
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [searchContent, setSearchContent] = useState("");

  useEffect(() => {
    if (props.open){
      users.forEach(user => {
        dispatch(updateUser(user.userid, 'select', false));
      });
      setSearchContent("");    
      setList(users);
      
      document.documentElement.style.setProperty('--user_1', variables.getPropertyValue('--user_1_init'));
      document.documentElement.style.setProperty('--user_2', variables.getPropertyValue('--user_2_init'));
      document.documentElement.style.setProperty('--user_3', variables.getPropertyValue('--user_3_init'));
      document.documentElement.style.setProperty('--user_4', variables.getPropertyValue('--user_4_init'));
      document.documentElement.style.setProperty('--user_5', variables.getPropertyValue('--user_5_init'));
      document.documentElement.style.setProperty('--user_6', variables.getPropertyValue('--user_6_init'));
      document.documentElement.style.setProperty('--user_total', variables.getPropertyValue('--user_total_init'));
    }
  }, [props.open]);

  useEffect(() => {
    setList(users);
  }, [users]);

  function okClicked(){
    const user = list.find(user => user.select === true);
    if (user !== undefined){
        props.setUser(user);
    }
    close();
  };

  function cancleClicked(){
    close();
  };
  
  function clickSelect(userid){
    const newList = list.map((user) =>
    user.userid === userid? {...user, select: true} : {...user, select: false});
    setList(newList);
  }

  function doubleClicked(userid){
    const user = list.find(user => user.userid === userid);
    if (user !== undefined){
        props.setUser(user);
    }
    close();
  }

  const onChange = (e) => {
    setSearchContent(e.target.value);
  };

  function search(){
    setList(users.filter(user => 
        user.userid.toUpperCase().includes(searchContent.toUpperCase()) ||
        user.email.toUpperCase().includes(searchContent.toUpperCase()) ||
        user.name.toUpperCase().includes(searchContent.toUpperCase()) ||
        user.phone.includes(searchContent) ));
  };
  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      search(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };
  function searchReset(){
    setSearchContent("");
    setList(users);
  }

  const [startX, setStartX] = useState(0);
  const [width, setWidth] = useState(0);
  const [total, setTotal] = useState(0);

  let root = document.querySelector(':root');
  let variables = getComputedStyle(root); 
  
  return (
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <div className='modalUser'>
        <div className='modalUser_title'>
            {"사용자 검색"}
            <BiX className='modalUser_button1' onClick={close}></BiX>
        </div>
        <div className='modalUser_content'>
          <div className='modalUser_right'>
            <div className='modalUser_right_flex'>
              <div className='modalUser_right2'>
                <div className='modalUser_result_1'>
                    <div style={{fontWeight:'bold', width:'30px'}}>검색</div>

                    <div className='modalUser_searchBox'>
                      <input className='searchInput' onChange={onChange}
                        placeholder={"ID, 이름, E-mail, 전화번호를 검색합니다."}
                        onKeyDown={(e) => handleOnKeyPress(e)} value={searchContent}></input>
                      <BiSearch className='modalUser_searchIcon' onClick={search} />
                    </div>

                </div>
                <div className='modalUser_blank_5' />
                  
                <div className='modalUser_result_2'>
                  <div className='modalUser_listitemTitle'>
                    <div className='modalUser_listitemTitleSub1' onDragOver={(e)=>{e.preventDefault()}}>ID</div>
                    
                    <div className='modalUser_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                      onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                        setWidth(variables.getPropertyValue('--user_1').slice(0,-2));
                        setTotal(variables.getPropertyValue('--user_total').slice(0,-2));}}
                      onDrag={(e)=>{document.documentElement.style.setProperty('--user_1', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                      onDragEnd={(e)=>{document.documentElement.style.setProperty('--user_1', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                        document.documentElement.style.setProperty('--user_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                    <div className='modalUser_listitemTitleSub2' onDragOver={(e)=>{e.preventDefault()}}>이름</div>
                    
                    <div className='modalUser_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                      onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                        setWidth(variables.getPropertyValue('--user_2').slice(0,-2));
                        setTotal(variables.getPropertyValue('--user_total').slice(0,-2));}}
                      onDrag={(e)=>{document.documentElement.style.setProperty('--user_2', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                      onDragEnd={(e)=>{document.documentElement.style.setProperty('--user_2', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                        document.documentElement.style.setProperty('--user_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                    <div className='modalUser_listitemTitleSub3' onDragOver={(e)=>{e.preventDefault()}}>E-mail</div>
                    
                    <div className='modalUser_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                      onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                        setWidth(variables.getPropertyValue('--user_3').slice(0,-2));
                        setTotal(variables.getPropertyValue('--user_total').slice(0,-2));}}
                      onDrag={(e)=>{document.documentElement.style.setProperty('--user_3', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                      onDragEnd={(e)=>{document.documentElement.style.setProperty('--user_3', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                        document.documentElement.style.setProperty('--user_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                    <div className='modalUser_listitemTitleSub4' onDragOver={(e)=>{e.preventDefault()}}>생년월일</div>
                    
                    <div className='modalUser_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                      onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                        setWidth(variables.getPropertyValue('--user_4').slice(0,-2));
                        setTotal(variables.getPropertyValue('--user_total').slice(0,-2));}}
                      onDrag={(e)=>{document.documentElement.style.setProperty('--user_4', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                      onDragEnd={(e)=>{document.documentElement.style.setProperty('--user_4', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                        document.documentElement.style.setProperty('--user_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                    <div className='modalUser_listitemTitleSub5' onDragOver={(e)=>{e.preventDefault()}}>전화번호</div>
                    
                    <div className='modalUser_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                      onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                        setWidth(variables.getPropertyValue('--user_5').slice(0,-2));
                        setTotal(variables.getPropertyValue('--user_total').slice(0,-2));}}
                      onDrag={(e)=>{document.documentElement.style.setProperty('--user_5', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                      onDragEnd={(e)=>{document.documentElement.style.setProperty('--user_5', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                        document.documentElement.style.setProperty('--user_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                    <div className='modalUser_listitemTitleSub6' onDragOver={(e)=>{e.preventDefault()}}>주소</div>
                  
                    <div className='modalUser_bar' draggable={true} onDragOver={(e)=>{e.preventDefault()}}
                      onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                        setWidth(variables.getPropertyValue('--user_6').slice(0,-2));
                        setTotal(variables.getPropertyValue('--user_total').slice(0,-2));}}
                      onDrag={(e)=>{document.documentElement.style.setProperty('--user_6', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                      onDragEnd={(e)=>{document.documentElement.style.setProperty('--user_6', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                        document.documentElement.style.setProperty('--user_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                  </div>
                  <div className='modalUser_listitems'>
                    {list.map((user)=>(
                      <div className={user.select? 'modalUser_listitem_selected':'modalUser_listitem'} 
                        onClick={()=>clickSelect(user.userid)} 
                        onDoubleClick={() => doubleClicked(user.userid)} key={user.email}>
                        <div className='modalUser_listitemSub1'>{user.userid}</div>
                        <div className='modalUser_listitemSub2'>{user.name}</div>
                        <div className='modalUser_listitemSub3'>{user.email}</div>
                        <div className='modalUser_listitemSub4'>{user.birth}</div>
                        <div className='modalUser_listitemSub5'>{user.phone}</div>
                        <div className='modalUser_listitemSub6'>{user.address?.replace('?', ' ')}</div>
                      </div>                  
                    ))}
                  </div>
                </div>              
              </div>    
            </div>
          
            <div className='modalUser_blank_10' />
            <div className='modalUser_storeCancel'>
              <div className='modalUser_storeButton' onClick={okClicked}>등록</div>
              <div className='modalUser_Hblank_20' />
              <div className='modalUser_storeButton' onClick={cancleClicked}>취소</div>
            </div>

          </div>
        </div>
      </div>
      ) : null}
    </div>
  );
};

export default ModalUserSelect