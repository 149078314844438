const SET = "modalState/SET";
const UPDATE = "modalState/UPDATE"

const initialState = {
    workend: false,
    control: false,
    setting: false,
    errorlog: false,
    info: false,
    userinfo: false,
    logout: false,
    shipList: false,
    shipRegister: false,
    shipEdit: false,
    shipDelete: false,
};

export const setModalState = (modalState) => ({
  type: SET,
  modalState: modalState,
});

export const updateModalState = (item, value) => ({
  type: UPDATE,
  item: item,
  value: value,
});

export default function modalState(state = initialState, action) {
  switch (action.type) {
    case SET:
      return {
        workend: action.modalState.workend,
        control: action.modalState.control,
        setting: action.modalState.setting,
        errorlog: action.modalState.errorlog,
        info: action.modalState.info,
        userinfo: action.modalState.userinfo,
        logout: action.modalState.logout,
        shipList: action.modalState.shipList,
        shipRegister: action.modalState.shipRegister,
        shipEdit: action.modalState.shipEdit,
        shipDelete: action.modalState.shipDelete,
      };
    case UPDATE:
      return {...state, [action.item]: action.value};
    default:
      return state;
  }
}