const SET = "shipsToAdd/SET";
const ADD = "shipsToAdd/ADD";

const initialState = [];

export const setShipsToAdd = (ships) => ({
  type: SET,
  ships: ships,
});

export const addShipToAdd = (ship) => ({
  type: ADD,
  ship: ship,
});

export default function shipsToAdd(state = initialState, action) {
  switch (action.type) {
    case SET:
      return action.ships;
    case ADD:
      return [...state, action.ship];
    default:
      return state;
  }
}