import {React, useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import './modalSystem.css';
import UseContextMenu from '../popupMenu/UseContextMenu';
import { ContextMenu1 } from '../popupMenu/styles';
import { BiX } from 'react-icons/bi';
import {BiAlignLeft} from 'react-icons/bi'
import {ImCheckboxChecked} from 'react-icons/im'
import {ImCheckboxUnchecked} from 'react-icons/im'
import {BiSearch} from 'react-icons/bi'
import { updateUser, setUsers, deleteUser } from '../../redux/modules/users';
import { Confirm, MyAlert } from '../popupMenu/Confirm';
import { commUpdateUserDB, commDeleteUserDB, commBackupDB, commRestoreDB, commEtcDBUpdate, commUpdateShipDB } from '../comm/CommUpdateDB';
import { ContextMenu3 } from "../../components/popupMenu/styles";
import {AiFillCaretRight, AiFillCaretLeft, AiFillStepForward, AiFillStepBackward} from 'react-icons/ai';
import {RxDividerVertical} from 'react-icons/rx';
import LoadingSpinner from './loadingSpinner';
import { updateEtc } from "../../redux/modules/etc";
import './common.scss';
import axios from 'axios';
import { updateShip } from '../../redux/modules/ships';

const ModalSystem = (props) => {
  const { open, close } = props;
  const {clicked, setClicked, points, setPoints} = UseContextMenu();
  const [request, setRequest] = useState(0);
  const [mode, setMode] = useState(0);
  const users = useSelector((state) => state.users);
  const ships = useSelector((state) => state.ships);
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [searchContent, setSearchContent] = useState("");
  const [checkDelete, setCheckDelete] = useState(false);
  const [checkInit, setCheckInit] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [userEdit, setUserEdit] = useState("");
  const [dbFileName, setDBFileName] = useState("");
  const [checkRestore, setCheckRestore] = useState(false);
  const [message1, setMessage1] = useState("");
  const [message2, setMessage2] = useState("");
  const XLSX = require( "xlsx" );
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [page, setPage] = useState(1);
  const [pageStart, setPageStart] = useState(1);
  const [pageEnd, setPageEnd] = useState(1);
  const [itemsInPage, setItemsInPage] = useState(15);
  const [searchList, setSearchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const etc = useSelector((state) => state.etc);
  const userItemsInPage = 15;
  const smsItemsInPage = 15;
  
  const setAlert = (content) => {
    setAlertContent(content);
    setAlertOpen(true);
  }

  useEffect(() => {
    if (open){
        users.forEach(user => {
          dispatch(updateUser(user.userid, 'select', false));
        });
        setSearchContent(""); 
        setIsSearchMode(false);
        setMode(0);
        setItemsInPage(userItemsInPage);
        setPage(1);
        const items = users;
        const newList = items.slice(0, itemsInPage);
        setPageEnd(items.length? (parseInt((items.length-1)/itemsInPage) + 1):1);
        setList(newList);

        setMessage1(etc.distanceContent);
        setMessage2(etc.gpsContent);

        document.documentElement.style.setProperty('--system_1', variables.getPropertyValue('--system_1_init'));
        document.documentElement.style.setProperty('--system_2', variables.getPropertyValue('--system_2_init'));
        document.documentElement.style.setProperty('--system_1_1', variables.getPropertyValue('--system_1_1_init'));
        document.documentElement.style.setProperty('--system_2_1', variables.getPropertyValue('--system_2_1_init'));
        document.documentElement.style.setProperty('--system_3', variables.getPropertyValue('--system_3_init'));
        document.documentElement.style.setProperty('--system_4', variables.getPropertyValue('--system_4_init'));
        document.documentElement.style.setProperty('--system_5', variables.getPropertyValue('--system_5_init'));
        document.documentElement.style.setProperty('--system_6', variables.getPropertyValue('--system_6_init'));
        document.documentElement.style.setProperty('--system_7', variables.getPropertyValue('--system_7_init'));
        document.documentElement.style.setProperty('--system_total', variables.getPropertyValue('--system_total_init'));
    }
    else{
      setIsSearchMode(false);
    }
    //console.log(users);
  }, [open]);

  useEffect(() => {
    if (isSearchMode){
      const items = (users.filter(user => 
        user.userid.toUpperCase().includes(searchContent.toUpperCase()) ||
        user.name.toUpperCase().includes(searchContent.toUpperCase()) ||
        user.email.toUpperCase().includes(searchContent.toUpperCase()) ||
        user.phone.includes(searchContent) ));
      const newList = items.slice((page-1)*itemsInPage, (page)*itemsInPage);
      setPageEnd(items.length? (parseInt((items.length-1)/itemsInPage) + 1):1);
      setList(newList);
      setSearchList(items);
    }
    else{
      const items = users;
      const newList = items.slice((page-1)*itemsInPage, (page)*itemsInPage);
      setPageEnd(items.length? (parseInt((items.length-1)/itemsInPage) + 1):1);
      setList(newList);
    }
  }, [users]);

  const drawPage = (curPage) => {
    var newList;
    if (isSearchMode){
      newList = searchList.slice((curPage-1)*itemsInPage, (curPage)*itemsInPage);
    }
    else{
      newList = users.slice((curPage-1)*itemsInPage, (curPage)*itemsInPage);
    }
    setList(newList);
  }

  const changeMode = (newMode) => {
    setMode(newMode);
    if (newMode === 0)
      setItemsInPage(userItemsInPage);
    else
      setItemsInPage(smsItemsInPage);
    setIsSearchMode(false);
    setSearchContent("");
    setPage(1);
    const items = users;
    items.forEach(item => {
      if (item.select)
        dispatch(updateUser(item.userid, 'select', false));
    })
    const newList = items.slice(0, (newMode === 0)? userItemsInPage:smsItemsInPage);
    setPageEnd(items.length? (parseInt((items.length-1)/((newMode === 0)? userItemsInPage:smsItemsInPage)) + 1):1);
    setList(newList);
  }
  
  useEffect(() => {
    if (request){
      setRequest(request-1);
    }
    else{
      setClicked(true);
    }
  }, [request]);

  function clickSelect(userid){
    const user = users.find(user => user.userid === userid);
    dispatch(updateUser(user.userid, 'select', !user.select));
  }
  function clickSms(userid){
    const user = users.find(user => user.userid === userid);
    const newValue = !user.sms;
    dispatch(updateUser(user.userid, 'sms', newValue));
    // user sms 업데이트
    const updateData = {userid: user.userid, sms: newValue};
    commUpdateUserDB(updateData);
  }

  const onChange = (e) => {
    setSearchContent(e.target.value);
  };

  const onChangeMessage1 = (e) => {
    setMessage1(e.target.value);
  };
  
  const onChangeMessage2 = (e) => {
    setMessage2(e.target.value);
  };
  
  const changePage = (e) => {
    setPage(e.target.value);
  }
  const pageEntered = (e) => {
    if (e.key === 'Enter') {
      if (page >= pageStart && page <= pageEnd){
        drawPage(page);
      }
    }
  }

  function search(){
    const items = (users.filter(user => 
      user.userid.toUpperCase().includes(searchContent.toUpperCase()) ||
      user.name.toUpperCase().includes(searchContent.toUpperCase()) ||
      user.email.toUpperCase().includes(searchContent.toUpperCase()) ||
      user.phone.includes(searchContent) ));
    const newList = items.slice(0, itemsInPage);
    setPageEnd(items.length? (parseInt((items.length-1)/itemsInPage) + 1):1);
    setList(newList);
    setSearchList(items);    
    setIsSearchMode(true);
    setPage(1);
  };

  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      search();
    }
  };
  
  function deleteItem() {
    setCheckDelete(false);
    const items = users.filter(user => user.select === true);
    items.forEach(user => {
      ships.filter(ship => ship.ownerID === user.userid).forEach(ship => {
        dispatch(updateShip(ship.shipNo, 'ownerID', ''));
        const updateData = {shipNo: ship.shipNo, ownerID: ''};
        commUpdateShipDB(updateData);
      });
      ships.filter(ship => ship.captainID === user.userid).forEach(ship => {
        dispatch(updateShip(ship.shipNo, 'captainID', ''));
        const updateData = {shipNo: ship.shipNo, captainID: ''};
        commUpdateShipDB(updateData);
      });

      dispatch(deleteUser(user.userid));
      commDeleteUserDB(user.userid);
    })
    setAlert("사용자를 삭제하였습니다.");
  };

  function initPassword() {
    setCheckInit(false);
    const userToInit = users.find(user => user.select === true);
    
    // 이 부분 확인요망
    const updateData = {userid: userToInit.userid, newpassword: 'oceangrid'};
    axios({
      method: "POST",
      url: 'api/auth/rp',
      data: updateData
    }).then(function(response) {      
      setAlert("비밀번호를 초기화하였습니다.");
    }).catch(error=>{
      console.log(error);
    });
  };

  function setGrade(grade){
    const user = users.find(user => user.userid === userEdit.userid);
    dispatch(updateUser(user.userid, 'isAdmin', grade? true:false));

    const updateData = {userid: user.userid, isAdmin: grade? true:false};
    commUpdateUserDB(updateData);
  }

  function getTitle(){
    switch(mode){
      case 0: return '사용자 관리';
      case 1: return 'SMS 설정';
      case 2: return '시스템 백업';
      default: return '사용자 관리';
    }
  }

  const hadleDBUploadButton = async (e) => {
    const file = e.target.files[0];
    setDBFileName(file);
  };
  const storeClicked = () => {
    setLoading(true);
    commBackupDB().then(result => {
      setLoading(false);
    });        
  }
  const restoreClicked = () => {
    if(dbFileName)
    {
      setLoading(true);
      setCheckRestore(false);
      commRestoreDB(dbFileName).then(result => {
 
        if(result.status === 200)
          setAlert("DB를 복원했습니다.");
        else
          setAlert("DB를 복원을 실패했습니다.");

        setLoading(false);
      });      
    }
  }

  function selectAllClicked(){
    if (list.length < 1)
      return;    
    const newValue = !list[0].select;
    
    list.forEach((listItem) => {
      const user = users.find((user) => (user.userid === listItem.userid));
      dispatch(updateUser(user.userid, 'select', newValue));
    });
  }

  const exelDownload =() => {
    //console.log("exelDownload");
    var results = [];
    users.map(user => {
      var result;
      result = {userid: user.userid, grade: user.isAdmin? '관리자':'일반', 
                email: user.email, birthday: user.birth,
                phone: user.phone, address: user.address?.replace('?', ' '),
                registrationDate: user.createdAt.substring(0,10)
              };
      results = [...results, result];
    });
    //console.log(results);

    const datas = XLSX.utils.json_to_sheet(results);
    datas["!cols"] = [{wpx: 150}, {wpx: 100}, {wpx: 200}, {wpx: 200}, {wpx: 200}, {wpx: 500}, {wpx: 200}];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, datas, 'users');
    XLSX.writeFile(workbook, 'users.xlsx');
  }

  function deleteClicked(){
    const items = users.filter(item => item.select === true);
    if (items.length < 1){
      setAlert("선택된 항목이 없습니다.");
      return;
    }
    setCheckDelete(true);
  }

  function initPasswordClicked(){
    const items = users.filter(item => item.select === true);
    if (items.length < 1){
      setAlert("선택된 항목이 없습니다.");
      return;
    }
    if (items.length > 1){
      setAlert("1명만 선택하시기 바랍니다.");
      return;
    }
    setCheckInit(true);
  }

  function clickDistanceSetting(){
    const newValue = !etc.distanceAlarm;
    dispatch(updateEtc('distanceAlarm', newValue));
  }
  function clickGpsErrorSetting(){
    const newValue = !etc.gpsErrorAlarm;
    dispatch(updateEtc('gpsErrorAlarm', newValue));
  }

  function okClicked(){
    dispatch(updateEtc('distanceContent', message1));
    dispatch(updateEtc('gpsContent', message2));
    
    // etc DB 업데이트
    const update = {distanceAlarm: etc.distanceAlarm, gpsErrorAlarm: etc.gpsErrorAlarm, distanceContent: message1, gpsContent: message2};
    commEtcDBUpdate(update);
  }

  const [startX, setStartX] = useState(0);
  const [width, setWidth] = useState(0);
  const [total, setTotal] = useState(0);

  let root = document.querySelector(':root');
  let variables = getComputedStyle(root); 

  // 변수 값 얻기
  

  return (
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <div className='modalSystem'>
            <div className='modalSystem_title'>
                {"시스템"}
                <BiX className='modalSystem_button1' onClick={props.close}></BiX>
            </div>
            <div className='modalSystem_content'>
                <div className='modalSystem_left'>
                    <div>
                        <div className={(mode === 0)? 'modalSystem_item_selected':'modalSystem_item_unselected'}
                             onClick={()=>{changeMode(0)}}>
                            <div>사용자 관리</div>
                            <BiAlignLeft></BiAlignLeft>
                        </div>
                        <div className={(mode === 1)? 'modalSystem_item_selected':'modalSystem_item_unselected'}
                             onClick={()=>{changeMode(1)}}>
                            <div>SMS 설정</div>
                            <BiAlignLeft></BiAlignLeft>
                        </div>
                        <div className={(mode === 2)? 'modalSystem_item_selected':'modalSystem_item_unselected'}
                             onClick={()=>{changeMode(2)}}>
                            <div>시스템 백업</div>
                            <BiAlignLeft></BiAlignLeft>
                        </div>
                    </div>
                </div>
                <div className='modalSystem_right'>
                    <div className='modalSystem_right_title'>
                        <div>{getTitle()}</div>
                    </div>
                    {(mode === 0)?
                    <div>
                        <div className='modalSystem_content'>
                            <div className='modalSystem_up'>
                                <div className='modalSystem_search'>
                                    <div style={{fontWeight:'bold'}}>{"검색"}</div>
                                    <div className='modalSystem_Hblank_10'/>
                                    <div className='modalSystem_searchBox'>
                                      <input className='searchInput' onChange={onChange}
                                        placeholder='사용자 ID, 이름, E-mail, 전화번호를 검색합니다'
                                        onKeyDown={(e) => handleOnKeyPress(e)} value={searchContent}></input>
                                      <BiSearch className='modalSystem_searchIcon' onClick={search} />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                        <div className='modalSystem_result'>
                          <div className='modalSystem_result_1'>
                            <div className='modalSystem_flex1'>
                              <div className='modalSystem_button2' onClick={deleteClicked}>삭제</div>
                              <div style={{width:'5px'}}/>
                              <div className='modalSystem_button2' onClick={initPasswordClicked}>비밀번호초기화</div>
                            </div>
                            <div>{"Total     " + (isSearchMode? searchList.length:users.length) + "명"}</div>
                          </div>
                          <div className='modalSystem_result_2'>
                  
                            <div className='modalSystem_itemTitle'>
                              <div className='modalSystem_itemTitleSub1' onDragOver={(e)=>{e.preventDefault()}} onClick={selectAllClicked}>선택</div>
                              
                              <div className='modalSystem_bar' draggable={true}
                                onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                                  setWidth(variables.getPropertyValue('--system_1').slice(0,-2));
                                  setTotal(variables.getPropertyValue('--system_total').slice(0,-2));}}
                                onDrag={(e)=>{document.documentElement.style.setProperty('--system_1', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                                onDragEnd={(e)=>{document.documentElement.style.setProperty('--system_1', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                                  document.documentElement.style.setProperty('--system_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>

                              <div className='modalSystem_itemTitleSub2' onDragOver={(e)=>{e.preventDefault()}}>ID</div>
                              
                              <div className='modalSystem_bar' draggable={true}
                                onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                                  setWidth(variables.getPropertyValue('--system_2').slice(0,-2));
                                  setTotal(variables.getPropertyValue('--system_total').slice(0,-2));}}
                                onDrag={(e)=>{document.documentElement.style.setProperty('--system_2', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                                onDragEnd={(e)=>{document.documentElement.style.setProperty('--system_2', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                                  document.documentElement.style.setProperty('--system_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>
                                
                              <div className='modalSystem_itemTitleSub1_1' onDragOver={(e)=>{e.preventDefault()}}>등급</div>
                              
                              <div className='modalSystem_bar' draggable={true}
                                onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                                  setWidth(variables.getPropertyValue('--system_1_1').slice(0,-2));
                                  setTotal(variables.getPropertyValue('--system_total').slice(0,-2));}}
                                onDrag={(e)=>{document.documentElement.style.setProperty('--system_1_1', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                                onDragEnd={(e)=>{document.documentElement.style.setProperty('--system_1_1', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                                  document.documentElement.style.setProperty('--system_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>
                                
                              <div className='modalSystem_itemTitleSub2_1' onDragOver={(e)=>{e.preventDefault()}}>이름</div>
                              
                              <div className='modalSystem_bar' draggable={true}
                                onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                                  setWidth(variables.getPropertyValue('--system_2_1').slice(0,-2));
                                  setTotal(variables.getPropertyValue('--system_total').slice(0,-2));}}
                                onDrag={(e)=>{document.documentElement.style.setProperty('--system_2_1', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                                onDragEnd={(e)=>{document.documentElement.style.setProperty('--system_2_1', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                                  document.documentElement.style.setProperty('--system_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>
                                
                              <div className='modalSystem_itemTitleSub3' onDragOver={(e)=>{e.preventDefault()}}>E-mail</div>
                              
                              <div className='modalSystem_bar' draggable={true}
                                onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                                  setWidth(variables.getPropertyValue('--system_3').slice(0,-2));
                                  setTotal(variables.getPropertyValue('--system_total').slice(0,-2));}}
                                onDrag={(e)=>{document.documentElement.style.setProperty('--system_3', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                                onDragEnd={(e)=>{document.documentElement.style.setProperty('--system_3', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                                  document.documentElement.style.setProperty('--system_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>
                                
                              <div className='modalSystem_itemTitleSub4' onDragOver={(e)=>{e.preventDefault()}}>생년월일</div>
                              
                              <div className='modalSystem_bar' draggable={true}
                                onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                                  setWidth(variables.getPropertyValue('--system_4').slice(0,-2));
                                  setTotal(variables.getPropertyValue('--system_total').slice(0,-2));}}
                                onDrag={(e)=>{document.documentElement.style.setProperty('--system_4', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                                onDragEnd={(e)=>{document.documentElement.style.setProperty('--system_4', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                                  document.documentElement.style.setProperty('--system_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>
                                
                              <div className='modalSystem_itemTitleSub5' onDragOver={(e)=>{e.preventDefault()}}>전화번호</div>
                              
                              <div className='modalSystem_bar' draggable={true}
                                onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                                  setWidth(variables.getPropertyValue('--system_5').slice(0,-2));
                                  setTotal(variables.getPropertyValue('--system_total').slice(0,-2));}}
                                onDrag={(e)=>{document.documentElement.style.setProperty('--system_5', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                                onDragEnd={(e)=>{document.documentElement.style.setProperty('--system_5', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                                  document.documentElement.style.setProperty('--system_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>
                                                              
                              <div style={{width:"var(--system_6)"}} onDragOver={(e)=>{e.preventDefault()}}>주소</div>

                              <div className='modalSystem_bar' draggable={true}
                                onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                                  setWidth(variables.getPropertyValue('--system_6').slice(0,-2));
                                  setTotal(variables.getPropertyValue('--system_total').slice(0,-2));}}
                                onDrag={(e)=>{document.documentElement.style.setProperty('--system_6', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                                onDragEnd={(e)=>{document.documentElement.style.setProperty('--system_6', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                                  document.documentElement.style.setProperty('--system_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>
                              
                              <div className='modalSystem_itemTitleSub7' onDragOver={(e)=>{e.preventDefault()}}>등록일(가입일자)</div>
                              
                              <div className='modalSystem_bar' draggable={true}
                                onDragStart={(e)=>{setStartX(e.clientX); e.dataTransfer.effectAllowed = 'move';
                                  setWidth(variables.getPropertyValue('--system_7').slice(0,-2));
                                  setTotal(variables.getPropertyValue('--system_total').slice(0,-2));}}
                                onDrag={(e)=>{document.documentElement.style.setProperty('--system_7', parseInt(e.clientX - startX) + parseInt(width) + 'px');}}
                                onDragEnd={(e)=>{document.documentElement.style.setProperty('--system_7', parseInt(e.clientX - startX) + parseInt(width) + 'px');
                                  document.documentElement.style.setProperty('--system_total', parseInt(e.clientX - startX) + parseInt(total) + 'px');}}/>
                                
                            </div>
                            <div className='modalSystem_items'>
                              {list.map((user)=>(
                                <div className='modalSystem_item' key={user.userid}>
                                  <div className='modalSystem_itemSub1'>
                                    {user.select?
                                      <ImCheckboxChecked onClick={() => clickSelect(user.userid)} /> :
                                      <ImCheckboxUnchecked onClick={() => clickSelect(user.userid)} />}</div>
                                  <div className='modalSystem_itemSub2'>{user.userid}</div>
                                  <div className='modalSystem_itemSub1_1' 
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setRequest(1);
                                      setPoints({x: e.pageX, y: e.pageY});
                                      //console.log(e.nativeEvent.offsetX);
                                      //console.log(e.nativeEvent.offsetY);
                                      //setPoints({x:e.nativeEvent.offsetX, y:e.nativeEvent.offsetY});
                                      setUserEdit(user);
                                      console.log("click"); }}>
                                    {user.isAdmin? "관리자":"일반"}</div>
                                  <div className='modalSystem_itemSub2_1'>{user.name}</div>
                                  <div className='modalSystem_itemSub3'>{user.email}</div>
                                  <div className='modalSystem_itemSub4'>{user.birth}</div>
                                  <div className='modalSystem_itemSub5'>{user.phone}</div>
                                  <div className='modalSystem_itemSub6'>{user.address?.replace('?', ' ')}</div>
                                  <div className='modalSystem_itemSub7'>{user.createdAt.substring(0,10)}</div>
                                </div>                  
                              ))}
                            </div>
                          </div>
                          
                        </div>                        

                        <div className='modalSystem_flexSpaceBetween'>
                          <div className='modalSystem_exel'>
                            <div className="modalSystem_button4" onClick={exelDownload}>엑셀저장</div>
                            <div style={{width: "30px"}}/>
                          </div>
                          
                          <div className='modalSystem_pages'>
                            <AiFillStepBackward className={(page>pageStart)? 'pageArrow':'pageArrow_Disabled'}
                              onClick={()=>{
                                if(page>pageStart){
                                  setPage(pageStart); drawPage(pageStart);
                              }}}/>
                            <AiFillCaretLeft className={(page>pageStart)? 'pageArrow':'pageArrow_Disabled'}
                              onClick={()=>{
                                if(page>pageStart){
                                  setPage(page - 1); drawPage(page - 1);
                              }}}/>
                            <RxDividerVertical/>
                            <div style={{width: "5px"}}/>
                            <input className='pageInput' onChange={changePage}
                              onKeyDown={(e) => pageEntered(e)} value={page}></input>
                            <div style={{width: "5px"}}/>
                            <div className='pagePage'>{'/ '+ pageEnd}</div>
                            <div style={{width: "5px"}}/>
                            <RxDividerVertical/>
                            <AiFillCaretRight className={(page<pageEnd)? 'pageArrow':'pageArrow_Disabled'}
                              onClick={()=>{
                                if(page<pageEnd){
                                  setPage(page + 1); drawPage(page + 1);
                              }}}/>
                            <AiFillStepForward className={(page<pageEnd)? 'pageArrow':'pageArrow_Disabled'}
                              onClick={()=>{
                                if(page<pageEnd){
                                  setPage(pageEnd); drawPage(pageEnd);
                              }}}/>
                          </div>
                        </div>
                        
                    </div>
                    : null}
                    
                    {(mode === 1)?
                    <div>
                      <div className='modalSystem_result'>
                        <div className='sms'>

                          <div className='sms_left'>
                            <div className='sms_search'>
                              <div className='sms_leftTitle'>{"사용자"}</div>
                              <div className='sms_searchBox'>
                                <input className='searchInput' onChange={onChange}
                                  placeholder='사용자 이름, 전화번호 검색'
                                  onKeyDown={(e) => handleOnKeyPress(e)} value={searchContent}></input>
                                <BiSearch className='sms_searchIcon' onClick={search} />
                              </div>                                
                            </div>
                            <div className='sms_result'>                    
                              <div className='sms_itemTitle'>
                                <div className='sms_itemTitleSub1' onClick={selectAllClicked}>선택</div>
                                <div className='sms_itemTitleSub2'>등급</div>
                                <div className='sms_itemTitleSub3'>이름</div>
                                <div className='sms_itemTitleSub4'>전화번호</div>
                              </div>
                              <div className='sms_items'>
                                {list.map((user)=>(
                                  <div className='modalSystem_item_sms' key={user.userid}>
                                    <div className='sms_itemSub1'>
                                      {user.sms?
                                        <ImCheckboxChecked onClick={() => clickSms(user.userid)} /> :
                                        <ImCheckboxUnchecked onClick={() => clickSms(user.userid)} />}</div>
                                    <div className='sms_itemSub2'>{user.isAdmin? "관리자":"일반"}</div>
                                    <div className='sms_itemSub3'>{user.name}</div>
                                    <div className='sms_itemSub4'>{user.phone}</div>
                                  </div>                  
                                ))}
                              </div>
                            </div>

                            <div className='modalSystem_flexEnd'>
                              <div className='modalSystem_pages'>
                                <AiFillStepBackward className={(page>pageStart)? 'pageArrow':'pageArrow_Disabled'}
                                  onClick={()=>{
                                    if(page>pageStart){
                                      setPage(pageStart); drawPage(pageStart);
                                  }}}/>
                                <AiFillCaretLeft className={(page>pageStart)? 'pageArrow':'pageArrow_Disabled'}
                                  onClick={()=>{
                                    if(page>pageStart){
                                      setPage(page - 1); drawPage(page - 1);
                                  }}}/>
                                <RxDividerVertical/>
                                <div style={{width: "5px"}}/>
                                <input className='pageInput' onChange={changePage}
                                  onKeyDown={(e) => pageEntered(e)} value={page}></input>
                                <div style={{width: "5px"}}/>
                                <div className='pagePage'>{'/ '+ pageEnd}</div>
                                <div style={{width: "5px"}}/>
                                <RxDividerVertical/>
                                <AiFillCaretRight className={(page<pageEnd)? 'pageArrow':'pageArrow_Disabled'}
                                  onClick={()=>{
                                    if(page<pageEnd){
                                      setPage(page + 1); drawPage(page + 1);
                                  }}}/>
                                <AiFillStepForward className={(page<pageEnd)? 'pageArrow':'pageArrow_Disabled'}
                                  onClick={()=>{
                                    if(page<pageEnd){
                                      setPage(pageEnd); drawPage(pageEnd);
                                  }}}/>
                              </div>
                            </div>
                            
                          </div>

                          <div className='sms_center'/>
                          
                          <div className='sms_right'>
                            <div className='sms_search'>
                              <div className='sms_leftTitle'>{"메시지 설정"}</div>
                              <div style={{height:"25px"}}/>
                            </div>
                            <div className='sms_rightresult'>                    
                              <div className='sms_rightItem'>
                                <div style={{display:'flex', width:'100px'}}>
                                  <div className='sms_icon'>{etc.distanceAlarm?
                                    <ImCheckboxChecked onClick={clickDistanceSetting} /> :
                                    <ImCheckboxUnchecked onClick={clickDistanceSetting} />}</div>
                                  <div style={{width:'5px'}}/>
                                  <div>위치이탈</div>
                                </div>
                                <textarea className='sms_messageInput' onChange={onChangeMessage1}
                                  placeholder='위치이탈시 전송문구 입력' value={message1}/>
                              </div>                   
                              <div className='sms_rightItem'>
                                <div style={{display:'flex', width:'100px'}}>
                                  <div className='sms_icon'>{etc.gpsErrorAlarm?
                                    <ImCheckboxChecked onClick={clickGpsErrorSetting} /> :
                                    <ImCheckboxUnchecked onClick={clickGpsErrorSetting} />}</div>
                                  <div style={{width:'5px'}}/>
                                  <div>GPS 고장</div>
                                </div>
                                <textarea className='sms_messageInput' onChange={onChangeMessage2}
                                  placeholder='GPS 고장시 전송문구 입력' value={message2}/>
                              </div>
                              <div className='modalSystem_flexRight'>
                                <div className='modalSystem_button3' onClick={okClicked}>저장</div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    : null}

                    {(mode === 2)?
                    <div>
                      <div className='modalSystem_result'>
                        <div className='sms'>

                          <div className='backup_left'>
                            <div className='sms_search'>
                              <div className='sms_leftTitle'>Backup</div>                               
                            </div>
                            <div className='sms_result'>   
                              <div className='backup_item'>
                                <div className='modalSystem_right1'>현 시스템의 데이터를 저장합니다.</div>
                                <div style={{height:'30px'}}/>
                                <div className='modalSystem_flexCenter'>
                                  <div className='modalSystem_button3' onClick={storeClicked}>저장</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='sms_center'/>
                          
                          <div className='backup_left'>
                            <div className='sms_search'>
                              <div className='sms_leftTitle'>Restore</div>
                            </div>
                            <div className='sms_result'>                    
                              <div className='backup_item'>
                                <div className='modalSystem_right1'>복원파일 시점으로 시스템을 적용합니다.</div>
                                <div style={{height:'30px'}}/>
                                <div className='modalSystem_flexLeft'>
                                  <input className='fileinput' type="file" id="input-file1" onChange={hadleDBUploadButton}/>
                                </div>
                                <div style={{height:'20px'}}/>
                                <div className='modalSystem_flexCenter'>
                                  <div className='modalSystem_button3' onClick={()=>setCheckRestore(true)}>복원</div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    : null}

                    {(mode === 3)?
                    <div>
                      <div className='modalSystem_result'>
                        <div className='modalSystem_result_3'>

                          <div className='modalSystem_leftside'>
                            <div className='modalSystem_left1'>Backup</div>
                            <div style={{height:'110px'}}/>
                            <div className='modalSystem_left1'>Restore</div>
                          </div>

                          <div className='modalSystem_rightside'>
                            <div className='modalSystem_right1'>현 시스템의 데이터를 저장합니다.</div>
                            <div style={{height:'30px'}}/>
                            <div className='modalSystem_flexLeft'>
                              <div className='modalSystem_Hblank_100'/>
                              <div className='modalSystem_button3' onClick={storeClicked}>저장</div>
                            </div>

                            <div className='modalSystem_blank_50'/>
                            <div className='modalSystem_blank_10'/>

                            <div className='modalSystem_right1'>복원파일 시점으로 시스템을 적용합니다.</div>
                            <div style={{height:'30px'}}/>
                            <div className='modalSystem_flexLeft'>
                              <div style={{width:'50px'}}/>
                              <input className='fileinput' type="file" id="input-file1" onChange={hadleDBUploadButton}/>
                              <div className='modalSystem_button3' onClick={()=>setCheckRestore(true)}>복원</div>
                            </div>
                          </div>
                
                        </div>
                      </div>
                    </div>
                    : null}
                </div>          
            </div>
        </div>
      ) : null}
      {clicked && (
        <ContextMenu3 top={points.y} left={points.x}>
          <div>
            <div className='modalSystem_ContextItems'>
              <div className='modalSystem_ContextItem' onClick={()=>setGrade(0)}>일반</div>
              <div className='modalSystem_ContextItem' onClick={()=>setGrade(1)}>관리자</div>
            </div>
          </div>    
        </ContextMenu3>
      )}
      {checkDelete && (
        <Confirm 
          content="사용자를 삭제하시겠습니까?" 
          ok={deleteItem} 
          close={()=>setCheckDelete(false)}/>
      )}
      {checkInit && (
        <Confirm 
          content="비밀번호를 초기화하시겠습니까?" 
          ok={initPassword}
          close={()=>setCheckInit(false)}/>
      )}
      {checkRestore && (
        <Confirm 
          content="정말 복원하시겠습니까?" 
          ok={restoreClicked}
          close={()=>setCheckRestore(false)}/>
      )}
      {alertOpen && (
        <MyAlert content={alertContent} ok={()=>setAlertOpen(false)}/>
      )}
      {loading && <LoadingSpinner />}
      
    </div>
  );
};

export default ModalSystem