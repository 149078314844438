const SET = "curDevice/SET";
const UPDATE = "curDevice/UPDATE"

const initialState = {
    id: "",
    no: "",
    devEui: "",
    name: "",
    description: "",
    shipNo: "",
    battery: "",
    lat: "",
    lng: "",
    state: "",
    power: "",
    workend: "",
    distanceAlarm: "",
    distance: "",
    LedOnTime: "",
    onoffPeriod: "",
    duration: "",
    commPeriod: "",
    lastComm: "",
    marker: null,
    overlay: null,
    select: false
};

export const setCurDevice = (device) => ({
  type: SET,
  device: device,
});

export const updateCurDevice = (item, value) => ({
  type: UPDATE,
  item: item,
  value: value,
});

export default function curDevice(state = initialState, action) {
  //console.log(action);
  switch (action.type) {
    case SET:
      return {...state, 
        id: action.device.id,
        no: action.device.no,
        devEui: action.device.devEui,
        name: action.device.name,
        description: action.device.description,
        shipNo: action.device.shipNo,
        battery: action.device.battery,
        lat: action.device.lat,
        lng: action.device.lng,
        state: action.device.state,
        power: action.device.power,
        workend: action.device.workend,
        distanceAlarm: action.device.distanceAlarm,
        distance: action.device.distance,
        LedOnTime: action.device.LedOnTime,
        onoffPeriod: action.device.onoffPeriod,
        duration: action.device.duration,
        commPeriod: action.device.commPeriod,
        lastComm: action.device.lastComm,
        marker: action.device.marker,
        overlay: action.device.overlay,
        select: false};
    case UPDATE:
      return {...state, [action.item]: action.value};
    default:
      return state;
  }
}