import React from 'react';
import './confirm.css';

export const Confirm = (props) => {
  return (
    <div className='confirm_bg'>
      <div className='confirm'>
          <div className='confirm_content'>
              {props.content}
          </div>
          <div className='confirm_buttons'>
              <div className='confirm_button' onClick={props.ok}>확인</div>
              <div className='confirm_button' onClick={props.close}>취소</div>
          </div>
      </div>
    </div>
  );
};

export const MyAlert = (props) => {
  const handleOnKeyPress = (e) => {
    console.log("handleOnKeyPress");
    if (e.key === 'Enter') {
      props.ok();
    }
  };

  return (
    <div className='confirm_bg'>
      <div className='confirm'>
          <div className='confirm_content'>
              {props.content}
          </div>
          <div className='confirm_buttons'>
              <div className='confirm_button' onKeyDown={(e) => handleOnKeyPress(e)} onClick={props.ok}>확인</div>
          </div>
      </div>
    </div>
  );
};