const SET = "devices/SET";
const ADD = "devices/ADD";
const UPDATE = "devices/UPDATE";
const DELETE = "devices/DELETE";

/*const initialState = [
    {id: 1, no: 1, devEui: '50f8a5fffe0e8e3a', name: '테스트1', description: '테스트용입니다.', shipNo: '090715-6482205', battery: 90, lat:35.044251, lng:129.117424, state: 0x11, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 2, no: 2, devEui: '50f8a5fffe0e8e3b', name: '테스트2', description: '테스트용입니다.', shipNo: '090715-6482205', battery: 87, lat:35.045232, lng:129.117482, state: 0x21, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 3, no: 1, devEui: '50f8a5fffe0e8e3c', name: '테스트3', description: '테스트용입니다.', shipNo: '090715-6482204', battery: 56, lat:35.051401, lng:129.110574, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 4, no: 2, devEui: '50f8a5fffe0e8e3d', name: '테스트4', description: '테스트용입니다.', shipNo: '090715-6482204', battery: 35, lat:35.050414, lng:129.110638, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 5, no: 1, devEui: '50f8a5fffe0e8e3e', name: '테스트5', description: '테스트용입니다.', shipNo: '090715-6482203', battery: 68, lat:35.041315, lng:129.121239, state: 0x10, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 6, no: 2, devEui: '50f8a5fffe0e8e3f', name: '테스트6', description: '테스트용입니다.', shipNo: '090715-6482203', battery: 95, lat:35.041172, lng:129.121359, state: 0x21, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 7, no: 3, devEui: '50f8a5fffe0e8e3g', name: '테스트7', description: '테스트용입니다.', shipNo: '090715-6482204', battery: 24, lat:35.050478, lng:129.110381, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 8, no: 4, devEui: '50f8a5fffe0e8e3h', name: '테스트8', description: '테스트용입니다.', shipNo: '090715-6482204', battery: 12, lat:35.050225, lng:129.110946, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 9, no: 3, devEui: '50f8a5fffe0e8e3i', name: '테스트9', description: '테스트용입니다.', shipNo: '090715-6482205', battery: 67, lat:35.045352, lng:129.117433, state: 0x10, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 10, no: 4, devEui: '50f8a5fffe0e8e3j', name: '테스트10', description: '테스트용입니다.', shipNo: '090715-6482205', battery: 86, lat:35.045313, lng:129.117569, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 11, no: 5, devEui: '50f8a5fffe0e8e3k', name: '테스트11', description: '테스트용입니다.', shipNo: '090715-6482204', battery: 63, lat:35.050574, lng:129.110945, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 12, no: 6, devEui: '50f8a5fffe0e8e3l', name: '테스트12', description: '테스트용입니다.', shipNo: '090715-6482204', battery: 79, lat:35.050127, lng:129.110568, state: 0x00, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 13, no: 3, devEui: '50f8a5fffe0e8e3m', name: '테스트13', description: '테스트용입니다.', shipNo: '090715-6482203', battery: 87, lat:35.041579, lng:129.121833, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 14, no: 4, devEui: '50f8a5fffe0e8e3n', name: '테스트14', description: '테스트용입니다.', shipNo: '090715-6482203', battery: 56, lat:35.041848, lng:129.121322, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 15, no: 7, devEui: '50f8a5fffe0e8e3o', name: '테스트15', description: '테스트용입니다.', shipNo: '090715-6482204', battery: 53, lat:35.050356, lng:129.110299, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 16, no: 8, devEui: '50f8a5fffe0e8e3p', name: '테스트16', description: '테스트용입니다.', shipNo: '090715-6482204', battery: 68, lat:35.050837, lng:129.110985, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 17, no: 5, devEui: '50f8a5fffe0e8e3q', name: '테스트17', description: '테스트용입니다.', shipNo: '090715-6482203', battery: 90, lat:35.041557, lng:129.121832, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 18, no: 5, devEui: '50f8a5fffe0e8e3r', name: '테스트18', description: '테스트용입니다.', shipNo: '090715-6482205', battery: 31, lat:35.045533, lng:129.117379, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 19, no: 9, devEui: '50f8a5fffe0e8e3s', name: '테스트19', description: '테스트용입니다.', shipNo: '090715-6482204', battery: 12, lat:35.050571, lng:129.110744, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 20, no: 10, devEui: '50f8a5fffe0e8e3t', name: '테스트20', description: '테스트용입니다.', shipNo: '090715-6482204', battery: 17, lat:35.050328, lng:129.110677, state: 0x00, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 21, no: 6, devEui: '50f8a5fffe0e8e3u', name: '테스트21', description: '테스트용입니다.', shipNo: '090715-6482203', battery: 47, lat:35.041215, lng:129.121894, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 22, no: 7, devEui: '50f8a5fffe0e8e3v', name: '테스트22', description: '테스트용입니다.', shipNo: '090715-6482203', battery: 43, lat:35.041788, lng:129.121359, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 23, no: 11, devEui: '50f8a5fffe0e8e3w', name: '테스트23', description: '테스트용입니다.', shipNo: '090715-6482204', battery: 87, lat:35.050943, lng:129.110522, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 24, no: 12, devEui: '50f8a5fffe0e8e3x', name: '테스트24', description: '테스트용입니다.', shipNo: '090715-6482204', battery: 93, lat:35.050472, lng:129.110787, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
    {id: 25, no: 8, devEui: '50f8a5fffe0e8e3y', name: '테스트25', description: '테스트용입니다.', shipNo: '090715-6482203', battery: 49, lat:35.041357, lng:129.121838, state: 0x01, power: 1, workend: 0, distanceAlarm: true, distance: 3, LedOnTime: 0x61c1cfd0, onoffPeriod: 1, duration: 3, commPeriod: 2, lastComm: '01-14 12:56', marker: null, overlay: null, select: false},
];*/
const initialState = null;

export const addDevices = (device) => ({
  type: SET,
  device: device,
});

export const addDevice = (device) => ({
  type: ADD,
  device: device,
});

export const updateDevice = (devEui, item, value) => ({
  type: UPDATE,
  devEui: devEui,
  item: item,
  value: value,
});

export const deleteDevice = (devEui) => ({
  type: DELETE,
  deleteID: devEui,
});

export default function devices(state = initialState, action) {
  //console.log(action);
  switch (action.type) {
    case SET:
      return action.device;
    case ADD:
      return [action.device, ...state];
    case UPDATE:
      return state.map((device)=>{
        if (device.devEui !== action.devEui){
          return device
        }
        return {...device, [action.item]: action.value}
      });
    case DELETE:
      return state.filter(device => device.devEui !== action.deleteID);
    default:
      return state;
  }
}