import {React, useState, useEffect} from 'react';
import './modalUserInfo.css';
import { BiX } from 'react-icons/bi';
import { useSelector, useDispatch } from "react-redux";
import { setLoggedInfo } from '../../redux/modules/user';
import { updateUser } from '../../redux/modules/users';
import storage from '../../lib/storage';
import { Confirm, MyAlert } from '../../components/popupMenu/Confirm';
import { commUpdateUserDB, commDeleteUserDB, commUpdateShipDB } from '../comm/CommUpdateDB';
import { logout } from '../../redux/modules/auth';
import axios from 'axios';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { updateShip } from '../../redux/modules/ships';

const ModalUserInfo = (props) => {
  const { open, close } = props;
  const users = useSelector((state) => state.users);
  const ships = useSelector((state) => state.ships);
  const dispatch = useDispatch();
  const [user, setUser] = useState();
  const [textID, setTextID] = useState();
  const [textName, setTextName] = useState();
  const [textEmail, setTextEmail] = useState();
  const [textBirth, setTextBirth] = useState();
  const [textPhone, setTextPhone] = useState("");
  const [textAddress, setTextAddress] = useState();
  const [textAddress1, setTextAddress1] = useState();
  const [textOldPass, setTextOldPass] = useState();
  const [textNewPass1, setTextNewPass1] = useState();
  const [textNewPass2, setTextNewPass2] = useState();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alert1Open, setAlert1Open] = useState(false);
  const [alert2Open, setAlert2Open] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  
  const setAlert = (content) => {
    setAlertContent(content);
    setAlertOpen(true);
  }

  useEffect(() => {
    if (props.open){
      const id = storage.get('loggedInfo').userid;
      const curUser = users.find(user => user.userid === id);
      setUser(curUser);
      const addressSplit = curUser.address.split('?');

      setTextID(curUser.userid);
      setTextName(curUser.name);
      setTextEmail(curUser.email);
      setTextBirth(curUser.birth);
      setTextPhone(curUser.phone);
      setTextAddress(addressSplit[0]);
      setTextAddress1(addressSplit[1]);
      setTextOldPass("");
      setTextNewPass1("");
      setTextNewPass2("");
    }
  }, [props.open]);

  function okClicked(){
    if (textName === ""){
      setAlert("이름이 입력되지 않았습니다.");
      return
    }
    if (textEmail === ""){
      setAlert("이메일이 입력되지 않았습니다.");
      return
    }
    if (textBirth === ""){
      setAlert("생년월일이 입력되지 않았습니다.");
      return
    }
    if (textPhone === ""){
      setAlert("전화번호가 입력되지 않았습니다.");
      return
    }
    if (textAddress === ""){
      setAlert("주소가 입력되지 않았습니다.");
      return
    }
    // 비밀번호 변경
    if (textOldPass !== "" || textNewPass1 !== "" || textNewPass2 !== ""){
      if (textOldPass === ""){
        setAlert("현재 비밀번호가 입력되지 않았습니다.");
        return
      }
      if (textNewPass1 === "" || textNewPass2 === ""){
        setAlert("신규 비밀번호가 입력되지 않았습니다.");
        return
      }
      if (textNewPass1.length < 6){
        setAlert("비밀번호를 6글자 이상으로 해주세요");
        return
      }
      if (textNewPass1 !== textNewPass2){
        setAlert("신규 비밀번호가 일치하지 않습니다.");
        return
      }
      if (textOldPass === textNewPass1){
        setAlert("신규 비밀번호가 현재 비밀번호와 동일합니다.");
        return
      }
      if (textNewPass1.length < 6)
      {
        setAlert("신규 비밀번호는 6자이상 입력해주세요.");
        return
      }

      const updateData = {userid: user.userid, originpassword: textOldPass, newpassword: textNewPass1};
      axios({
        method: "POST",
        url: 'api/auth/cp',
        data: updateData
      }).then(function(response) {
        const addressNew = textAddress + '?' + textAddress1;
        dispatch(updateUser(user.userid, 'name', textName));
        dispatch(updateUser(user.userid, 'email', textEmail));
        dispatch(updateUser(user.userid, 'birth', textBirth));
        dispatch(updateUser(user.userid, 'phone', textPhone));
        dispatch(updateUser(user.userid, 'address', addressNew));
    
        const updateData = {userid: user.userid, profile: {username: textName}, email: textEmail, birth: textBirth, phone: textPhone, address: addressNew};
        commUpdateUserDB(updateData);
        dispatch(setLoggedInfo({username: textName}));

        setAlertContent("정보가 수정되었습니다.");
        setAlert1Open(true);
      }).catch(error=>{
        console.log(error);

        setAlertContent("현재 비밀번호가 다릅니다.");
        setAlert2Open(true);
      });
      //commUpdateUserPassword({userid: user.userid, originpassword: textOldPass, newpassword: textNewPass1});
    }
    else{
      const addressNew = textAddress + '?' + textAddress1;
      dispatch(updateUser(user.userid, 'name', textName));
      dispatch(updateUser(user.userid, 'email', textEmail));
      dispatch(updateUser(user.userid, 'birth', textBirth));
      dispatch(updateUser(user.userid, 'phone', textPhone));
      dispatch(updateUser(user.userid, 'address', addressNew));
  
      const updateData = {userid: user.userid, profile: {username: textName}, email: textEmail, birth: textBirth, phone: textPhone, address: addressNew};
      commUpdateUserDB(updateData);
      
      let loggedInfo = storage.get('loggedInfo');
      loggedInfo.username = textName;
      storage.set('loggedInfo', loggedInfo);
      dispatch(setLoggedInfo(loggedInfo));

      setAlertContent("정보가 수정되었습니다.");
      setAlert1Open(true);
    }

    
    //
  };

  function cancleClicked(){
    close();
  };
  
  function exitClicked(){

    close();
  };

  const onChange1 = (e) => {
    setTextName(e.target.value);
  };
  const onChange2 = (e) => {
    setTextEmail(e.target.value);
  };
  const onChange3 = (e) => {
    let birth = "";
    if(e._d)
    {
       const b = new Date(e._d);
       birth = b.getFullYear() + "년 " + (b.getMonth() + 1) + "월 " + b.getDate() + "일";
       setTextBirth(birth);
    }
  };
  const onChange4 = (e) => {
    setTextPhone(e.target.value.replace(/[^0-9]/g, '').
      replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, ""));
    /*const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setTextPhone(e.target.value);
    }*/
  };
  useEffect(() => {
    if (textPhone.length === 10) {
      setTextPhone(textPhone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
    }
    if (textPhone.length === 13) {
      setTextPhone(textPhone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    }
  }, [textPhone]);

  const onChange5 = (e) => {
    setTextAddress(e.target.value);
  };
  const onChange5_1 = (e) => {
    setTextAddress1(e.target.value);
  };
  const onChange6 = (e) => {
    setTextOldPass(e.target.value);
  };
  const onChange7 = (e) => {
    setTextNewPass1(e.target.value);
  };
  const onChange8 = (e) => {
    setTextNewPass2(e.target.value);
  };

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setTextAddress(fullAddress);
  };
  var openPost = useDaumPostcodePopup("https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js");
  const onAdressClick = (e) => {    
    openPost({ onComplete: handleComplete, popupKey: "onlyOne" });
  };

  const withdrawal = async () => {
    ships.filter(ship => ship.ownerID === user.userid).forEach(ship => {
      dispatch(updateShip(ship.shipNo, 'ownerID', ''));
      const updateData = {shipNo: ship.shipNo, ownerID: ''};
      commUpdateShipDB(updateData);
    });
    ships.filter(ship => ship.captainID === user.userid).forEach(ship => {
      dispatch(updateShip(ship.shipNo, 'captainID', ''));
      const updateData = {shipNo: ship.shipNo, captainID: ''};
      commUpdateShipDB(updateData);
    });

    commDeleteUserDB(user.userid);
    setConfirmDeleteOpen(false);
    exitClicked();

    try {
      await logout();
    } catch (e) {
      console.log(e);
    }

    storage.remove('loggedInfo');
    window.location.href = '/';
  };

  return (
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <div className='modalUserInfo'>
          <div className='modalUserInfo_title'>
              {"회원정보수정"}
              <BiX className='modalUserInfo_button1' onClick={close}></BiX>
          </div>

          <div className='modalUserInfo_content'>
            <div className='modalUserInfo_item'>
              <div className='modalUserInfo_itemLeft'>아이디</div>
              <input className='modalUserInfo_input1' value={textID} disabled={true}/>
            </div>
            <div className='modalUserInfo_item'>
              <div className='modalUserInfo_itemLeft'>이름</div>
              <input className='modalUserInfo_input1' onChange={onChange1} value={textName}/>
            </div>
            <div className='modalUserInfo_item'>
              <div className='modalUserInfo_itemLeft'>이메일</div>
              <input className='modalUserInfo_input1' onChange={onChange2} value={textEmail}/>
            </div>
            <div className='modalUserInfo_item'>
              <div className='modalUserInfo_itemLeft'>생년월일</div>
              <Datetime onChange={(date) => {onChange3(date);}}
                renderInput={(props, openCalendar) => (
                  <input {...props} value={textBirth} onClick={openCalendar} className='modalUserInfo_input1'/> 
                )} 
                dateFormat='YYYY년 MM월 DD일' timeFormat={false} closeOnSelect={true}/>
            </div>
            <div className='modalUserInfo_item'>
              <div className='modalUserInfo_itemLeft'>
                <div>전화번호</div>
              </div>
              <input className='modalUserInfo_input1' type="text" onChange={onChange4} value={textPhone} />
            </div>
            <div className='modalUserInfo_item'>
              <div className='modalUserInfo_itemLeft'>주소</div>
              <input className='modalUserInfo_input1' onClick={onAdressClick} onChange={onChange5} value={textAddress}/>
              <div style={{height:'3px'}}/>
              <input className='modalUserInfo_input1' placeholder='상세주소' onChange={onChange5_1} value={textAddress1}/>
            </div>

            <div className='modalUserInfo_item_pass'>
              <div className='modalUserInfo_itemLeft'>현재 비밀번호</div>
              <input className='modalUserInfo_input1' onChange={onChange6} value={textOldPass} type="password"/>
            </div>
            <div className='modalUserInfo_item_pass'>
              <div className='modalUserInfo_itemLeft'>신규 비밀번호</div>
              <input className='modalUserInfo_input1' placeholder='6자리이상' onChange={onChange7} value={textNewPass1} type="password"/>
            </div>
            <div className='modalUserInfo_item_pass'>
              <div className='modalUserInfo_itemLeft'>비밀번호 확인</div>
              <input className='modalUserInfo_input1' placeholder='6자리이상' onChange={onChange8} value={textNewPass2} type="password"/>
            </div>  
          
            <div className='modalUserInfo_blank_10' />
            
            <div className='modalUserInfo_storeButton' onClick={okClicked}>정보수정</div>
            <div className='modalUserInfo_exit' onClick={()=>setConfirmDeleteOpen(true)}>탈퇴하기</div>
          </div>
          
          <div className='modalUserInfo_blank_20' />
        </div>
      ) : null}
      {confirmDeleteOpen && (
        <Confirm content={"정말 탈퇴하시겠습니까?"} ok={withdrawal} 
          close={()=>setConfirmDeleteOpen(false)}/>
      )}
      {alertOpen && (
        <MyAlert content={alertContent} ok={()=>setAlertOpen(false)}/>
      )}
      {alert1Open && (
        <MyAlert content={alertContent} ok={()=>{setAlert1Open(false); close();}}/>
      )}
      {alert2Open && (
        <MyAlert content={alertContent} ok={()=>{setAlert2Open(false)}}/>
      )}
    </div>
  );
};

export default ModalUserInfo