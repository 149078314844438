import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const WeatherImgDiv = styled.div`
  width: 5%;
  user-select: none;
  text-align: center;

  img {
    width: 40px;
  }
`;

const WeatherTextDiv = styled.div`
  width: 10%;
  color: white;
  text-align: center;
  text-align: left;
  font-size: 1.15rem;
  font-weight: 500;
`;

const positionOptions = {
    maximumAge: 0,
    timeout: 5000,
    enableHighAccuracy: true,
  };

const useCurrentLocation = (options = {}) => {
      const [location, setLocation] = useState();
      const [error, setError] = useState();
    
      const handleSuccess = (location) => {
        const { latitude, longitude } = location.coords;
        setLocation({ latitude, longitude });
      };
    
      const handleError = () => {
        setError("Local navigation failed.");
      };
    
      useEffect(() => {
        if (!navigator.geolocation) {
          setError("Geolocation is not supported.");
          return;
        }
    
        navigator.geolocation.getCurrentPosition(
          handleSuccess,
          handleError,
          positionOptions
        );
      }, [positionOptions]);
    
      return { location, error };
    };

function Weather() {
    const { location, error } = useCurrentLocation(positionOptions);
    const [city, setCity] = useState("");
    const [weather, setWeather] = useState("");
    const [temp, setTemp] = useState("");

    useEffect(() => {
        if (error) {
          return console.log("error");
        }
        
        if (location) {
          const weather_KEY = "2af12d14bbc063323f5fc88a90670c8c";
          
          axios
            .get(
              `https://api.openweathermap.org/data/2.5/weather?lat=${location.latitude}&lon=${location.longitude}&appid=${weather_KEY}&units=metric&lang=kr`
            )
            .then((response) => {
              console.log(response);
              setCity(response.data.name);
              setWeather(response.data.weather[0].icon);
              setTemp(`${response.data.main.temp}°C`);
            })
            .catch(() => {
              alert("Local navigation failed.");
            });
        }
    }, [location]);

    return (
        <>
          {!city === false ? (
            <>
            <WeatherImgDiv>
              <img src={`http://openweathermap.org/img/wn/${weather}.png`}></img>
            </WeatherImgDiv>
            <WeatherTextDiv>
              {temp} <span>{city}</span>
            </WeatherTextDiv>
            </>
          ) : (<p></p>
            //<CostumSpin tip={"Finding your location.."} />
          )}
        </>
      );
}
    
export default Weather;