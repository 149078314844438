import axios from 'axios';

const accessToken = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6ImEyYjM2YTY1LTAzMDAtNGFkNi1iZWI1LTZhMWRhNDFlOWFlMyIsInR5cCI6ImtleSJ9.EmPeCW5VV9QqXiLgWsCLkjg5GLktLrgu8NHGBpXpdn0";
const gateways = "/gateways";
const devices = "/devices";
const gateway1 = "ac1f09fffe0b8440";

function fillZero(str){
  return (str.length > 1)? str : '0'+str;
}

export function commGetDevices()
{
  axios({
    method: "GET",
    url: '/api/auth/device'
  })
  .then(function(response) {
    console.log(response);
    alert(response.data);
  }).catch(error=>{
    console.log(error);
    alert(error);
  });
}

export function getCommLog(shipInfo, devEui, startDate, endDate, msgType)
{
  let makeUrl = '/api/devices/log?';

  if(shipInfo)
    makeUrl += '&shipInfo=' + shipInfo;
  if(devEui)
    makeUrl += '&devEui=' + devEui;
  if(msgType)
    makeUrl += '&msgType=' + msgType;
  if(startDate)
  {
    const sd = new Date(startDate);
    const csd = sd.getFullYear() +
    '-' + ( (sd.getMonth()+1) < 9 ? "0" + (sd.getMonth()+1) : (sd.getMonth()+1) )+
    '-' + ( (sd.getDate()) < 9 ? "0" + (sd.getDate()) : (sd.getDate()) )+
    'T' + ( (sd.getHours()) < 9 ? "0" + (sd.getHours()) : (sd.getHours()) )+
    ':' + ( (sd.getMinutes()) < 9 ? "0" + (sd.getMinutes()) : (sd.getMinutes()) );
    makeUrl += '&startDate=' + csd;
  }
  if(endDate)
  {
    const sd = new Date(endDate);
    const csd = sd.getFullYear() +
    '-' + ( (sd.getMonth()+1) < 9 ? "0" + (sd.getMonth()+1) : (sd.getMonth()+1) )+
    '-' + ( (sd.getDate()) < 9 ? "0" + (sd.getDate()) : (sd.getDate()) )+
    'T' + ( (sd.getHours()) < 9 ? "0" + (sd.getHours()) : (sd.getHours()) )+
    ':' + ( (sd.getMinutes()) < 9 ? "0" + (sd.getMinutes()) : (sd.getMinutes()) );
    makeUrl += '&endDate=' + csd;
  }
  //console.log(makeUrl);
  return makeUrl;
  
}

export function saveGatewayLog(gatewayLog)
{
  axios({
    method: "POST",
    url: 'api/devices/save',
    data: gatewayLog.data
  }).then(function(response) {
    console.log(response);
  }).catch(error=>{
    console.log(error);
    alert(error);
  });
}

export function commGetGateway()
{
  axios({
    method: "GET",
    url: gateways+'/'+gateway1,
    headers: {
      "Authorization": accessToken,
    },
  })
  .then(function(response) {
    console.log(response);
    saveGatewayLog(response);
    return response.data;
    //alert("gateway : " + response.data.gateway.gatewayId + "\naltitude : " + response.data.gateway.location.altitude + "\nlatitude : " + response.data.gateway.location.latitude + "\nlongitude : " + response.data.gateway.location.longitude);
  }).catch(error=>{
    console.log(error);
    alert(error);
  });
}

export function commGatewayCheck(gatewayID)
{
  return {
    method: "GET",
    url: gateways+'/'+gatewayID,
    headers: {
      "Authorization": accessToken,
    }
  }
}

export function commRegisterGateway(gateway)
{
  return {
    method: "POST",
    url: gateways,
    headers: {
      "Authorization": accessToken,
    },
    data: {
      "gateway": {
        "description": gateway.description,
        "gatewayId": gateway.id,
        "location": {
          "accuracy": 0,
          "altitude": 0,
          "latitude": 0,
          "longitude": 0,
          "source": "UNKNOWN"
        },
        "metadata": {
          "additionalProp1": "string",
          "additionalProp2": "string",
          "additionalProp3": "string"
        },
        "name": gateway.name,
        "statsInterval": 30,
        "tags": {
          "additionalProp1": "string",
          "additionalProp2": "string",
          "additionalProp3": "string"
        },
        "tenantId": "52f14cd4-c6f1-4fbd-8f87-4025e1d49242"
      }
    }
  }
}

export function commUpdateGateway(gateway)
{
  return {
    method: "PUT",
    url: gateways+'/'+gateway.id,
    headers: {
      "Authorization": accessToken,
    },
    data: {
      "gateway": {
        "description": gateway.description,
        "location": {
          "accuracy": 0,
          "altitude": 0,
          "latitude": 0,
          "longitude": 0,
          "source": "UNKNOWN"
        },
        "metadata": {
          "additionalProp1": "string",
          "additionalProp2": "string",
          "additionalProp3": "string"
        },
        "name": gateway.name,
        "tags": {
          "additionalProp1": "string",
          "additionalProp2": "string",
          "additionalProp3": "string"
        },
        "tenantId": "52f14cd4-c6f1-4fbd-8f87-4025e1d49242"
      }
    }
  }
}

export function commDeleteGateway(gatewayID)
{
  return {
    method: "DELETE",
    url: gateways+'/'+gatewayID,
    headers: {
      "Authorization": accessToken,
    }
  }
}

export function commRegisterDevice(device)
{
  return {
    method: "POST",
    url: devices,
    headers: {
      "Authorization": accessToken,
    },
    data: {
      "device": {
        "applicationId": "d143a411-a09d-4bc4-ab90-018525a1e60b",
        "description": device.description,
        "devEui": device.devEui,
        "deviceProfileId": "8b70d3e5-657f-4004-9d06-55daeddb2ca0",
        "isDisabled": false,
        "name": device.name,
        "skipFcntCheck": true,
        "tags": {
          "additionalProp1": "string",
          "additionalProp2": "string",
          "additionalProp3": "string"
        },
        "variables": {
          "additionalProp1": "string",
          "additionalProp2": "string",
          "additionalProp3": "string"
        }
      }
    }
  }
}

export function commUpdateDevice(device)
{
  return {
    method: "PUT",
    url: devices+'/'+device.devEui,
    headers: {
      "Authorization": accessToken,
    },
    data: {
      "device": {
        "applicationId": "013b7a5d-6d72-4b5b-9bf8-b65f8e37c2b4",
        "description": device.description,
        "deviceProfileId": "04bcd175-6264-4665-82d3-b0558ba2c75e",
        "isDisabled": false,
        "name": device.name,
        "skipFcntCheck": true,
        "tags": {
          "additionalProp1": "string",
          "additionalProp2": "string",
          "additionalProp3": "string"
        },
        "variables": {
          "additionalProp1": "string",
          "additionalProp2": "string",
          "additionalProp3": "string"
        }
      }
    }
  }
}

export function commDeleteDevice(devEui)
{
  return {
    method: "DELETE",
    url: devices+'/'+devEui,
    headers: {
      "Authorization": accessToken,
    }
  }
}

export function commActivateDevice(device)
{
  return {
    method: "POST",
    url: devices+'/'+device.devEui+'/activate',
    headers: {
      "Authorization": accessToken,
    },
    data: {
      "deviceActivation": {
        "aFCntDown": 0,
        "appSKey": device.appskey,
        "devAddr": device.devEui.substr(8,8),
        "fCntUp": 0,
        "fNwkSIntKey": device.nwkskey,
        "nFCntDown": 0,
        "nwkSEncKey": device.nwkskey,
        "sNwkSIntKey": device.nwkskey,
      }
    }
  }
}

export function commDeleteQueue(devEui)
{
  return {
    method: "DELETE",
    url: devices+'/'+devEui+'/queue',
    headers: {
      "Authorization": accessToken,
    },
  }
}

export function commSendToDevice(devEui, data)
{
  return {
    method: "POST",
    url: devices+'/'+devEui+'/queue',
    headers: {
      "Authorization": accessToken,
    },
    data: {
      "queueItem": {
        "confirmed": false,
        "fCntDown": 0,
        "fPort": 220,
        "id": "",
        "isPending": false,
        "object" : {"data" : data}
      }
    }
  }
}

export function makeDeviceData(workEnd, distance, led, onoffPeriod, duration, commPeriod)
{
  var d1 = fillZero((1).toString(16));
  var d2 = fillZero((workEnd? '1':'0').toString(16))
  var d3 = fillZero(distance.toString(16))
  var d4 = fillZero((led? '1':'0').toString(16))
  var d5 = fillZero(onoffPeriod.toString(16))
  var d6 = fillZero(duration.toString(16))
  var d7 = fillZero(commPeriod.toString(16))
  
  const result = d1+d2+d3+d4+d5+d6+d7;
  return (result)
}

export function getCurTime()
{
  var today = new Date();
  //console.log(today);
  var option1 = { month: "2-digit", day: "2-digit" };
  var date = today.toLocaleDateString("ko-KR", option1);
  var time = date.substring(0,2) + '-' + date.substring(4,6) + ' ' + String(today.getHours()).padStart(2,"0") + ':' + String(today.getMinutes()).padStart(2,"0");
  //console.log(time);
  return time;
}

export function isNew(db, memory)
{
  if (db.substring(0,2) > memory.substring(0,2))  return true;
  else if (db.substring(0,2) < memory.substring(0,2))  return false;
  else{
    if (db.substring(3,5) > memory.substring(3,5))  return true;
    else if (db.substring(3,5) < memory.substring(3,5))  return false;
    else {
      if (db.substring(6,8) > memory.substring(6,8))  return true;
      else if (db.substring(6,8) < memory.substring(6,8))  return false;
      else {
        if (db.substring(9) >= memory.substring(9))  return true;
        else {
          return false;
        }
      }
    }
  }
}
