import React, { Component } from 'react';
import { AuthContent, InputWithLabel, AuthButton, RightAlignedLink, AuthError, Kakao_Auth } from '../../components/Auth';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as authActions from '../../redux/modules/auth';
import * as userActions from '../../redux/modules/user';
import storage from '../../lib/storage';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import withRouter from '../Base/withRouter';

class Login extends Component {
    componentWillUnmount() {
        const { AuthActions } = this.props;
        AuthActions.initializeForm('login')
    }

    componentDidMount() {
        const { location, search } = this.props;
        //const query = queryString.parse(location.search);
        const query = queryString.parse(search.search);

        if(query.expired !== undefined) {
            this.setError('세션에 만료되었습니다. 다시 로그인하세요.')
        }
    }

    handleChange = (e) => {
        const { AuthActions } = this.props;
        const { name, value } = e.target;

        AuthActions.changeInput({
            name,
            value,
            form: 'login'
        });
    }

    handleLocalLogin = async () => {     
        const { form, AuthActions, UserActions, history, navigate } = this.props;
        const { userid, password } = form.toJS();

        try {
            AuthActions.setError({
                form: 'login',
                message: null
            });

            await AuthActions.localLogin({userid, password});
            const loggedInfo = this.props.result.toJS();

            UserActions.setLoggedInfo(loggedInfo);
            //history.push('/');
            storage.set('loggedInfo', loggedInfo);
            navigate('/control');
        } catch (e) {
            console.log(e);
            this.setError('잘못된 계정정보입니다.');
        }
    }

    setError = (message) => {
        const { AuthActions } = this.props;
        AuthActions.setError({
            form: 'login',
            message
        });
        return false;
    }

    render() {
        const { userid, password } = this.props.form.toJS(); // form 에서 email 과 password 값을 읽어옴
        const { handleChange, handleLocalLogin } = this;
        const { error } = this.props;

        return (
            <AuthContent title="로그인">
                <InputWithLabel 
                    label="아이디" 
                    name="userid" 
                    placeholder="아이디" 
                    value={userid} 
                    onChange={handleChange}
                />
                <InputWithLabel 
                    label="비밀번호" 
                    name="password" 
                    placeholder="비밀번호" 
                    type="password" 
                    value={password} 
                    onChange={handleChange}
                    onKeyPress={(e)=>{if( e.charCode===13 ){handleLocalLogin();}}}
                />
                {
                    error && <AuthError>{error}</AuthError>
                }
                <AuthButton onClick={handleLocalLogin} >로그인</AuthButton>
                <RightAlignedLink to="/register">회원가입</RightAlignedLink>
            </AuthContent>
        );
    }
}

export default connect(
    (state) => ({
        form: state.auth.getIn(['login', 'form']),
        error: state.auth.getIn(['login', 'error']),
        result: state.auth.get('result')
    }),
    (dispatch) => ({
        AuthActions: bindActionCreators(authActions, dispatch),
        UserActions: bindActionCreators(userActions, dispatch)
    })
)(withRouter(Login));