import React from "react";
import styled from 'styled-components';
import oc from 'open-color';
//import DaumPostcode from "react-daum-postcode";
import Postcode from './AddressPopup';

// 두개가 함께 있을땐 상단 (그 사이) 에 여백을 준다
const Wrapper = styled.div`
    & + & {
        margin-top: 1.0rem;
    }
`;

const Label = styled.div`
    font-size: 1rem;
    color: ${oc.gray[6]};
    margin-top: 0.8rem;
    margin-bottom: 0.25rem;
`;

const Input = styled.input`
    width: 100%;
    border: 1px solid ${oc.gray[3]};
    outline: none;
    border-radius: 0px;
    line-height: 2.0rem;
    font-size: 1.0rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 1.0rem;
    ::placeholder {
        color: ${oc.gray[3]};
`;

const handleClick = (e, vr) => {
  //if(vr.state.isDaumPost === true)
  //  vr.setState({ isDaumPost: false });
  //else
    vr.setState({ isDaumPost: true });
};

const AddressWithLabel = ({label, ...rest}) => (
  <Wrapper>    
    <Label>{label}</Label>
    <Postcode prp={rest} /><Input placeholder="상세주소" name={rest.name2} value={rest.value2} onChange={rest.onChange2}/>  
  </Wrapper>
);
/*
<div>      
      <button onClick={(e)=>{handleClick(e, rest.vr)}}>주소 검색</button>
      {rest.vr.state.isDaumPost &&  
        <Postcode/>
      }
      <input type="text" value={rest.value} onChange={()=>{rest.value=""; rest.onChange();}} placeholder="주소" />
    </div>
*/
export default AddressWithLabel;