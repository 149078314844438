const SET = "myAlert/SET";

const initialState = {
    on: false,
    content: ''
};

export const setMyAlert = (on, content) => ({
  type: SET,
  on: on,
  content: content
});

export default function myAlert(state = initialState, action) {
  switch (action.type) {
    case SET:
      return {
        on: action.on,
        content: action.content,
      };
    default:
      return state;
  }
}