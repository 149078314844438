import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as baseActions from '../redux/modules/base';
import { AuthWrapper } from '../components/Auth';
import { Routes, Route } from 'react-router-dom';
import { Login, Register, SocialAuth } from '../containers/Auth';

class Auth extends Component {
    //페이지에 진입 할 때 헤더를 비활성화
    UNSAFE_componentWillMount() {
       this.props.BaseActions.setHeaderVisibility(false);
    }

    //페이지에서 벗어 날 때 다시 활성화
    componentWillUnmount() {
       this.props.BaseActions.setHeaderVisibility(true);
    }
    
    render() {
        return (
            <AuthWrapper>
                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>   
                    <Route path="/auth" element={<SocialAuth/>}/>                 
                </Routes>                
            </AuthWrapper>
        );
    }
}

export default connect(
    (state) => ({

    }),
    (dispatch) => ({
        BaseActions: bindActionCreators(baseActions, dispatch)
    })
)(Auth);