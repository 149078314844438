import {React, useState, useEffect} from 'react';
import './searchResult.css';
import { useSelector, useDispatch } from "react-redux";
import { setCurShip } from '../../redux/modules/curShip';
import { setShips } from '../../redux/modules/ships';
import { setCurDevice, updateCurDevice } from '../../redux/modules/curDevice';
import { setCurDevInfo } from '../../redux/modules/curDevInfo';
import axios from 'axios';
import { isNew } from '../comm/Comm';
import { updateDevice } from "../../redux/modules/devices";
import { setCurDevices } from '../../redux/modules/curDevices';

const SearchResult = (props) => {
    const { open, close } = props;
    const [list, setList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const dispatch = useDispatch();
    const ships = useSelector((state) => state.ships);
    const devices = useSelector((state) => state.devices);
    const curShip = useSelector((state) => state.curShip);
    
    useEffect(() => {
        var newList;
        switch(props.searchItem){
            case 1:
                newList = devices.filter(device => device.devEui.toUpperCase().includes(props.searchContent.toUpperCase()));
                break;
            case 2:
                newList = ships.filter(ship => ship.shipName.toUpperCase().includes(props.searchContent.toUpperCase()));
                break;
            case 3:
                newList = ships.filter(ship => ship.shipNo.toUpperCase().includes(props.searchContent.toUpperCase()));
                break;
            case 4:
                newList = ships.filter(ship => ship.gatewayID.toUpperCase().includes(props.searchContent.toUpperCase()));
                break;
            default:
                newList = [];
                break;
        }
        setList(newList);
        //console.log('props.searchItem '+props.searchItem);
        //console.log('props.searchContent '+props.searchContent);
        //console.log(newList);
    }, [props.searchContent]);
  
    function UpdateDeviceFromDB(devEui, data)
    {
        dispatch(updateDevice(devEui, 'battery', data.battery));
        dispatch(updateDevice(devEui, 'lat', data.lat));
        dispatch(updateDevice(devEui, 'lng', data.lng));
        dispatch(updateDevice(devEui, 'state', data.state));
        dispatch(updateDevice(devEui, 'power', data.power));
        dispatch(updateDevice(devEui, 'workend', data.workend));
        dispatch(updateDevice(devEui, 'distanceAlarm', data.distanceAlarm));
        dispatch(updateDevice(devEui, 'distance', data.distance));
        dispatch(updateDevice(devEui, 'LedOnTime', data.LedOnTime));
        dispatch(updateDevice(devEui, 'onoffPeriod', data.onoffPeriod));
        dispatch(updateDevice(devEui, 'duration', data.duration));
        dispatch(updateDevice(devEui, 'commPeriod', data.commPeriod));
        dispatch(updateDevice(devEui, 'lastComm', data.lastComm));
        
        dispatch(updateCurDevice('battery', data.battery));
        dispatch(updateCurDevice('lat', data.lat));
        dispatch(updateCurDevice('lng', data.lng));
        dispatch(updateCurDevice('state', data.state));
        dispatch(updateCurDevice('power', data.power));
        dispatch(updateCurDevice('workend', data.workend));
        dispatch(updateCurDevice('distanceAlarm', data.distanceAlarm));
        dispatch(updateCurDevice('distance', data.distance));
        dispatch(updateCurDevice('LedOnTime', data.LedOnTime));
        dispatch(updateCurDevice('onoffPeriod', data.onoffPeriod));
        dispatch(updateCurDevice('duration', data.duration));
        dispatch(updateCurDevice('commPeriod', data.commPeriod));
        dispatch(updateCurDevice('lastComm', data.lastComm));
    }
    function clickSelect(item){
        if (props.searchItem === 1){
            const newList = list.map((device) =>
                device.devEui === item.devEui? {...device, select: true} : {...device, select: false});
            setList(newList);

            const ship = ships.find(ship => ship.shipNo === item.shipNo);
            if (ship.select === false){
                const newShips = ships.map((ship) =>
                    ship.shipNo === ship.shipNo? {...ship, select: true} : {...ship, select: false});
                dispatch(setShips(newShips));
            }
            
            const devInfo = {isShip: false, shipNo: item.shipNo, devEui: item.devEui, fromSide: true};
            dispatch(setCurDevInfo(devInfo));
            dispatch(setCurDevice(item));
            dispatch(setCurShip(ship));

            // 선택된 device 정보를 DB에서 업데이트
            axios({
                method: "GET",
                url: '/api/devicedb/dev?&devEui=' + item.devEui,
            })
            .then(function(response) {
                console.log(response);
                const memoryLastComm = item.lastComm;
                const dbLastComm = response.data[0].lastComm;
                if (isNew(dbLastComm, memoryLastComm)){
                    UpdateDeviceFromDB(item.devEui, response.data[0]);
                }
            }).catch(error=>{
                console.log(error);
            });
            
            // 선택된 device의 어선이 이전 어선과 다를때 현재 어선에 소속된 모든 device의 정보를 DB에서 업데이트
            if (item.shipNo !== curShip.shipNo){
                const newShip = ships.find(ship => ship.shipNo === item.shipNo);
                if (newShip !== null || newShip !== undefined){
                    dispatch(setCurShip(newShip));
                    dispatch(setCurDevices(devices, newShip.shipNo));

                    devices.filter(device => device.shipNo === newShip.shipNo).map(device => {
                        axios({
                            method: "GET",
                            url: '/api/devicedb/dev?&devEui=' + device.devEui,
                        })
                        .then(function(response) {
                            console.log(response);
                            const memoryLastComm = device.lastComm;
                            const dbLastComm = response.data[0].lastComm;
                            if (isNew(dbLastComm, memoryLastComm)){
                                UpdateDeviceFromDB(device.devEui, response.data[0]);
                            }
                        }).catch(error=>{
                            console.log(error);
                        });
                    })
                }
            }
        }
        else{
            const newList = list.map((ship) =>
                ship.shipNo === item.shipNo? {...ship, select: true} : {...ship, select: false});
            setList(newList);

            const newShips = ships.map((ship) =>
                ship.shipNo === item.shipNo? {...ship, select: true} : {...ship, select: false});
            dispatch(setShips(newShips));

            const devInfo = {isShip: true, shipNo: item.shipNo, devEui: null, fromSide: true};
            dispatch(setCurDevInfo(devInfo));
            dispatch(setCurShip(item));
            dispatch(setCurDevices(devices, item.shipNo));

            devices.filter(device => device.shipNo === item.shipNo).map(device => {
                axios({
                    method: "GET",
                    url: '/api/devicedb/dev?&devEui=' + device.devEui,
                })
                .then(function(response) {
                    console.log(response);
                    const memoryLastComm = device.lastComm;
                    const dbLastComm = response.data[0].lastComm;
                    if (isNew(dbLastComm, memoryLastComm)){
                        UpdateDeviceFromDB(device.devEui, response.data[0]);
                    }
                }).catch(error=>{
                    console.log(error);
                });
            })
        }
        props.close();
    }

  
    function itemClicked(){
      
    };
  
    return (
        <div className='search'>
            <div className='search_title'>
                <div>{(props.searchItem === 1)? '어망':'어선'}</div>
                <div>{list.length + '건'}</div>
            </div>
            <div className='search_lists'>
                {(props.searchItem === 1)? 
                    (list.map(device => (
                        <div className={device.select? 'search_list_selected':'search_list'} onClick={()=>clickSelect(device)}
                         key={device.devEui}>{device.devEui.toUpperCase()}</div>
                    ))) :
                    (list.map(ship => (
                        <div className={ship.select? 'search_list_selected':'search_list'} onClick={()=>clickSelect(ship)}
                         key={ship.shipNo}>{ship.shipName+' ('+ship.shipNo+')'}</div>
                    )))
                }
            </div>
            <div className='search_end'>
                <div className='search_button' onClick={close}>닫기</div>
            </div>
        </div>
    )
}

export default SearchResult