import styled, { css } from "styled-components";

export const ContextMenu = styled.div`
  position: absolute;
  width: 220px;
  color: #000000;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 2px solid #dee2e6;
  font-size: .8rem;
  z-index: 3;
  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}
`;

export const ContextMenu1 = styled.div`
  position: absolute;
  width: 120px;
  height: 200px;
  overflow: auto;
  color: #000000;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 2px solid #dee2e6;
  z-index: 3;
  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}
`;

export const ContextMenu2 = styled.div`
  position: absolute;
  width: 110px;
  color: #000000;
  background-color: #FFFFFF;
  z-index: 3;
  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}
`;

export const ContextMenu3 = styled.div`
  position: absolute;
  width: 80px;
  color: #000000;
  background-color: #FFFFFF;
  z-index: 3;
  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}
`;