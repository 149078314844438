const SET = "curUser/SET";
const UPDATE = "curUser/UPDATE"

const initialState = {
    userid: "",
    name: "",
    email: "",
    isAdmin: false,
};

export const setCurUser = (user) => ({
  type: SET,
  user: user,
});

export const updateCurUser = (item, value) => ({
  type: UPDATE,
  item: item,
  value: value,
});

export default function curUser(state = initialState, action) {
  switch (action.type) {
    case SET:
      return {
        userid: action.user.userid,
        name: action.user.name,
        email: action.user.email,
        isAdmin: action.user.isAdmin,
      };
    case UPDATE:
      return {...state, [action.item]: action.value};
    default:
      return state;
  }
}