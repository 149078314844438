import {React, useState, useEffect} from 'react';

const UseContextMenu = () => {
    const [clicked, setClicked] = useState(false);
    const [points, setPoints] = useState({x: 0, y: 0});
    
    useEffect(() => {
      const handleClick = () => {
        setClicked(false);
        //console.log("click -> false");
      }
      document.addEventListener("click", handleClick);
      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, []);
    
    return {
      clicked,
      setClicked,
      points,
      setPoints,
    };
};

export default UseContextMenu