import React, { useEffect } from 'react';
//import Kakao from 'react-kakao-login';
import KakaoLogin from 'react-kakao-login';
const axios = require("axios");

const { Kakao } = window;

function Kakao_Auth() {
    useEffect(() => {
        if (typeof window !== "undefined") {
          Kakao.init("5890b5ca145901f90fbcbd3c18b6795f");
        }
      }, []);

    const responseKakao = (response) => {
      console.log(response);
      // response에서 access_token을 추출하여 백엔드로 전달합니다.
      Kakao.API.request({
        url: '/v2/user/me',
        success: function (response) {
          console.log(response);
          const { id, email } = response.kakao_account;
          // 여기서 기존에 가입된 사용자와 연동하는 작업을 수행합니다.
        },
        fail: function (error) {
          console.log(error);
        },
      });
    };
    
    return (
      <div>
        <KakaoLogin
          jsKey={"5890b5ca145901f90fbcbd3c18b6795f"}
          onSuccess={responseKakao}
          onFailure={responseKakao}
          isPopup={false}
          redirectUri={"/auth"}
        />
      </div>
    );
  }

export default Kakao_Auth;

/*import styled from 'styled-components';

const CLIENT_ID = 'c6c06005d8f5ca1e94af6093087dbebf';
const REDIRECT_URI = 'http://localhost:3000/auth'
const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`;

const Kakao_btn = styled.div`
    background-image: url("/images/kakao_login_medium_wide.png");
    background-repeat: no-repeat;
    background-size : cover;
    margin: 10px auto;
    padding: -10px;
    color: transparent;
    width: 300px;
    height: 45px;
`;

function Kakao_Auth() {
    return (
        <>
        <a href={KAKAO_AUTH_URL}>
            <Kakao_btn></Kakao_btn>
        </a>
    </>
    )
}

export default Kakao_Auth;*/