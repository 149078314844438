import { Map } from 'immutable';
import { handleActions, createAction } from 'redux-actions';

const SET_HEADER_VISIBILITY = 'base/SET_HEADER_VISIBILITY'; // 헤더 렌더링 여부 설정
const SET_USER_MENU_VISIBILITY = 'base/SET_USER_MENU_VISIBILITY'; // 유저메뉴 렌더링 여부 설정

export const setHeaderVisibility = createAction(SET_HEADER_VISIBILITY); // visible
export const setUserMenuVisibility = createAction(SET_USER_MENU_VISIBILITY); // visible

const initialState = Map({
    header: Map({
        visible: true
    }),
    userMenu: Map({
        visibleUserMenu: false
    })
});

const SET_LEFT_MENU_VISIBILITY = 'base/SET_LEFT_MENU_VISIBILITY'; // 왼쪽메뉴 렌더링 여부 설정

export const toggleLeftMenu = isVisible => ({type: SET_LEFT_MENU_VISIBILITY, isVisible});
const initialStateLeftMenu = {
  visibilityLeftMenu: true
};

export const leftMenuVisibility = (state = initialStateLeftMenu, action) => {
  switch (action.type) {
      case SET_LEFT_MENU_VISIBILITY:
          return {...state, 
            visibilityLeftMenu: action.isVisible
          };
      default:
          return state;
  }
};

export default handleActions({
    [SET_HEADER_VISIBILITY]: (state, action) => state.setIn(['header', 'visible'], action.payload),
    [SET_USER_MENU_VISIBILITY]: (state, action) => state.setIn(['userMenu', 'visibleUserMenu'], action.payload)
}, initialState);