import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
    
font-weight: bold;
cursor: pointer;
padding: 0px;

height: 32px;
width: 75px;
font-size: 15px;

background: #rgb(255,255,255);
text-align: center;
border: 1px solid rgb(100,100,100);
border-right: 1px solid rgb(200,200,200);
`
function Button({children,...rest}){
    return <StyledButton {...rest}>{children}</StyledButton>
}

export default Button;