import {React, useState, useEffect} from 'react';
import './modalComm.css';
import UseContextMenu from '../popupMenu/UseContextMenu';
import { BiX } from 'react-icons/bi';
import {BiSearch} from 'react-icons/bi'
import { getCommLog } from '../comm/Comm';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import axios from 'axios';
import Modal from "react-modal";
import {JSONTree} from "react-json-tree";
import { ContextMenu3 } from "../../components/popupMenu/styles";
import { IoIosArrowDown } from 'react-icons/io'
import {AiFillCaretRight, AiFillCaretLeft, AiFillStepForward, AiFillStepBackward} from 'react-icons/ai'
import {RxDividerVertical} from 'react-icons/rx'
import { useSelector } from "react-redux";

const ModalComm = (props) => {
  const {open, close} = props;
  const {clicked, setClicked, points, setPoints} = UseContextMenu();
  const [request, setRequest] = useState(0);
  const [textShipInfo, setTextShipInfo] = useState("");
  const [textDevEui, setTextDevEui] = useState("");
  const [textStart, setTextStart] = useState("");
  const [textEnd, setTextEnd] = useState("");
  const [list, setList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [isJsonOpen, setIsJsonOpen] = useState(false);
  const [jsonContent, setJsonContent] = useState({});
  const [select, setSelect] = useState(0);
  const XLSX = require( "xlsx" );
  const [page, setPage] = useState(1);
  const [pageStart, setPageStart] = useState(1);
  const [pageEnd, setPageEnd] = useState(1);
  const [itemsInPage, setItemsInPage] = useState(15);
  const ships = useSelector((state) => state.ships);
  const devices = useSelector((state) => state.devices);

  const theme = {
    scheme: 'default',
    author: 'chris kempson (http://chriskempson.com)',
    base00: '#ffffff',
    };

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
      overflow: "auto",
      backgroundColor: "white",
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,0.5)"
    },
  };

  useEffect(() => {
    if (open){
      setList([]);
      setTextShipInfo("");
      setTextDevEui("");
      //setTextStart("");
      setTextEnd("");
      setSelect(0);

      var today = new Date();
      var year = today.getFullYear();
      var month = ('0' + (today.getMonth() + 1)).slice(-2);
      var day = ('0' + today.getDate()).slice(-2);
      var dateString = year + '/' + month  + '/' + day + ' 00:00';
      //console.log(dateString);
      setTextStart(dateString);
      
      // 오늘 날짜로 search
      const makeUrl = getCommLog('', '', dateString, '', 0);
      axios({
        method: "GET",
        url: makeUrl
      })
      .then(function(response) {
        console.log(response.data);
        var listData = response.data.sort((a,b) => (b.getAt > a.getAt)? 1:-1);
        setSearchList(listData);
        setPage(1);
        //console.log(list);
      }).catch(error=>{
        console.log(error);
        //alert(error);
      });
    }
    else{
      setTextEnd("");
    }
  }, [open]);

  useEffect(() => {
    //console.log("request "+request);
    if (request !== -1){
      if (request){
        setRequest(request-1);
      }
      else{
        setClicked(true);
      }
    }
  }, [request]);

  useEffect(() => {
    const items = searchList;
    const newList = items.slice((page-1)*itemsInPage, (page)*itemsInPage);
    setPageEnd(items.length? (parseInt((items.length-1)/itemsInPage) + 1):1);
    setList(newList);
  }, [searchList]);

  const drawPage = (curPage) => {
    var newList;
    newList = searchList.slice((curPage-1)*itemsInPage, (curPage)*itemsInPage);
    setList(newList);
  }

  const onChange1 = (e) => {
    setTextShipInfo(e.target.value);
  };
  // const onChange2 = (e) => {
  //   setTextShipNo(e.target.value);
  // };
  const onChange3 = (e) => {
    setTextDevEui(e.target.value.toUpperCase());
  };
  const changePage = (e) => {
    setPage(e.target.value);
  }
  const pageEntered = (e) => {
    if (e.key === 'Enter') {
      if (page >= pageStart && page <= pageEnd){
        drawPage(page);
      }
    }
  }
  const searchEntered = (e) => {
    if (e.key === 'Enter') {
      search();
    }
  }

  function search(){
    
    /*const ship = textShipInfo? ships.find(ship => (ship.shipName.includes(textShipInfo) ||
      ship.shipNo.toUpperCase().includes(textShipInfo))) : null;
    console.log(ship);
    const device = textDevEui? devices.find(device => device.devEui.includes(textDevEui)) : null;

    const makeUrl = getCommLog(ship? ship.shipNo:'', device? device.devEui:'', textStart, textEnd, select);
    //const makeUrl = getCommLog(textShipInfo, textDevEui, textStart, textEnd, select);
*/
    var newList = [];
    setSearchList(newList);
    setPage(1);

    if (textShipInfo === ''){
      if (textDevEui === ''){
        const makeUrl = getCommLog('', '', textStart, textEnd, select);

        axios({
          method: "GET",
          url: makeUrl
        })
        .then(function(response) {
          console.log(response.data);
          var listData = response.data.sort((a,b) => (b.getAt > a.getAt)? 1:-1);
          setSearchList(listData);
          setPage(1);
        }).catch(error=>{
          console.log(error);
        });
      }
      else{
        const devicesToSearch = devices.filter(device => device.devEui.includes(textDevEui));
        devicesToSearch.map(device => {
          const makeUrl = getCommLog('', device.devEui, textStart, textEnd, select);

          axios({
            method: "GET",
            url: makeUrl
          })
          .then(function(response) {
            console.log(response.data);
            var listData = response.data;
            newList = newList.concat(listData);
            newList = newList.sort((a,b) => (b.getAt > a.getAt)? 1:-1);
            console.log(newList);
            setSearchList(newList);
            setPage(1);
          }).catch(error=>{
            console.log(error);
          });
        })
      }
    }
    else{
      if (textDevEui === ''){
        const shipsToSearch = ships.filter(ship => (ship.shipName.includes(textShipInfo))
          || (ship.shipNo.includes(textShipInfo)));
        shipsToSearch.map(ship => {
          const makeUrl = getCommLog(ship.shipNo, '', textStart, textEnd, select);

          axios({
            method: "GET",
            url: makeUrl
          })
          .then(function(response) {
            console.log(response.data);
            var listData = response.data;
            newList = newList.concat(listData);
            newList = newList.sort((a,b) => (b.getAt > a.getAt)? 1:-1);
            console.log(newList);
            setSearchList(newList);
            setPage(1);
          }).catch(error=>{
            console.log(error);
          });
        })
      }
      else{
        const shipsToSearch = ships.filter(ship => (ship.shipName.includes(textShipInfo))
          || (ship.shipNo.includes(textShipInfo)));
        shipsToSearch.map(ship => {
          const devicesToSearch = devices.filter(device => ((device.shipNo === ship.shipNo) && (device.devEui.includes(textDevEui))));
          devicesToSearch.map(device => {
            const makeUrl = getCommLog(ship.shipNo, device.devEui, textStart, textEnd, select);

            axios({
              method: "GET",
              url: makeUrl
            })
            .then(function(response) {
              console.log(response.data);
              var listData = response.data;
              newList = newList.concat(listData);
              newList = newList.sort((a,b) => (b.getAt > a.getAt)? 1:-1);
              console.log(newList);
              setSearchList(newList);
              setPage(1);
            }).catch(error=>{
              console.log(error);
            });
          })
        })
      }
    }
    
  };

  const exelDownload =() => {
    //console.log("exelDownload");
    var results = [];
    searchList.map(item => {
      var result;
      result = { no: (searchList.indexOf(item) + 1),
                 kind: item.isSend? "송신":"수신", 
                dateTime: (item.getAt.substring(5,10) + ' ' + item.getAt.substring(11,16)),
                ship: ((item.shipName)? item.shipName:'') + ((item.shipNo)? (' (' + item.shipNo + ')'):''), 
                devEui: (item.devEui === null)? '':item.devEui.toUpperCase(),
                event: (item.content.object)? eventType(item.content.object.DecodeDataHex):item.evt,
                gateway: (item.content.rxInfo !== undefined)? item.content.rxInfo[0].gatewayId.toUpperCase():'',
                rssi: (item.content.rxInfo !== undefined)? item.content.rxInfo[0].rssi:'',
                snr: (item.content.rxInfo !== undefined)? item.content.rxInfo[0].snr:''
              };
      results = [...results, result];
    });
    //console.log(results);

    const datas = XLSX.utils.json_to_sheet(results);
    datas["!cols"] = [{wpx: 50}, {wpx: 50}, {wpx: 100}, {wpx: 150}, {wpx: 150}, {wpx: 100}, {wpx: 150}, {wpx: 50}, {wpx: 50}];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, datas, 'comm_log');
    XLSX.writeFile(workbook, 'comm_log.xlsx');
  }

  function eventType(data){
    const value = parseInt(data.substring(4,6));
    switch(value){
      case 1: return '정기 위치 보고';
      case 2: return '서버요청 응답';
      case 3: return '단말 전원 OFF';
      case 4: return '단말 전원 ON';
      default: return '정기 위치 보고';
    }
  }

  function getSelect(){
    switch(select){
      case 0: return '전체';
      case 1: return '송신';
      case 2: return '수신';
      default: return '전체';
    }
  }
  function getShipName(devEui){
    //console.log(devEui);
    //console.log(devices);
    const device = devices.find(dev => dev.devEui.toUpperCase() === devEui.toUpperCase());
    if (device === undefined)
      return '';
    //console.log(device);
    const ship = ships.find(ship => ship.shipNo === device.shipNo);
    //console.log(ship);
    if (ship === undefined)
      return '';    
    return (ship.shipName + ' (' + ship.shipNo + ')');
  }

  return (
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <div className='modalComm'>
          <div className='modalComm_title'>
              {"통신로그"}
              <BiX className='modalComm_button1' onClick={close}></BiX>
          </div>
          <div className='modalComm_content'>
            <div className='modalComm_right'>
              <div className='modalComm_right_title'>
                  <div>최근 1개월간의 통신기록</div>
              </div>
              <div>
                <div className='modalComm_content'>
                  <div className='modalComm_up'>
                    <div className='modalComm_search'>

                      <div className='modalComm_flex_flexstart'>
                        <div>{"구분"}</div>
                        <div style={{width:'5px'}} />
                        <div className='modalComm_selectBox' id={"selectBox"}
                          onClick={(e) => {
                            if (clicked){
                              setClicked(false);
                            }
                            else{
                              e.preventDefault(); 
                              setRequest(1);
                              var div = document.getElementById("selectBox");
                              var left = div.getBoundingClientRect().left;
                              var bottom = div.getBoundingClientRect().bottom;
                              //console.log(left + ', ' + bottom);
                              setPoints({x: left, y: bottom});
                            }
                          }}>
                          <div>{getSelect()}</div>
                          <IoIosArrowDown/>
                        </div>
                      </div> 

                      <div className='modalComm_flex_flexstart'>
                        <div>{"시작일시"}</div>
                        <div style={{width:'5px'}} />
                        <Datetime value={textStart} onChange={(date) => setTextStart(date)} 
                          inputProps={{ readOnly: true, style: { width: '120px', height: '25px' } }} 
                          renderInput={(props, openCalendar) => (
                            <><input {...props} onClick={openCalendar} /> 
                            </>)}
                          renderDay={(props, currentDate, selectedDate) => (<td {...props}> {currentDate.date()} 
                            {currentDate.hour() === 0 && currentDate.minute() === 0 ? null : ( <span style={{ marginLeft: 5 }}> 
                            {currentDate.format('HH:mm')} </span> )} </td> )} 
                          dateFormat='YYYY/MM/DD' timeFormat='HH:mm' closeOnSelect={true}/>     
                      </div>

                      <div className='modalComm_flex_flexstart'>
                        <div>{"종료일시"}</div>
                        <div style={{width:'5px'}} />
                        <Datetime value={textEnd} onChange={(date) => setTextEnd(date)} 
                          inputProps={{ readOnly: true, style: { width: '120px', height: '25px' } }} 
                          renderInput={(props, openCalendar) => (
                            <><input {...props} onClick={openCalendar} /> 
                            </>)} 
                          dateFormat='YYYY/MM/DD' timeFormat='HH:mm' closeOnSelect={true}/>
                      </div>                     

                      <div className='modalComm_flex_flexstart'>
                        <div>{"어선명(또는 어선번호)"}</div>
                        <div style={{width:'5px'}} />
                        <input style={{width: '150px', height:'25px'}} onChange={onChange1} 
                          onKeyDown={(e) => searchEntered(e)} value={textShipInfo}></input>
                      </div>

                      <div className='modalComm_flex_flexstart'>
                        <div>{"어망ID"}</div>
                        <div style={{width:'5px'}} />
                        <input style={{width: '150px', height:'25px'}} onChange={onChange3} 
                          onKeyDown={(e) => searchEntered(e)} value={textDevEui}></input>
                      </div>

                      <div className='modalComm_button2' onClick={search}><BiSearch></BiSearch></div>
                          
                    </div>
                  </div>
                </div>
                <div className='modalComm_result'>
                  <div className='modalComm_result_1'>
                    <div>{"Total     " + (searchList.length) + "건"}</div>
                  </div>
                  <div className='modalComm_result_2'>                    
                    <div className='modalComm_itemTitle'>
                      <div className='modalComm_itemTitleSub1'>번호</div>
                      <div className='modalComm_itemTitleSub2'>구분</div>
                      <div className='modalComm_itemTitleSub3'>날짜시간</div>
                      <div className='modalComm_itemTitleSub4'>어선명(어선번호)</div>
                      <div className='modalComm_itemTitleSub5'>어망 ID</div>
                      <div className='modalComm_itemTitleSub6'>이벤트</div>
                      <div className='modalComm_itemTitleSub7'>중계기</div>
                      <div className='modalComm_itemTitleSub8'>RSSI</div>
                      <div className='modalComm_itemTitleSub9'>SNR</div>
                      <div className='modalComm_itemTitleSub10'>RawData</div>
                    </div>
                    <div className='modalComm_items'>
                      <div>
                        {list.map(item =>(
                          <div className='modalComm_item' key={item._id}>
                            <div className='modalComm_itemSub1'>{searchList.indexOf(item)+1}</div>
                            <div className='modalComm_itemSub2'>{item.isSend? "송신":"수신"}</div>
                            <div className='modalComm_itemSub3'>
                              {item.getAt.substring(5,10) + ' ' + item.getAt.substring(11,16)}</div>
                            <div className='modalComm_itemSub4'>
                              <div>{getShipName(item.devEui)}</div>
                            </div>
                            <div className='modalComm_itemSub5'>{item.devEui.toUpperCase()}</div>
                            <div className='modalComm_itemSub6'>{(item.content.object)? eventType(item.content.object.DecodeDataHex):item.evt}</div>
                            <div className='modalComm_itemSub7'>{(item.content.rxInfo !== undefined)? item.content.rxInfo[0].gatewayId.toUpperCase():null}</div>
                            <div className='modalComm_itemSub8'>{(item.content.rxInfo !== undefined)? item.content.rxInfo[0].rssi:null}</div>
                            <div className='modalComm_itemSub9'>{(item.content.rxInfo !== undefined)? item.content.rxInfo[0].snr:null}</div>
                            <div className='modalComm_itemSub10'><span onClick={()=>{setIsJsonOpen(true); setJsonContent(item.content);}}>상세</span></div>
                          </div>
                        ))}
                        <Modal isOpen={isJsonOpen} onRequestClose={()=>{setIsJsonOpen(false)}} style={modalStyle}>
                                <JSONTree data={jsonContent} theme={theme} />
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className='modalShipList_flexSpaceBetween'>
                <div className='modalShipList_exel'>
                  <div className="modalShipList_button4" onClick={exelDownload}>엑셀저장</div>
                  <div style={{width: "30px"}}/>
                </div>
                
                <div className='modalShipList_pages'>
                  <AiFillStepBackward className={(page>pageStart)? 'pageArrow':'pageArrow_Disabled'}
                    onClick={()=>{
                      if(page>pageStart){
                        setPage(pageStart); drawPage(pageStart);
                    }}}/>
                  <AiFillCaretLeft className={(page>pageStart)? 'pageArrow':'pageArrow_Disabled'}
                    onClick={()=>{
                      if(page>pageStart){
                        setPage(page - 1); drawPage(page - 1);
                    }}}/>
                  <RxDividerVertical/>
                  <div style={{width: "5px"}}/>
                  <input className='pageInput' onChange={changePage}
                    onKeyDown={(e) => pageEntered(e)} value={page}></input>
                  <div style={{width: "5px"}}/>
                  <div className='pagePage'>{'/ '+ pageEnd}</div>
                  <div style={{width: "5px"}}/>
                  <RxDividerVertical/>
                  <AiFillCaretRight className={(page<pageEnd)? 'pageArrow':'pageArrow_Disabled'}
                    onClick={()=>{
                      if(page<pageEnd){
                        setPage(page + 1); drawPage(page + 1);
                    }}}/>
                  <AiFillStepForward className={(page<pageEnd)? 'pageArrow':'pageArrow_Disabled'}
                    onClick={()=>{
                      if(page<pageEnd){
                        setPage(pageEnd); drawPage(pageEnd);
                    }}}/>
                </div>
              </div>
              
              <div style={{height:'10px'}} />

            </div>          
          </div>
        </div>
      ) : null}
      {clicked && (
        <ContextMenu3 top={points.y} left={points.x}>
          <div>
            <div className='modalComm_ContextItems'>
              <div className='modalComm_ContextItem' onClick={()=>setSelect(0)}>전체</div>
              <div className='modalComm_ContextItem' onClick={()=>setSelect(1)}>송신</div>
              <div className='modalComm_ContextItem' onClick={()=>setSelect(2)}>수신</div>
            </div>
          </div>    
        </ContextMenu3>
      )}
    </div>
  );
};

export default ModalComm