import {React, useState, useEffect, useRef} from 'react';
import axios from 'axios';
import './Control.css'
import UseContextMenu from '../components/popupMenu/UseContextMenu'
import Home from '../pages/home/Home'
import Topbar from '../components/topbar/topbar'
import Sidebar from '../components/sidebar/Sidebar'
import { ContextMenu } from "../components/popupMenu/styles";
import ModalWorkend from '../components/modals/ModalWorkend';
import ModalControl from '../components/modals/ModalControl';
import ModalSetting from '../components/modals/modalSetting';
import ModalShip from '../components/modals/ModalShip';
import LogoutAfterTime from '../containers/Auth/LogoutAfterTime';
import { getCurTime, saveGatewayLog, commGetGateway, commGatewayCheck, commSendToDevice, makeDeviceData, commDeleteQueue} from '../components/comm/Comm';
import { commUpdateDeviceDB, commDeleteShipDB, commUpdateShipDB } from '../components/comm/CommUpdateDB';
import { useSelector, useDispatch } from "react-redux";
import storage from '../lib/storage';
import { setCurDevInfo } from "../redux/modules/curDevInfo";
import { setCurShip } from "../redux/modules/curShip";
import { updateCurShip } from "../redux/modules/curShip";
import { updateShip } from "../redux/modules/ships";
import { updateDevice } from "../redux/modules/devices";
import { setCurDevices } from '../redux/modules/curDevices';
import { updateCurDevice } from '../redux/modules/curDevice';
import { setUsers } from '../redux/modules/users.js';
import { setShips } from '../redux/modules/ships.js';
import { deleteShip } from '../redux/modules/ships.js';
import { setGateways } from '../redux/modules/gateways.js';
import { addDevices } from '../redux/modules/devices.js';
import { updateModalState } from '../redux/modules/modalState';
import { MyAlert } from '../components/popupMenu/Confirm';
import { setCurUser } from "../redux/modules/curUser";
import { isNew } from '../components/comm/Comm';
import { updateLoadState } from '../redux/modules/loadState';
import { updateEtc } from '../redux/modules/etc';
import ModalShipDelete from '../components/modals/ModalShipDelete';

var userDB = null;
var shipDB = null;
var gatewayDB = null;
var deviceDB = null;
var etcDB = null;
var isLoadingFinished = false;

function Control() {
  
  const [center, setCenter] = useState({lat: 35.05, lng: 129.10});
  const {clicked, setClicked, points, setPoints} = UseContextMenu();
  const ships = useSelector((state) => state.ships);
  const devices = useSelector((state) => state.devices);
  const curDevInfo = useSelector((state) => state.curDevInfo);
  const curShip = useSelector((state) => state.curShip);
  const curDevice = useSelector((state) => state.curDevice);
  const modalState = useSelector((state) => state.modalState);
  const curUser = useSelector((state) => state.curUser);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [flag, setFlag] = useState(false);
  const [flag1min, setFlag1min] = useState(false);
  const dispatch = useDispatch();
  const { visibilityLeftMenu } = useSelector((state) => state.leftMenuVisibility);
  const [getShipData, getCompleteShipData] = useState(false);
  const [getUsersData, getCompleteUsersData] = useState(false);
  const [getGatewayData, getCompleteGatewayData] = useState(false);
  const [getDeviceData, getCompleteDeviceData] = useState(false);
  const [getEtcData, getCompleteEtcData] = useState(false);
  const curDevices = useSelector((state) => state.setCurDevices);

  const setAlert = (content) => {
    setAlertContent(content);
    setAlertOpen(true);
  }

  const cnt = useRef(0);

  useEffect(() => {
    axios({
      method: "GET",
      url: '/api/auth/allUsers'
    })
    .then(function(response) {
      userDB = response.data;
      dispatch(setUsers(userDB)); // DB 데이터로 메모리 데이터 설정
      getCompleteUsersData(true);
      dispatch(updateLoadState('users', true));
    }).catch(error=>{
      alert(error);
    });
  }, []);

  useEffect(() => {
    if(getUsersData)
    {
      //dispatch(setUsers(userDB));
      
      //storage.get('loggedInfo').userid; 현재 로그인한 사용자 id를 받아오는 코드입니다.
      const id = storage.get('loggedInfo').userid;
      const user = userDB.find(user => user.userid === id);
      if(user === null)
      {
          storage.remove('loggedInfo');
          window.location.href = '/auth/login?expired';
      }
      
      const LoginUser = {userid: user.userid, name: user.name, email: user.email, isAdmin: user.isAdmin};
      console.log("LoginUser ");
      console.log(LoginUser);
      dispatch(setCurUser(LoginUser));
    }
    else if(storage.get('loggedInfo') === null)
    {
      window.location.href = '/auth/login?expired';
    }
  }, [getUsersData]);

  useEffect(() => {
    if (getUsersData){
      let getShipURL = null;
      const useridLoggedin = storage.get('loggedInfo').userid;
      const user = userDB.find(user => user.userid === useridLoggedin);
      if(user.isAdmin)
        getShipURL = '/api/shipdb/get';
      else
        getShipURL = '/api/shipdb/owner/' + storage.get('loggedInfo').userid;

      axios({
        method: "GET",
        url: getShipURL
      })
      .then(function(response) {
        var temp = response.data;
        if(temp instanceof Array){
          shipDB = temp;
        }
        else{
          shipDB = [];
          if (temp !== '')
            shipDB = [...shipDB, temp];
        }
        console.log(shipDB);
        dispatch(setShips(shipDB)); // DB 데이터로 메모리 데이터 설정

        if (shipDB.length === 0){
          dispatch(updateEtc('noShip', true));
        }
        //if(shipDB)
        getCompleteShipData(true);
        
        dispatch(updateLoadState('ships', true));
      }).catch(error=>{
        alert(error);
      });
    }
  }, [getUsersData]);

  useEffect(() => {
    if(getShipData)
    {
      //dispatch(setShips(shipDB));

      // 메모리에 어선들의 현재위치를 업데이트
      shipDB.forEach(ship => {
        axios(
          commGatewayCheck(ship.gatewayID)
        )
        .then(function(response) {
          //console.log(response);
          const latNew = response.data.gateway.location.latitude;
          const lngNew = response.data.gateway.location.longitude;
          // 배열 업데이트
          dispatch(updateShip(ship.shipNo, 'lat', latNew));
          dispatch(updateShip(ship.shipNo, 'lng', lngNew));
          
          console.log("ship.shipNo "+ ship.shipNo);
          console.log(latNew+', '+lngNew);
        }).catch(error=>{
          console.log(error);
          //alert(error);
        });
      })      
    }
  }, [getShipData]);

  useEffect(() => {
    if (getShipData){
      axios({
        method: "GET",
        url: '/api/gatewaydb/get'
      })
      .then(function(response) {
        gatewayDB = response.data;
        dispatch(setGateways(gatewayDB)); // DB 데이터로 메모리 데이터 설정
        getCompleteGatewayData(true);
        dispatch(updateLoadState('gateways', true));
      }).catch(error=>{
        alert(error);
      });
    }
  }, [getShipData]);
  useEffect(() => {
    if(getGatewayData)
    {
      //dispatch(addGateways(gatewayDB));
    }
  }, [getGatewayData]);

  useEffect(() => {
    if (getShipData){
      let getShipURL = null;
      const useridLoggedin = storage.get('loggedInfo').userid;
      const user = userDB.find(user => user.userid === useridLoggedin);
      if(user.isAdmin){
          axios({
            method: "GET",
            url: '/api/devicedb/get'
          })
          .then(function(response) {
            deviceDB = response.data;
            dispatch(addDevices(deviceDB));
            getCompleteDeviceData(true);
            dispatch(updateLoadState('devices', true));
          }).catch(error=>{
            alert(error);
          });
      }
      else{
        deviceDB = [];
        const shipsToSearch = shipDB.filter(ship => (
          (ship.ownerID === user.userid) || (ship.captainID === user.userid)));
        if (shipsToSearch.length > 0){
          shipsToSearch.map(ship => {
            axios({
              method: "GET",
              url: '/api/devicedb/dev?shipNo=' + ship.shipNo
            })
            .then(function(response) {
              deviceDB = deviceDB.concat(response.data);
              dispatch(addDevices(deviceDB));
              getCompleteDeviceData(true);
              dispatch(updateLoadState('devices', true));
            }).catch(error=>{
              alert(error);
            });
          })
        }
        else{
          dispatch(addDevices(deviceDB));
          getCompleteDeviceData(true);
          dispatch(updateLoadState('devices', true));
        }
      }     
    }
  }, [getShipData]);
  useEffect(() => {
    if(getDeviceData)
    {
      //dispatch(addDevices(deviceDB));
    }
  }, [getDeviceData]);

  // etc DB에서 읽어오기
  useEffect(() => {
    axios({
      method: "GET",
      url: '/api/etcdb/get'
    })
    .then(function(response) {
      console.log("etc db success");
      console.log(response);
      etcDB = response.data[0];
      dispatch(updateEtc('distanceAlarm', etcDB.distanceAlarm));
      dispatch(updateEtc('gpsErrorAlarm', etcDB.gpsErrorAlarm));
      dispatch(updateEtc('distanceContent', etcDB.distanceContent));
      dispatch(updateEtc('gpsContent', etcDB.gpsContent));
      getCompleteEtcData(true);
      //dispatch(updateLoadState('etc', true));
    }).catch(error=>{
      console.log("etc db error");
      alert(error);
    });
  }, []);

  // 주기적으로 해야하는 일들... (현재의 gateway 위치, 현재 어선의 device 데이터 업데이트)
  useEffect(() => {
    const loop = setInterval(()=>{
      cnt.current += 1;
      //console.log(cnt.current);
      setFlag(true);

      return() =>{
        clearInterval(loop);
      }
    }, 5000);
    
    setInterval(()=>{
      const loop_1min = setFlag1min(true);

      return() =>{
        clearInterval(loop_1min);
      }
    }, 60000);
  }, []);

  useEffect(() => {
    if (flag){
      setFlag(false);

      //console.log(curShip);
      if(curShip.gatewayID !== "") {

        axios(
          commGatewayCheck(curShip.gatewayID)
        )
        .then(function(response) {
          //console.log(response);
          const latNew = response.data.gateway.location.latitude;
          const lngNew = response.data.gateway.location.longitude;
          // 배열 업데이트
          dispatch(updateShip(curShip.shipNo, 'lat', latNew));
          dispatch(updateShip(curShip.shipNo, 'lng', lngNew));
          dispatch(updateCurShip('lat', latNew));
          dispatch(updateCurShip('lng', lngNew));

          // 통신성공시 어선의 LastComm에 기록
          const time = getCurTime();
          dispatch(updateShip(curShip.shipNo, 'lastComm', time));
          dispatch(updateCurShip('lastComm', time));

          // 통신로그 기록
          //saveGatewayLog(response);
        })
        .catch(error=>{
          //console.log(error);
          //alert(error);
        });
      }
    }
  }, [flag]);
  
  function UpdateDeviceFromDB(devEui, data)
  {
      dispatch(updateDevice(devEui, 'battery', data.battery));
      dispatch(updateDevice(devEui, 'lat', data.lat));
      dispatch(updateDevice(devEui, 'lng', data.lng));
      dispatch(updateDevice(devEui, 'state', data.state));
      dispatch(updateDevice(devEui, 'power', data.power));
      dispatch(updateDevice(devEui, 'workend', data.workend));
      dispatch(updateDevice(devEui, 'distanceAlarm', data.distanceAlarm));
      dispatch(updateDevice(devEui, 'distance', data.distance));
      dispatch(updateDevice(devEui, 'LedOnTime', data.LedOnTime));
      dispatch(updateDevice(devEui, 'onoffPeriod', data.onoffPeriod));
      dispatch(updateDevice(devEui, 'duration', data.duration));
      dispatch(updateDevice(devEui, 'commPeriod', data.commPeriod));
      dispatch(updateDevice(devEui, 'lastComm', data.lastComm));
      //console.log(data.lastComm);
      
      if (curDevInfo.isShip === false && devEui === curDevInfo.devEui){
        dispatch(updateCurDevice('battery', data.battery));
        dispatch(updateCurDevice('lat', data.lat));
        dispatch(updateCurDevice('lng', data.lng));
        dispatch(updateCurDevice('state', data.state));
        dispatch(updateCurDevice('power', data.power));
        dispatch(updateCurDevice('workend', data.workend));
        dispatch(updateCurDevice('distanceAlarm', data.distanceAlarm));
        dispatch(updateCurDevice('distance', data.distance));
        dispatch(updateCurDevice('LedOnTime', data.LedOnTime));
        dispatch(updateCurDevice('onoffPeriod', data.onoffPeriod));
        dispatch(updateCurDevice('duration', data.duration));
        dispatch(updateCurDevice('commPeriod', data.commPeriod));
        dispatch(updateCurDevice('lastComm', data.lastComm));
        //console.log(data.lastComm);
      }
  }

  function isTimeOver(last, period){
    var today = new Date();
    var month = ('0' + (today.getMonth() + 1)).slice(-2);
    var day = ('0' + today.getDate()).slice(-2);
    var hour = ('0' + today.getHours()).slice(-2);
    var min = ('0' + today.getMinutes()).slice(-2);
    var todayString = month + '-' + day + ' ' + hour + ':' + min;

    var last_month = parseInt(last.substring(0,2), 10);
    var last_day = parseInt(last.substring(3,5), 10);
    var last_hour = parseInt(last.substring(6,8), 10);
    var last_min = parseInt(last.substring(9,11), 10);

    last_min = last_min + period;
    if (last_min >= 60){
      last_min = last_min - 60;
      last_hour = last_hour + 1;
    }

    if (last_hour >= 24){
      last_hour = last_hour - 24;
      last_day = last_day + 1;
    }

    last_day = ('0' + last_day).slice(-2);
    last_hour = ('0' + last_hour).slice(-2);
    last_min = ('0' + last_min).slice(-2);
    var lastString = ('0' + String(last_month)).slice(-2) + '-' + ('0' + String(last_day)).slice(-2) + 
                      ' ' + ('0' + String(last_hour)).slice(-2) + ':' + ('0' + String(last_min)).slice(-2);

    //console.log('todayString '+ todayString);
    //console.log('lastString '+ lastString);

    if (isNew(todayString, lastString)){
      //console.log("true");
      return true;
    }
    else{
      //console.log("false");
      return false;
    }
  }

  useEffect(() => {
    if (flag1min && ships){
      setFlag1min(false);

      //console.log(curShip);
      if (curShip.shipNo){
        // 1분에 한번씩 어선정보 업데이트 된 내용을 DB에 기록
        const ship = ships.find(ship => ship.shipNo === curShip.shipNo);
        const updateData = { shipNo: ship.shipNo, lat: ship.lat, lng: ship.lng, lastComm: ship.lastComm };
        commUpdateShipDB(updateData);

        // 1분에 한번씩 현재 배에 소속된 어망들의 정보를 DB에서 읽어옴
        const useridLoggedin = storage.get('loggedInfo').userid;
        const user = userDB.find(user => user.userid === useridLoggedin);
        (user.isAdmin? devices.filter(device => device.shipNo === ship.shipNo):devices).map(device => {
          axios({
              method: "GET",
              url: '/api/devicedb/dev?&devEui=' + device.devEui,
          })
          .then(function(response) {
              //console.log(device.devEui);
              //console.log(response);
              const memoryLastComm = device.lastComm;
              const dbLastComm = response.data[0].lastComm;
              
              // 메모리에 기록된 통신시간보다 DB에 기록된 통신시간이 더 최근이면 메모리를 업데이트
              if (isNew(dbLastComm, memoryLastComm)){
                  UpdateDeviceFromDB(device.devEui, response.data[0]);
              }

              var workendOff = false;
              var ledTurnOff = false;

              if (response.data[0].state & 0x0F){ //LED On
                if (isTimeOver(response.data[0].LedOnTime, response.data[0].duration * 10)){
                  workendOff = true;
                }
                if (response.data[0].LedOnTime === '01-01 09:00'){
                  //Led On 시간이 없으면 최종통신시간 + Led 유지시간을 넘으면 Led Off
                  if (isTimeOver(response.data[0].lastComm, response.data[0].duration * 10)){
                    ledTurnOff = true;
                  }
                }
                else{
                  //Led On 시간이 있으면 Led On 시간 + Led 유지시간을 넘으면 Led Off
                  if (isTimeOver(response.data[0].LedOnTime, response.data[0].duration * 10)){
                    ledTurnOff = true;
                  }
                }
              }
              else{ //LED Off
                if (isTimeOver(response.data[0].lastComm, response.data[0].commPeriod * 10)){
                  workendOff = true;
                }
                if (response.data[0].LedOnTime !== '01-01 09:00'){
                  //Led Off인데 Led On 시간이 '01-01 09:00'이 아니면 그렇게 만듦
                  ledTurnOff = true;
                }
              }

              if (workendOff){
                dispatch(updateDevice(device.devEui, 'workend', 0));
                if (curDevInfo.isShip === false && device.devEui === curDevInfo.devEui){
                  dispatch(updateCurDevice('workend', 0));
                }
                const updateDB = {devEui: device.devEui, workend: 0};
                commUpdateDeviceDB(updateDB);
              }

              if (ledTurnOff){
                dispatch(updateDevice(device.devEui, 'state', (response.data[0].state & 0xF0)));
                dispatch(updateDevice(device.devEui, 'LedOnTime', '01-01 09:00'));
                if (curDevInfo.isShip === false && device.devEui === curDevInfo.devEui){
                  dispatch(updateCurDevice('state', (response.data[0].state & 0xF0)));
                  dispatch(updateCurDevice('LedOnTime', '01-01 09:00'));
                }
                const updateDB = {devEui: device.devEui, state: (response.data[0].state & 0xF0), LedOnTime: '01-01 09:00'};
                commUpdateDeviceDB(updateDB);
              }
          }).catch(error=>{
              console.log(error);
          });
        })

        //dispatch(setCurDevices(devices, ship.shipNo));
      }
    }
  }, [flag1min]);

/*  useEffect(() => {
    console.log("curDevices changed");
    console.log(curDevices);
    if (curDevices){
      console.log("curDevices changed");
      dispatch(setCurDevices(devices, curShip.shipNo));
    }
  }, [devices]);*/

  useEffect(() => {
    //console.log(curDevInfo);
    //console.log(curShip);
    if (curDevInfo.fromSide){
      if (curDevInfo.isShip){
        setCenter({lat: curShip.lat, lng: curShip.lng});
      }
      else{
        setCenter({lat: curDevice.lat, lng: curDevice.lng});
      }
    }
    //setInfoOpen(true);
    if (curDevInfo.shipNo || curDevInfo.devEui)
      dispatch(updateModalState('info', true));
  }, [curDevInfo]);

/*  useEffect(() => {
    console.log(devices);
  }, [devices]);*/

  /*const handleCurrentDeviceChange1 = (device) => {
    setInfoOpen(true);
  };*/

  const handleRightClicked = (clicked) => {
    setClicked(clicked);
  };
  const handleRightClickPoint = (point) => {
    setPoints(point);
  };  
  const handleInfoClosed = () => {
    //setInfoOpen(false);
    dispatch(updateModalState('info', false));
  };

  function changeInfoClicked(){
    //console.log("changeInfoClicked");
    //setShipBackup(curShip);
    //setModalShipOpen(true);
    dispatch(updateModalState('shipRegister', true));
  }
  function changeInfoClosed(){
    //setModalShipOpen(false);
  }

/*  const test = ()=>{
    const value = 0x31;
    const v1 = (value & 0x20);
    console.log("value = "+value);
    console.log("value = "+value.toString());
    console.log("v1 = "+v1);
    console.log("v1 = "+v1.toString(16));
  }*/

  function setInitalShip(){
    if (ships.length > 0){
      const ship = ships[0];
      const devInfo = {isShip: true, shipNo: ship.shipNo, devEui: null, fromSide: true};
      dispatch(setCurDevInfo(devInfo));
      dispatch(setCurShip(ship));
      dispatch(setCurDevices(devices, ship.shipNo));
      setCenter({lat: ship.lat, lng: ship.lng});
      dispatch(updateModalState('info', true));
    }
  }
  
  useEffect(() => {
    if(isLoadingFinished === false && ships && devices) {
      isLoadingFinished = true;
      console.log(ships);
      setInitalShip();
    }
  }, [ships, devices]);

  function WorkendOpen(){
    if (curShip !== undefined){
      const devicesToApply = devices.filter(device => device.shipNo === curDevInfo.shipNo);
      if (devicesToApply.length < 1){
        setAlert("등록된 부이가 없습니다.");
        return;
      }

      dispatch(updateModalState('workend', true));
    }
  }
  function controlOpen(){
    if (curShip !== undefined){
      const devicesToApply = devices.filter(device => device.shipNo === curDevInfo.shipNo);
      if (devicesToApply.length < 1){
        setAlert("등록된 부이가 없습니다.");
        return;
      }
      
      dispatch(updateModalState('control', true));
    }
  }
  function settingOpen(){
    if (curShip !== undefined){
      const devicesToApply = devices.filter(device => device.shipNo === curDevInfo.shipNo);
      if (devicesToApply.length < 1){
        setAlert("등록된 부이가 없습니다.");
        return;
      }

      dispatch(updateModalState('setting', true));
    }
  }
  function removeShip(){
    const devicesToInit = devices.filter(device => device.shipNo === curShip.shipNo);
    devicesToInit.forEach(device => {
      dispatch(updateDevice(device.devEui, 'shipNo', ''));
      const updateDB = {devEui: device.devEui, shipNo: ''};
      commUpdateDeviceDB(updateDB);
    })
    dispatch(deleteShip(curShip.shipNo));
    setInitalShip();
    commDeleteShipDB(curShip.shipNo);
  }
  
  return (
    <div className="App">
      {/* <LogoutAfterTime time={60000*30} /> */}
      { devices && (
      <>
      <Topbar />
      <div className="Appcontainer">
          {visibilityLeftMenu && (
            <div onContextMenu={(e) => {
              e.preventDefault();
              setClicked(true);
              if (e.pageY < (window.innerHeight - 270)){
                setPoints({ x: e.pageX, y: e.pageY });
              }
              else{
                setPoints({ x: e.pageX, y: (window.innerHeight - 270)});
              }
            } }>
              <Sidebar />
            </div>
          )}
          <Home
            center={center}
            onRightClicked={handleRightClicked}
            onRightClickPosition={handleRightClickPoint} />
        </div></>
      )}
      {clicked && (
        <ContextMenu top={points.y-60} left={points.x}>
          {curDevInfo.isShip ? (
            <div>
                <div className='ContextTitle'>
                  <div>
                    <span>{curShip.shipName}</span>
                    <span className='ContextTitle_ship1_2'>{' (' + curShip.shipNo + ')'}</span>
                  </div>
                </div>
                <div className='ContextItems'>
                  <div className='ContextItem' onClick={WorkendOpen}>작업완료</div>
                  <div className='ContextItem' onClick={controlOpen}>제어</div>
                  <div className='ContextItem' onClick={settingOpen}>설정</div>
                  {curUser.isAdmin ?
                  <div>
                    <div className='ContextItem' onClick={()=>dispatch(updateModalState('shipEdit', true))}>기본정보수정</div>
                    <div className='ContextItem' onClick={()=>dispatch(updateModalState('shipDelete', true))}>어선삭제</div>
                  </div>:null
                  }
                </div>
            </div>
          ) : (
            <div>
                <div className='ContextTitle'>{'부이 '+curDevice.devEui.toUpperCase()}</div>
                <div className='ContextItems'>
                  <div className='ContextItem' onClick={controlOpen}>제어</div>
                  <div className='ContextItem' onClick={settingOpen}>설정</div>
                </div>
            </div>
          )}
        </ContextMenu>
      )}
      <ModalShipDelete
        open={modalState.shipDelete} 
        ok={()=>{dispatch(updateModalState('shipDelete', false)); removeShip();}}
        close={() => dispatch(updateModalState('shipDelete', false))} />
      {alertOpen && (
        <MyAlert content={alertContent} ok={()=>setAlertOpen(false)}/>
      )}
    </div>
  );
}
  
export default Control;